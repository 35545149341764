import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Referee } from "./Referee";
import { Venue } from "./Venue";
export interface Props {
  fixtureId: string;
}
export const FixtureInfo: FC<Props> = ({ fixtureId }) => (
  <>
    <Box display="flex" justifyContent="space-evenly" p={1}>
      <Referee fixtureId={fixtureId} />
      <Venue fixtureId={fixtureId} />
    </Box>
  </>
);
