import { Box, Typography } from "@material-ui/core";
import { green, grey, red } from "@material-ui/core/colors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  form?: string;
}
export const FormView: FC<Props> = ({ form }) => {
  const elements = form?.split("");
  const [t] = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {elements?.map((e, i) => (
        <Box
          bgcolor={
            (e === "W" && green[500]) ||
            (e === "L" && red[500]) ||
            (e === "D" && grey[500])
          }
          display="flex"
          justifyContent="center"
          alignItems="center"
          key={i}
          border="1px"
          color="white"
          width="2rem"
          height="2rem"
          borderRadius="50%"
          m={0.25}
        >
          <Typography variant="button" color="inherit" align="center">
            {t(`standing.form_${e.toLowerCase()}`)}
          </Typography>
        </Box>
      ))}
      {!elements && "-"}
    </Box>
  );
};
