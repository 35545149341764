import { Box, Card, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Substitutes } from "../substitutes/Substitutes";

interface Props {
  contestantId: string;
  side: "Home" | "Away";
}

export const SubsFormation: FC<Props> = ({ contestantId, side }) => {
  const [t] = useTranslation();
  return (
    <Box my={0.5} ml={side === "Away" ? 1 : 0} mr={side === "Home" ? 1 : 0}>
      <Card>
        <Box py={0.5}>
          <Typography variant="overline" align="center" component="h6">
            {t("glossary.substitutes")}
          </Typography>
        </Box>
        <Box overflow="scroll auto" px={1} minHeight={85}>
          <Substitutes contId={contestantId} />
        </Box>
      </Card>
    </Box>
  );
};
