import { BucketType } from "features/fixture/interfaces";
import { createContext } from "react";
import { useQueryState } from "../Stats/useQueryState";

export type TeamSide = "home" | "away";
export type SideFilter = `${TeamSide}-${TeamSide}`;
export interface SeasonalFilter {
  bounds: [BucketType, BucketType];
  gameLimit?: number;
  side?: SideFilter;
  setBounds: (bounds: [BucketType, BucketType]) => void;
  setGameLimit: (limit?: number) => void;
  setSide: (side?: SideFilter) => void;
}

const filter: SeasonalFilter = {
  bounds: ["PM", "FT"],
  setBounds: (bounds: [BucketType, BucketType]) => {},
  setGameLimit: (limit?: number) => {},
  setSide: (side?: SideFilter) => {},
};

export const SeasonalFilterContext = createContext(filter);

export const useSeasonalFilter = () => {
  const [bounds, setBounds] = useQueryState<[BucketType, BucketType]>(
    "bounds",
    ["PM", "FT"]
  );
  const [gameLimit, setGameLimit] = useQueryState<number | undefined>("gl");
  const [side, setSide] = useQueryState<SideFilter | undefined>("sd");
  const [homeSide, awaySide] = (side?.split("-") || []) as [TeamSide, TeamSide];
  return {
    bounds,
    gameLimit,
    side,
    homeSide,
    awaySide,
    setGameLimit,
    setBounds,
    setSide,
  };
};
