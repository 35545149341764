import { API } from "app/API";
import qs from "qs";
import { from } from "rxjs";
import { FetchTrnStatsData } from "./FetchTrnStatsData";
import { FetchTrnStatsParams } from "./FetchTrnStatsParams";

const createFetchTrnStatsEndpoint = (id: string, params: FetchTrnStatsParams) => API.get<FetchTrnStatsData>(`/calendars/${id}/stats`, {
  params,
  paramsSerializer: (p) => qs.stringify(p),
});

export const fetchTrnStatsReq = (id: string, params: FetchTrnStatsParams) => from(createFetchTrnStatsEndpoint(id, params));
