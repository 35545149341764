import { createContext } from "react";
export type StandingFormat = "form" | "half-time" | "";
export type StandingSide = "home" | "away" | "total";

interface StandingFilter {
  format: StandingFormat;
  side: StandingSide;
  setFormat: (format: StandingFormat) => void;
  setSide: (side: StandingSide) => void;
}

const filter: StandingFilter = {
  side: "total",
  format: "",
  setFormat: (format) => {},
  setSide: (side) => {},
};
export const StandingFilterContext = createContext(filter);
