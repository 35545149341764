import {
  emitGetCalendar,
  subscribeCalendars
} from "features/socket/socketSlice";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "./Calendar";
interface Props {
  id: string;
  schedule: number;
}

export const CalendarContainer: FC<Props> = ({ id, schedule }) => {
  const connection = useSelector((state) => state.socketStatus.serverStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (connection === "connected") {
      dispatch(emitGetCalendar({ id }));
    }
  }, [id, dispatch, connection]);

  useEffect(() => {
    if (connection === "connected") {
      dispatch(
        subscribeCalendars({
          namespace: "calendars",
          initialMessage: {
            calendarId: id,
            date: schedule,
            timezoneOffset: new Date(schedule).getTimezoneOffset(),
          },
        })
      );
    }
  }, [id, dispatch, schedule, connection]);
  return <Calendar id={id} />;
};
