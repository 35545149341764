import { IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextFieldProps as MuiTextFieldProps } from "formik-material-ui";
import React, { FC, useState } from "react";
import { TextField } from "./TextField";
interface EndAdornmentProps {
  showPasswordHandler: any;
  handleMouseDownPassword: any;
  showPassword: boolean;
}
const EndAdornment: FC<EndAdornmentProps> = ({
  showPassword,
  handleMouseDownPassword,
  showPasswordHandler,
}) => {
  return (
    <>
      <InputAdornment position="end">
        <IconButton
          tabIndex={-1}
          aria-label="toggle password visibility"
          onClick={() => showPasswordHandler(!showPassword)}
          onMouseDown={handleMouseDownPassword}
        >
          {!showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    </>
  );
};
export const PasswordTextField: FC<MuiTextFieldProps> = (props) => {
  const [showPassword, showPasswordHandler] = useState(false);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const { InputProps, ...rest } = props;
  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <EndAdornment
            showPassword={showPassword}
            showPasswordHandler={showPasswordHandler}
            handleMouseDownPassword={handleMouseDownPassword}
          />
        ),
        type: showPassword ? "text" : "password",
      }}
    />
  );
};
