import { Box } from "@material-ui/core";
import { TrnPitchHeader } from "app/components/trn-stats/TrnPitchHeader";
import { TrnPositionTitles } from "app/components/trn-stats/TrnPositionTitles";
import { PlayerPosition } from "features/fixture/interfaces";
import { usePlayerStatType } from "features/fixture/usePlayerStatType";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { selectTeamKit } from "../../../../features/fixture/selectors";
import { FormationCont } from "./FormationCont";
import { Pitch } from "./Pitch";
import { TopPlayer } from "./TopPlayer";

interface Props {
  contestantId: string;
  position: "home" | "away";
}

const TopPlayerList: FC<{
  contestantId: string;
  reverse: boolean;
  position: PlayerPosition;
}> = ({ contestantId, reverse, position }) => {
  const type = usePlayerStatType() || StatType.matches_played;
  const status = useSelector((state) => state.fixtures.h2hStatus);
  const kit = useSelector(selectTeamKit(contestantId));
  const players = useSelector(
    (s) =>
      s.fixtures.contestants[contestantId]?.topPlayers
        ?.filter((p) => s.fixtures.players[p.id]?.position === position)
        .map((p) => ({
          id: p.id,
          stat: p.stats[type] || 0,
          avg: (
            (p.stats[type] || 0) / p.stats[StatType.matches_played]
          ).toFixed(1),
        }))
        .filter((p) => p.stat !== 0)
        .sort((a, b) => b.stat - a.stat)
        .slice(0, 4) || []
  );
  return (
    <Box
      hidden={
        type === StatType.goals_conceded &&
        position !== PlayerPosition.GOALKEEPER
      }
    >
      {players.map((p) => (
        <TopPlayer
          id={p.id}
          key={p.id}
          kitColour={kit}
          reverse={reverse}
          status={status}
          stat={p.stat}
          avg={p.avg}
        />
      ))}
    </Box>
  );
};
export const ContestantTopPlayers: FC<Props> = ({ contestantId, position }) => {
  const reverse = position === "away";
  return (
    <Pitch side={reverse ? "Away" : "Home"}>
      <Box width="100%" display="flex" flexDirection="column" height="100%">
        <TrnPitchHeader>
          <TrnPositionTitles reverse={reverse} />
        </TrnPitchHeader>
        <FormationCont reverse={reverse}>
          <TopPlayerList
            contestantId={contestantId}
            reverse={reverse}
            position={PlayerPosition.GOALKEEPER}
          />

          <TopPlayerList
            contestantId={contestantId}
            reverse={reverse}
            position={PlayerPosition.DEFENDER}
          />

          <TopPlayerList
            contestantId={contestantId}
            reverse={reverse}
            position={PlayerPosition.MIDFIELDER}
          />

          <TopPlayerList
            contestantId={contestantId}
            reverse={reverse}
            position={PlayerPosition.FORWARD}
          />
        </FormationCont>
      </Box>
    </Pitch>
  );
};
