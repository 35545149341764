import { Box, Card, Typography } from "@material-ui/core";
import { Grid } from "app/components/core/Grid";
import { UpdateUserForm } from "app/components/update-user/UpdateUserForm";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const UpdateUser = () => {
  const email = useSelector((s) => s.user.email);
  const [t] = useTranslation();
  return (
    <Box minHeight="100vh" display="flex" alignItems="center">
      <Grid container>
        <Grid item md={4} />
        <Grid item xs={12} md={4}>
          <Card variant="outlined">
            <Box px={1} py={2}>
              <Typography variant="h6" align="center">
                {t("headers.create_account")}
              </Typography>
              <UpdateUserForm email={email} />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} />
      </Grid>
    </Box>
  );
};
