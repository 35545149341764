import { Box, styled } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";
import { getPeriodBucket } from "features/fixture/fixtureSlice";
import { BucketType } from "features/fixture/interfaces";
import {
  selectFixtureBuckets,
  selectIsFirstStarted,
} from "features/fixture/selectors";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FilterButton } from "../core/FilterButton";
import { PeriodFilterContext } from "./PeriodFilterContext";
const CustomToggleGroup = styled(ToggleButtonGroup)({});

export const PeriodTweak = () => {
  const [t] = useTranslation();
  /* @ts-expect-error */
  const { id } = useParams();
  const buckets = useSelector(selectFixtureBuckets(id));
  const isStarted = useSelector(selectIsFirstStarted(id));
  const isDisabled = React.useCallback(
    (type: BucketType) =>
      !isStarted || !(buckets[type] && buckets[type].statId),
    [buckets, isStarted]
  );

  const { bounds, setBounds } = useContext(PeriodFilterContext);
  const [lower, upper] = bounds;
  const filter = (lower && upper && `${lower.type}-${upper.type}`) || null;
  const dispatch = useDispatch();

  const onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any
  ) => void = (e, value) => {
    if (value === null) {
      setBounds([]);
      return;
    }

    if (!buckets) {
      return;
    }
    const [lo, up] = value.split("-") as [BucketType, BucketType];
    setBounds([
      { type: lo, minute: buckets[lo].matchMin },
      { type: up, minute: buckets[up]?.matchMin },
    ]);
  };

  useEffect(() => {
    const low = buckets[lower?.type || "PM"];
    const up = buckets[upper?.type || "FT"];
    if (low) dispatch(getPeriodBucket([low, up]));
  }, [buckets, dispatch, lower?.type, upper?.type]);
  return (
    <>
      <Box px={1}>
        <CustomToggleGroup
          size="small"
          value={filter}
          exclusive
          onChange={onChange}
        >
          <FilterButton
            name={t("filters.period_match")!}
            value={null}
            disabled={isDisabled("PM")}
          />
          <FilterButton
            name={t("filters.period_PM_HT")!}
            value={"PM-HT"}
            disabled={isDisabled("PM")}
          />
          <FilterButton
            name={t("filters.period_HT_FT")!}
            value={"HT-FT"}
            disabled={isDisabled("HT")}
          />
        </CustomToggleGroup>
      </Box>
      <Box px={1}>
        <CustomToggleGroup
          size="small"
          value={filter}
          exclusive
          onChange={onChange}
        >
          <FilterButton
            name={t("filters.period_PM_15")!}
            value={"PM-15"}
            disabled={isDisabled("PM")}
          />
          <FilterButton
            name={t("filters.period_15_30")!}
            value={"15-30"}
            disabled={isDisabled("15")}
          />
          <FilterButton
            name={t("filters.period_30_HT")!}
            value={"30-HT"}
            disabled={isDisabled("30")}
          />
          <FilterButton
            name={t("filters.period_HT_60")!}
            value={"HT-60"}
            disabled={isDisabled("HT")}
          />
          <FilterButton
            name={t("filters.period_60_75")!}
            value={"60-75"}
            disabled={isDisabled("60")}
          />
          <FilterButton
            name={t("filters.period_75_FT")!}
            value={"75-FT"}
            disabled={isDisabled("75")}
          />
        </CustomToggleGroup>
      </Box>
      <Box px={1}>
        <CustomToggleGroup
          size="small"
          value={filter}
          exclusive
          onChange={onChange}
        >
          <FilterButton
            name={t("filters.last_goal")!}
            value={"LGOAL-FT"}
            disabled={isDisabled("LGOAL")}
          />
          <FilterButton
            name={t("filters.last_red_card")!}
            value={"LRED-FT"}
            disabled={isDisabled("LRED")}
          />
        </CustomToggleGroup>
      </Box>
    </>
  );
};
