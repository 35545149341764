import { Box, styled, Typography } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { DefaultTheme } from "styled-components";
import { SeasonalFilterContext } from "../seasonal/SeasonalFilterContext";
import { TextSkeleton } from "./TextSkeleton";
import { useContSeasonDetails } from "./useContSeasonalDetails";
interface Props {
  contId: string;
  statType: StatType;
}

const Li = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "&::before": {
    content: "'•'",
    lineHeight: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: "2rem",
    color: theme.palette.grey[500],
  },
}));

const Ul = styled(Box)<DefaultTheme, { reverseView: boolean }>(
  ({ reverseView }) => ({
    listStyle: "none",
    "& div": {
      flexDirection: (reverseView && "row-reverse") || "row",
    },
  })
);
const LiWrapper: FC = ({ children }) => (
  <Li py={0.5}>
    <Box display="flex" justifyContent="space-between" flexGrow={1}>
      {children}
    </Box>
  </Li>
);
export const TeamStatDetails: FC<Props> = ({ contId, statType }) => {
  const [t] = useTranslation();

  const status = useSelector((state) => state.fixtures.h2hStatus);
  const { avg, sideRatio, timeRatio } = useContSeasonDetails(contId, statType);
  const { bounds, side, gameLimit } = useContext(SeasonalFilterContext);
  const [lowerMinute, upperMinute] = bounds;
  return (
    <Ul px={2} bgcolor="white" reverseView={false}>
      <LiWrapper>
        <Typography color="textSecondary" variant="button">
          {t("glossary.average_per_match")}
        </Typography>
        <Typography variant="button">
          <TextSkeleton status={status} text={avg} />
        </Typography>
      </LiWrapper>
      <>
        {(lowerMinute !== "PM" || upperMinute !== "FT") && (
          <LiWrapper>
            <Typography variant="button">
              {t(`filters.period_${lowerMinute}_${upperMinute}`)}
              <Typography variant="inherit" color="textSecondary">
                {` / ${t("glossary.fulltime")}`}
              </Typography>
            </Typography>
            <Typography variant="button">
              <TextSkeleton status={status} text={timeRatio} />
            </Typography>
          </LiWrapper>
        )}
      </>
      <>
        {!gameLimit && side && (
          <LiWrapper>
            <Typography variant="button">
              {`${side
                .split("-")
                .map((s) => t(`glossary.${s}`))
                .join("-")}`}
              <Typography variant="inherit" color="textSecondary">
                {` / ${t("glossary.total")}`}
              </Typography>
            </Typography>

            <Typography variant="button">
              <TextSkeleton status={status} text={sideRatio} />
            </Typography>
          </LiWrapper>
        )}
      </>
    </Ul>
  );
};
