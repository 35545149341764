import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import React, { FC } from "react";

interface Props {
  title: string;
}

export const StatDescTooltip: FC<Props> = ({ title }) => (
  <Tooltip title={title}>
    <InfoIcon fontSize="inherit" />
  </Tooltip>
);
