import { StatType } from "shared/enums";
import { StatItem } from "../../interface/StatItem";
export const SEASONAL_STATS_ITEMS: StatItem[] = [
  { name: "Matches", statType: StatType.matches_played },
  {
    name: "Possession %",
    statType: StatType.possession_percentage,
  },
  {
    name: "Goals",
    statType: StatType.goals,
    subStats: [
      { name: "Winning Goal", statType: StatType.winning_goal },
      { name: "Goals Openplay", statType: StatType.goals_openplay },
      { name: "Headed Goals", statType: StatType.att_hd_goal },
      { name: "Penalty Goals", statType: StatType.att_pen_goal },
      { name: "Freekick Goals", statType: StatType.att_freekick_goal },
      { name: "Defender Goals", statType: StatType.defender_goals },
      { name: "Midfielder Goals", statType: StatType.midfielder_goals },
      { name: "Forward Goals", statType: StatType.forward_goals },
    ],
  },
  { name: "Goals Conceded", statType: StatType.goals_conceded },
  { name: "Goal Assist", statType: StatType.goal_assist },
  { name: "Total Attempt Assist", statType: StatType.total_att_assist },
  {
    name: "Shots On Target",
    statType: StatType.ontarget_scoring_att,
  },
  { name: "Shots Off Target", statType: StatType.shot_off_target },
  { name: "Corners Kicks", statType: StatType.corner_taken },
  { name: "Lost Corners", statType: StatType.lost_corners },
  { name: "Total Throws", statType: StatType.total_throws },
  {
    name: "Fouls",
    statType: StatType.fk_foul_lost,
  },
  {
    name: "Yellow Cards",
    statType: StatType.total_yel_card,
  },
  {
    name: "Red Cards",
    statType: StatType.total_red_card,
  },
  { name: "Total Tackle", statType: StatType.total_tackle },
  { name: "Offsides", statType: StatType.total_offside },
  { name: "Woodwork hits", statType: StatType.hit_woodwork },
  { name: "Saves", statType: StatType.saves },
  { name: "Goal Kicks", statType: StatType.goal_kicks },
  { name: "Touch Ball", statType: StatType.touches },
  { name: "Lost Ball", statType: StatType.poss_lost_all },
  {
    name: "Unsuccessful Touch",
    statType: StatType.unsuccessful_touch,
  },
  { name: "Total Pass", statType: StatType.total_pass },
  { name: "Accurate Pass", statType: StatType.accurate_pass },
  { name: "Duels Won", statType: StatType.duel_won },
  { name: "Duels Lost", statType: StatType.duel_lost },
  {
    name: "Total Cross",
    statType: StatType.total_cross_nocorner,
  },
  {
    name: "Accurate Cross",
    statType: StatType.accurate_cross_nocorner,
  },
];
