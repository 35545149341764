import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

interface NameProps {
  name: string;
}
interface TimeMinProps {
  time: number;
}

export const TimeMin: FC<TimeMinProps> = ({ time }) => (
  <Box fontWeight="600" px={1}>
    <Typography variant="inherit">{`${
      time < 10 ? "0" : ""
    }${time}'`}</Typography>
  </Box>
);

export const PlayerName: FC<NameProps> = ({ name }) => (
  <Box px={1}>
    <Typography variant="subtitle1" noWrap>
      {name}
    </Typography>
  </Box>
);

export const PlayerNameHelper: FC<NameProps> = ({ name }) => (
  <Box>
    <Typography variant="caption" color="textSecondary">
      {`(${name})`}
    </Typography>
  </Box>
);
