import { Box } from "@material-ui/core";
import { StatItem } from "app/interface/StatItem";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";

interface Props {
  items: StatItem[];
  index: Nullable<StatType>;
  viewHoc: (name: string, desc: string, type: StatType) => JSX.Element;
}

export const MobileStatsList: FC<Props> = ({
  items,
  index: select,
  viewHoc,
}) => {
  const [tName] = useTranslation("stats");
  const [tDesc] = useTranslation("statsDesc");
  const view = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    view.current?.scrollIntoView({ block: "center" });
  }, []);
  return (
    <Box display="flex">
      {items.map(({ statType }) => (
        <Box
          key={statType}
          flex="0 0 60%"
          mx={1}
          // @ts-ignore
          ref={select === statType ? view : undefined}
        >
          {viewHoc(tName(statType), tDesc(statType), statType)}
        </Box>
      ))}
    </Box>
  );
};
