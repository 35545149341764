import "app/i18n";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./app/store";
const render = () => {
  const App = require("app/App").default;
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback="...">
          <App />
        </Suspense>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}
