import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { AvgStatsContainer } from "./AvgStatsContainer";
import { MatchStatViewProps } from "./MatchStatView";
import { StatDescTooltip } from "./StatDescTooltip";
import { StatCard, StatName, StatValue } from "./Styled";
import { TextSkeleton } from "./TextSkeleton";
type Props = Omit<MatchStatViewProps, "subStats">;

export const StatViewContainer: FC<Props> = ({
  status,
  statName,
  stat = 0,
  isMin = false,
  isSelected,
  clickHandler,
  side,
  statDesc,
  ...avgStat
}) => (
  <StatCard
    variant={isSelected ? "outlined" : "elevation"}
    selected={isSelected}
    onClick={clickHandler}
  >
    <Box px={1} py={0.5}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={side === "Away" ? "row-reverse" : "row"}
      >
        <StatName variant="button">
          <Box
            display="flex"
            alignItems="center"
            flexDirection={side === "Away" ? "row-reverse" : "row"}
          >
            <StatDescTooltip title={statDesc} />
            <Box mx={0.5} component="span">
              {statName}
            </Box>
          </Box>
        </StatName>
        <StatValue variant="h6" isMin={isMin} variantMapping={{ h6: "span" }}>
          <TextSkeleton status={status} text={stat} />
        </StatValue>
      </Box>
    </Box>
    <Box
      display="flex"
      alignItems="center"
      flexDirection={side === "Away" ? "row-reverse" : "row"}
      px={1}
    >
      <AvgStatsContainer side={side} {...avgStat} />
    </Box>
  </StatCard>
);
