import { TermsFR } from "app/components/terms/TermsFR";
import { IntroPage } from "app/pages/intro/IntroPage";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import { Paths } from "./Paths";

export const RoutesWrapper: FC = () => {
  const hasLoggedIn = useSelector((s) => !!s.user.email);
  return (
    <>
      <Route path={Paths.AUTH}>
        <AuthenticationRoutes />
      </Route>
      <Route path={Paths.APP}>
        <AppRoutes />
      </Route>
      <Route path={Paths.TERMS}>
        <TermsFR />
      </Route>
      <Route path={Paths.INTRO} exact>
        {hasLoggedIn ? <Redirect to={Paths.APP} /> : <IntroPage />}
      </Route>
    </>
  );
};
