import { Box, styled, Typography } from "@material-ui/core";
import { TeamStanding } from "features/socket/interfaces";
import React, { FC } from "react";
import { BulletIcon, DownIcon, UpIcon } from "../Calendar/EventIcons";
interface Props {
  rank: number;
  result: TeamStanding["result"];
  reverse?: boolean;
}
const Rank = styled(Typography)(({ theme }) => ({
  minWidth: "1rem",
  ...theme.typography.caption,
  [`${theme.breakpoints.up("md")}`]: {},
}));

export const RankView: FC<Props> = ({ rank, result, reverse = false }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection={reverse ? "row-reverse" : "row"}
  >
    <Box fontSize={"0.5rem"} display="flex" alignItems="inherit" px={0.5}>
      {result === "equal" && <BulletIcon />}
      {result === "up" && <UpIcon />}
      {result === "down" && <DownIcon />}
    </Box>
    <Rank align="center">{rank}</Rank>
  </Box>
);
