import { RootState } from "app/rootReducer";
import { StatsViewContext } from "features/fixture/StatsViewContext";
import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { SEASONAL_STATS_ITEMS } from "./SEASONAL_STATS_ITEMS";
import { SeasonStatsList } from "./SeasonStatsList";

interface Props {
  contestantId: string;
  opponentId: string;
}
export const SeasonalStatsListContainer: FC<Props> = ({
  contestantId,
  opponentId,
}) => {
  const { index, setIndex } = useContext(StatsViewContext);
  const { side, stats } = useSelector((state: RootState) => {
    const contestant = state.fixtures.contestants[contestantId];
    if (!contestant) return {};
    const { side, seasonalStats: stats } = contestant;
    return { side, stats };
  });

  const { stats: oppStats } = useSelector((state: RootState) => {
    const contestant = state.fixtures.contestants[opponentId];
    if (!contestant) return {};
    const { side, seasonalStats: stats } = contestant;
    return { side, stats };
  });

  const status = useSelector((state: RootState) => state.fixtures.h2hStatus);
  return (
    <SeasonStatsList
      index={index}
      contId={contestantId}
      setIndex={setIndex}
      stats={stats}
      oppStats={oppStats}
      side={side}
      status={status}
      items={SEASONAL_STATS_ITEMS}
    />
  );
};
