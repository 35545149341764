import { getPlayersPlayed } from "features/fixture/fixtureSlice";
import { selectFixtureTrn } from "features/fixture/selectFixtureTrn";
import { selectFixtureContestants } from "features/fixture/selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePlayerPlayed = (id: string, enabled: boolean) => {
  const trn = useSelector(selectFixtureTrn(id));
  const [homeId, awayId] = useSelector(selectFixtureContestants(id));
  const dispatch = useDispatch();
  useEffect(() => {
    if (trn?.id && enabled)
      dispatch(
        getPlayersPlayed({
          homeId,
          awayId,
          seasonId: trn.id,
        })
      );
  }, [awayId, dispatch, enabled, homeId, trn]);
};
