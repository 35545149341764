import {
  Box,
  Divider,
  Hidden,
  InputBase,
  MenuItem,
  Paper,
  Select,
  styled,
  Typography,
} from "@material-ui/core";
import { Event } from "@material-ui/icons";
import {
  selectFixPeriodBucket,
  selectFixQuarterBucket,
} from "features/fixture/selectors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { StatsViewContext } from "../../../features/fixture/StatsViewContext";
import { useStatsViewIndex } from "../../../utils/useStatsViewIndex";
import { Grid } from "../core/Grid";
import { FixtureInfo } from "../Fixture/FixtureInfo";
import { Formation } from "../Fixture/Pitch/Formation";
import { SubsFormation } from "../Fixture/Pitch/SubsFormation";
import { useIsUserSubscribed } from "../Fixture/useIsUserSubscribed";
import { FilterContainer } from "../trn-stats/FilterContainer";
import { ControlledSwiperWrapper } from "./ControlledSwiperWrapper";
import { MobileStatsListContainer } from "./MobileStatsListContainer";
import { MobileStatsWrapper } from "./MobileStatsWrapper";
import { PeriodFilterContext, usePeriodFilter } from "./PeriodFilterContext";
import { PeriodTweak } from "./PeriodTweak";
import { StatsListContainer } from "./StatsListContainer";
import { usePeriodSeasonalEffect as useLiveSeasonalEffect } from "./useLiveSeasonalEffect";
import { usePlayerPlayed } from "./usePlayerPlayed";
import { AskToSubscribeAlert } from "./VerifiedAlert";

interface Props {
  homeContestantId: string;
  awayContestantId: string;
  fixtureId: string;
}
const StyledInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 4,
  position: "relative",
  border: "1px solid black",
  paddingLeft: 7,
  paddingRight: 7,
  margin: `0px ${theme.spacing(1)}px`,
}));
export const MatchStats: FC<Props> = ({
  homeContestantId,
  awayContestantId,
  fixtureId,
}) => {
  const [t] = useTranslation("stats");
  const isSubscribed = useIsUserSubscribed();
  const filter = usePeriodFilter();
  const statViewCtx = useStatsViewIndex(StatType.matches_played);
  const status = useSelector((state) => state.socketStatus.initFixtureStatus);
  const [qLow, qUp] = useSelector(selectFixPeriodBucket(fixtureId));
  const [pLow, pUp] = useSelector(selectFixQuarterBucket(fixtureId));
  usePlayerPlayed(fixtureId, status === "success");
  const [gameLimit, setGameLimit] = React.useState(3);
  useLiveSeasonalEffect(fixtureId, "PM", "FT", gameLimit, status === "success");
  useLiveSeasonalEffect(fixtureId, qLow, qUp, gameLimit, status === "success");
  useLiveSeasonalEffect(fixtureId, pLow, pUp, gameLimit, status === "success");
  return (
    <StatsViewContext.Provider value={statViewCtx}>
      <PeriodFilterContext.Provider value={filter}>
        <FilterContainer>
          <>
            <PeriodTweak />
            <Divider orientation="vertical" flexItem />
            <Box>
              <Select
                value={gameLimit}
                onChange={(e) => setGameLimit(e.target.value as number)}
                input={<StyledInput />}
                renderValue={(v) => (
                  <Box display="flex" alignItems="center">
                    <Event fontSize="small" />
                    <Box ml={0.5}>
                      <Typography variant="subtitle2">{`Last ${v} avg`}</Typography>
                    </Box>
                  </Box>
                )}
              >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </Box>
          </>
        </FilterContainer>
        <Hidden mdUp>
          <Grid
            container
            spacing={1}
            position="sticky"
            top={60}
            zIndex="appBar"
            bgcolor="background.default"
          >
            <MobileStatsWrapper>
              <Grid item xs={12}>
                <MobileStatsListContainer
                  homeId={homeContestantId}
                  awayId={awayContestantId}
                  fixtureId={fixtureId}
                />
              </Grid>
            </MobileStatsWrapper>
          </Grid>
        </Hidden>
        <Box display="flex">
          <Box width={{ xs: 0, md: "20%" }}>
            <Hidden mdDown>
              <StatsListContainer
                contestantId={homeContestantId}
                opponentId={awayContestantId}
                fixtureId={fixtureId}
                side="Home"
              />
            </Hidden>
          </Box>
          <Box width={{ xs: "100%", md: "60%" }}>
            <Box position={{ md: "sticky" }} top={161}>
              <Box
                pt={0.5}
                textAlign="center"
                bgcolor={"#4caf50"}
                color="white"
              >
                <Typography variant="h6">
                  {t(statViewCtx.index ?? StatType.matches_played)}
                </Typography>
              </Box>
              <Box position="relative">
                {!isSubscribed &&
                  statViewCtx.index !== StatType.matches_played && (
                    <AskToSubscribeAlert />
                  )}
                <ControlledSwiperWrapper>
                  <Box
                    height={{
                      xs: `calc(100dvh - 191px)`,
                      md: `calc(93vh - 335px)`,
                    }}
                  >
                    <Formation contestantId={homeContestantId} side="Home" />
                  </Box>
                  <SubsFormation contestantId={homeContestantId} side="Home" />
                  <Box
                    height={{
                      xs: `calc(100dvh - 191px)`,
                      md: `calc(93vh - 335px)`,
                    }}
                  >
                    <Formation contestantId={awayContestantId} side="Away" />
                  </Box>
                  <SubsFormation contestantId={awayContestantId} side="Away" />
                </ControlledSwiperWrapper>
              </Box>
              <Box>
                <Paper variant="outlined" square>
                  <FixtureInfo fixtureId={fixtureId} />
                </Paper>
              </Box>
            </Box>
          </Box>
          <Box width={{ xs: 0, md: "20%" }}>
            <Hidden mdDown>
              <StatsListContainer
                contestantId={awayContestantId}
                opponentId={homeContestantId}
                fixtureId={fixtureId}
                side="Away"
              />
            </Hidden>
          </Box>
        </Box>
      </PeriodFilterContext.Provider>
    </StatsViewContext.Provider>
  );
};
