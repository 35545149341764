import { Theme, useMediaQuery } from "@material-ui/core";
import {
  selectFixtureContestants,
  selectHasMatchStats,
  selectIsLiveFixture,
} from "features/fixture/selectors";
import { StatsViewContext } from "features/fixture/StatsViewContext";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { FC } from "react";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import styled from "styled-components";
import { useStatsViewIndex } from "utils/useStatsViewIndex";
import { Grid } from "../core/Grid";
import {
  PeriodFilterContext,
  usePeriodFilter,
} from "../Stats/PeriodFilterContext";
import { FixtureOverview } from "./FixtureOverview";
import { FixtureRoutes } from "./FixtureRoutes";
import { MinimalFixtureOverview } from "./MinimalFixtureOverview";
import { TabBar } from "./TabBar";
import { useFixtureTitle } from "./useFixtureTitle";
interface TabsContainerProps {
  fixtureId: string;
}

const OverviewMotion = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.drawer};
`;

export const FixtureLayout: FC<TabsContainerProps> = ({ fixtureId }) => {
  useFixtureTitle(fixtureId);
  const hasStats = useSelector(selectHasMatchStats(fixtureId));
  const isLive = useSelector(selectIsLiveFixture(fixtureId));
  const [homeId, awayId] = useSelector(selectFixtureContestants(fixtureId));
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 116], [-116, 0]);

  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const filter = usePeriodFilter();
  const statViewCtx = useStatsViewIndex(StatType.matches_played);
  return (
    <StatsViewContext.Provider value={statViewCtx}>
      <PeriodFilterContext.Provider value={filter}>
        <OverviewMotion style={{ y: y }}>
          <MinimalFixtureOverview
            homeId={homeId}
            awayId={awayId}
            fixtureId={fixtureId}
          />
        </OverviewMotion>
        <Grid container>
          <Grid item md={3} />
          <Grid item md={6} xs={12}>
            <FixtureOverview
              homeId={homeId}
              awayId={awayId}
              fixtureId={fixtureId}
            />
          </Grid>
          <Grid item md={3} />
        </Grid>
        <Grid
          container
          position={{ md: "sticky" }}
          top={60}
          zIndex="appBar"
          bgcolor="background.default"
        >
          <Grid item md={3} />
          <Grid item md={6} xs={12}>
            <TabBar
              variant={upMd ? "fullWidth" : "scrollable"}
              isMatchActive={hasStats}
              isLive={isLive}
            />
          </Grid>
          <Grid item md={3} />
        </Grid>
        <FixtureRoutes fixtureId={fixtureId} />
      </PeriodFilterContext.Provider>
    </StatsViewContext.Provider>
  );
};
