import { createContext } from "react";
import { StatType } from "shared/enums";

interface View {
  index: StatType | null;
  setIndex: (i: StatType | null) => void;
}
const selectedStats: View = {
  index: null,
  setIndex: (i) => {},
};
export const StatsViewContext = createContext(selectedStats);
