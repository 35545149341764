import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

interface Props {
  length: number;
}

export const IndexColumn: FC<Props> = ({ length }) => (
  <Box display="flex" flexDirection="column" bgcolor="#00000038">
    {Array.from(Array(length)).map((_, i) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexBasis='20%'
        width={40}
        // pb={4}
        color="white"
      >
        <Typography color="inherit" variant="caption" align="center">
          {i + 1}
        </Typography>
      </Box>
    ))}
  </Box>
);
