import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

const BackgroundImage = styled.div`
  --line-color: #8fd298;
  --bg-color: #4caf50;
  ${({ theme }) => `
  position: relative;
  background-color: var(--bg-color);
  height: 100%;
  width: 100%;
`}
`;

const Circle = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border: 2px solid var(--line-color);
  border-radius: 50%;
  top: calc(50% - 50px);
`;

const Border = styled.div``;
const GoalBox = styled.div``;
const PenaltyBox = styled.div``;
const Arc = styled.div``;
const ContestantZone = styled.div<{ side?: "Home" | "Away" }>`
  position: absolute;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  padding: ${({ side }) =>
    side === "Home" ? "5px 0px 5px 5px" : "5px 5px 5px 0px"};
  box-sizing: border-box;

  ${Circle} {
    right: ${({ side }) => (side === "Home" ? "-50px" : "auto")};
    left: ${({ side }) => (side === "Away" ? "-50px" : "auto")};
  }
  ${Border} {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: solid var(--line-color);
    border-width: ${({ side }) =>
      side === "Home" ? "2px 2px 1px" : "1px 2px 2px"};
  }

  ${GoalBox} {
    position: absolute;
    background-color: var(--bg-color);
    border: solid var(--line-color);
    z-index: 3;
    width: 40%;
    height: 40px;
    border-width: ${({ side }) =>
      side === "Home" ? "0px 2px 2px" : "2px 2px 0px"};
    top: ${({ side }) => side === "Home" && "0"};
    bottom: ${({ side }) => !(side === "Home") && "0"};
    left: 30%;
  }

  ${PenaltyBox} {
    position: absolute;
    background-color: var(--bg-color);
    border: solid var(--line-color);
    z-index: 2;
    width: 60%;
    height: 80px;
    border-width: ${({ side }) =>
      side === "Home" ? "0px 2px 2px" : "2px 2px 0px"};
    top: ${({ side }) => side === "Home" && "0"};
    bottom: ${({ side }) => !(side === "Home") && "0"};
    left: 20%;
  }

  ${Arc} {
    position: absolute;
    border: 2px solid var(--line-color);
    z-index: 1;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: ${({ side }) => side === "Home" && "0"};
    bottom: ${({ side }) => !(side === "Home") && "0"};
    left: calc(50% - 50px);
  }

  ${({ theme }) => theme.breakpoints.up("xs")} {
    ${Border} {
      border-width: ${({ side }) =>
        side === "Home" ? "2px 1px 2px 2px" : "2px 2px 2px 1px"};
    }
    ${GoalBox} {
      height: 40%;
      width: 40px;
      border-width: ${({ side }) =>
        side === "Home" ? "2px 2px 2px 0px" : "2px 0px 2px 2px"};
      top: 30%;
      left: ${({ side }) => (side === "Home" ? "0" : "auto")};
      right: ${({ side }) => (!(side === "Home") ? "0" : "auto")};
    }
    ${PenaltyBox} {
      height: 60%;
      width: 80px;
      border-width: ${({ side }) =>
        side === "Home" ? "2px 2px 2px 0px" : "2px 0px 2px 2px"};
      top: 20%;
      left: ${({ side }) => (side === "Home" ? "0" : "auto")};
      right: ${({ side }) => (!(side === "Home") ? "0" : "auto")};
    }
    ${Arc} {
      top: calc(50% - 50px);
      left: ${({ side }) => (side === "Home" ? "0" : "auto")};
      right: ${({ side }) => (!(side === "Home") ? "0" : "auto")};
    }
  }
`;

interface Props {
  side?: "Home" | "Away";
}
export const Pitch: FC<Props> = ({ children, side = "Home" }) => {
  return (
    <Box width="100%" height="100%">
      <>
        <BackgroundImage>
          <ContestantZone side={side}>
            <Border>
              <GoalBox />
              <PenaltyBox />
              <Arc />
            </Border>
            <Circle />
          </ContestantZone>
          {children}
        </BackgroundImage>
      </>
    </Box>
  );
};
