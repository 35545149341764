import { Box } from "@material-ui/core";
import { Grid } from "app/components/core/Grid";
import { ForgetPass } from "app/components/forget-pass/ForgetPass";
import { Login } from "app/components/login/Login";
import { NewPass } from "app/components/new-password/NewPass";
import { SignUp } from "app/components/signup/SignUp";
import StatistigoalIcon from "app/icons/StatistigoalIcon";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { LangSwitcher } from "../components/core/LangSwitcher";
import { Paths } from "./Paths";
export const AuthenticationRoutes: FC = () => {
  const history = useHistory();
  const hasLoggedIn = useSelector((state) => !!state.user.id);

  useEffect(() => {
    if (hasLoggedIn) {
      history.replace(Paths.APP);
    }
  }, [history, hasLoggedIn]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
      my={8}
    >
      <Grid container spacing={8}>
        <Grid item md={4} />
        <Grid
          item
          md={4}
          xs={12}
          bgcolor="background.paper"
          border={1}
          borderRadius="borderRadius"
          borderColor="grey.400"
        >
          <Box pb={2} fontSize="h2.fontSize" textAlign="center">
            <StatistigoalIcon />
          </Box>
          <Switch>
            <Route path={Paths.LOGIN}>
              <Login />
            </Route>
            <Route path={Paths.FORGET_PASS}>
              <ForgetPass />
            </Route>
            <Route path={`${Paths.NEW_PASS}/:token`}>
              <NewPass />
            </Route>
            <Route path={Paths.SIGN_UP}>
              <SignUp />
            </Route>
          </Switch>
          <LangSwitcher />
        </Grid>
        <Grid item md={4} />
      </Grid>
    </Box>
  );
};
