import { PayloadAction } from "@reduxjs/toolkit";
import { fetchPeriodStats } from "app/API";
import {
  GetPeriodBucket,
  getPeriodBucket,
  PeriodBucketsEntity,
  successPeriodBucket
} from "features/fixture/fixtureSlice";
import { periodStatsSchema } from "features/fixture/schemas/periodStatsSchema";
import { normalize } from "normalizr";
import { Epic } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import { httpErrorHandler } from "./httpErrorHandler";

const mapPayloadToBounds = ({
  payload,
}: PayloadAction<GetPeriodBucket>): GetPeriodBucket => {
  const [low, up] = payload;
  if (up && !up.statId) return [low];

  return payload;
};

export const fetchPeriodStatsEpic: Epic = (actions$) =>
  actions$.pipe(
    ofActionCreator(getPeriodBucket),
    map(mapPayloadToBounds),
    mergeMap((bounds) =>
      fetchPeriodStats(bounds).pipe(
        mergeMap(({ data }) =>
          from(data).pipe(
            map((stat) =>
              normalize<any, PeriodBucketsEntity>(stat, periodStatsSchema)
            ),
            map((normal) => successPeriodBucket(normal))
          )
        ),
        catchError((e) => httpErrorHandler(e, getPeriodBucket(bounds)))
      )
    )
  );
