import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { selectFixtureDate } from "features/fixture/selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";
interface Props {
  id: string;
}
export const FixtureDate: FC<Props> = ({ id }) => {
  const date = useSelector(selectFixtureDate(id));
  return (
    <Typography variant="caption">
      {date || <Skeleton variant="text" width={50} />}
    </Typography>
  );
};
