import TimerIcon from "@material-ui/icons/Timer";
import React from "react";
import styled from "styled-components";

const Container = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin: 4px;
  height: 1em;
  width: 1em;

  & > svg {
    top: -3px;
    left: -3px;
    bottom: 0;
    right: 0;

    position: absolute;
    margin: 0 auto;
    animation: live 1s infinite ease-in-out;
    animation-fill-mode: both;
    &:nth-child(1) {
      animation-delay: -0.1s;
    }
  }
  @keyframes live {
    0%,
    80%,
    100% {
      transform: scale(0.8);
    }
    40% {
      transform: scale(1);
    }
  }
`;

export const TimerFeedback = () => (
  <Container>
    <TimerIcon fontSize="small" />
  </Container>
);
