import { Box, Typography } from "@material-ui/core";
import SoccerIcon from "@material-ui/icons/SportsSoccer";
import { Skeleton } from "@material-ui/lab";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectCalendarDetails } from "./CalendarsSchedule";

interface Props {
  id: string;
}

export const CalendarSummary: FC<Props> = ({ id }) => {
  const status = useSelector((state) => state.socketStatus.getCalendarStatus);
  const { calendarName, competition } = useSelector(selectCalendarDetails(id));
  return (
    <Box py={1} display="flex" justifyContent="center" alignItems="center">
      {status === "loading" && <Skeleton width="100px" />}
      {status === "success" && (
        <Box display="flex" justifyContent="center" alignItems="center">
          {competition.imagePath ? (
            <img
              src={competition.imagePath}
              width="32px"
              alt={competition.name}
            />
          ) : (
            <SoccerIcon />
          )}
          <Box px={1}>
            <Typography variant="h6" align="center">
              {competition?.name}
              <Typography variant="subtitle1" component="span">
                {` (${calendarName})`}
              </Typography>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
