import { fetchCountMatchesReq } from "app/API";
import { httpErrorHandler } from "features/fixture/httpErrorHandler";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import {
  getCountMatchesByDate,
  setCountMatchesByDate
} from "./calendarSlice";

export const fetchCountMatchesByDateEpic: Epic = (action$) =>
  action$.pipe(
    ofActionCreator(getCountMatchesByDate),
    mergeMap(({ payload }) =>
      fetchCountMatchesReq(payload).pipe(
        map(({ data }) => setCountMatchesByDate(data)),
        catchError((e) => httpErrorHandler(e, getCountMatchesByDate))
      )
    )
  );
