import { Box, Typography } from "@material-ui/core";
import { StatItem } from "app/interface/StatItem";
import { RemoteStatus, StatDto } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { StatDescTooltip } from "../Stats/StatDescTooltip";
import { StatCard, StatValue } from "../Stats/Styled";
import { TextSkeleton } from "../Stats/TextSkeleton";

interface Props {
  items: StatItem[];
  index: Nullable<StatType>;
  status: RemoteStatus;
  setIndex: (s: Nullable<StatType>) => void;
  stats?: { [key: string]: StatDto };
}

export const MobileTrnStatsList: FC<Props> = ({
  items,
  index: select,
  setIndex: setSelect,
  stats = {},
  status,
}) => {
  const [tName] = useTranslation("stats");
  const [tDesc] = useTranslation("statsDesc");
  return (
    <Box display="flex">
      {items.map(({ statType }, i) => (
        <Box key={i} flex="0 0 60%" mx={1}>
          <StatCard
            variant={statType === select ? "outlined" : "elevation"}
            selected={statType === select}
          >
            <Box
              p={1}
              display="flex"
              alignItems="center"
              onClick={() => setSelect(statType)}
              justifyContent="space-between"
            >
              <Box pr={1}>
                <Typography
                  variant="button"
                  align="center"
                  color="textSecondary"
                  component="h5"
                  noWrap
                >
                  <Box display="flex" alignItems="center">
                    <StatDescTooltip title={tDesc(statType)} />
                    <Box mx={0.5} component="span">
                      {tName(statType)}
                    </Box>
                  </Box>
                </Typography>
              </Box>

              <StatValue variant="h5" isMin={false}>
                <TextSkeleton
                  status={status}
                  text={stats[statType]?.value || "0"}
                />
              </StatValue>
            </Box>
          </StatCard>
        </Box>
      ))}
    </Box>
  );
};
