import { RootState } from "app/rootReducer";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { filterValue } from "utils/filterValue";
import { PeriodFilterContext } from "./PeriodFilterContext";

export const useContestantStats = (contestantId: string) => {
  const { bounds } = useContext(PeriodFilterContext);
  const { stats, periodStats } = useSelector((state: RootState) => {
    const contestant = state.fixtures.contestants[contestantId];
    if (!contestant) return {};
    const { side, stats, bucketStats } = contestant;
    const [low, up] = bounds;
    if (low && up && bucketStats && bucketStats[low.type]) {
      const lowStats = bucketStats[low.type];
      const upStats = bucketStats[up.type] || stats;
      const upMin = up.minute || contestant.matchMin;
      const periodStats = filterValue([lowStats, upStats], [low.minute, upMin]);
      return { side, periodStats };
    }
    return { side, stats };
  });
  return stats || periodStats || {};
};
