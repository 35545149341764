import { Box, Button, Card, Typography } from "@material-ui/core";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  createBillingPortal,
  createCheckoutSession,
  fetchPlans,
} from "app/API";
import React, { FC } from "react";
import { useQuery } from "utils/useQuery";
interface PlanDto {
  id: string;
  product: {
    id: string;
    name: string;
  };
  unit_amount: number;
  currency: string;
  recurring: {
    interval: "month" | "year";
  };
}
const usePlans = () => {
  const [plans, setPlans] = React.useState<PlanDto[]>([]);
  const [index, setIndex] = React.useState<number>(0);
  React.useEffect(() => {
    const fetch = async () => {
      const { data } = await fetchPlans<PlanDto>();
      setPlans(data);
    };
    fetch();
  }, []);
  return { plan: plans[index], index, setIndex };
};
const useCheckoutSession = () => {
  const query = useQuery();
  const success = query.get("success") === "true";
  const canceled = query.get("canceled") === "true";
  const session_id = query.get("session_id");
  return { success, canceled, session_id };
};
const Plan: FC<{
  id: string;
  name: string;
  amount: number;
  interval: string;
}> = ({ id, amount, interval, name }) => (
  <Card variant="outlined">
    <Box p={4} minWidth={400}>
      <Typography variant="body1">{name}</Typography>
      <Typography variant="h5">
        {amount}$ / {interval}
      </Typography>
      <Box my={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={async () => {
            const session = await createCheckoutSession(id);
            window.location.href = session.url;
          }}
        >
          Checkout
        </Button>
      </Box>
    </Box>
  </Card>
);
const PlanWrapper: FC = () => {
  const { plan, index, setIndex } = usePlans();
  return (
    <>
      <Box my={1}>
        <ToggleButtonGroup
          value={index}
          onChange={(_, v) => setIndex(v)}
          exclusive
          size="small"
        >
          <ToggleButton value={0}>Annual</ToggleButton>
          <ToggleButton value={1}>Monthly</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {plan && (
        <Plan
          id={plan.id}
          name={plan.product.name}
          amount={plan.unit_amount / 100}
          interval={plan.recurring.interval}
        />
      )}
      {!plan && <Skeleton variant="rect" height={100} width={200} />}
    </>
  );
};
const SuccussCheckoutSession: FC<{ session_id: string }> = ({ session_id }) => (
  <Card variant="outlined">
    <Box p={4}>
      <Box pb={2} clone>
        <Typography variant="h5">🎉 Your 14 day trial is active now</Typography>
      </Box>

      <Button variant="contained" color="primary" href={"/app"} fullWidth>
        Start using Statistigoal
      </Button>
      <Box my={1} />
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={async () => {
          const session = await createBillingPortal();
          window.location.href = session.url;
        }}
      >
        Manage Subscription
      </Button>
    </Box>
  </Card>
);
export const CheckoutPage = () => {
  const { success, canceled, session_id } = useCheckoutSession();
  return (
    <>
      <Box
        maxWidth={800}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mx="auto"
      >
        {(canceled || !session_id) && <PlanWrapper />}
        {success && session_id && (
          <SuccussCheckoutSession session_id={session_id} />
        )}
      </Box>
    </>
  );
};
