import { indigo } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none",
          color: "inherit",
        },
        "a:hover": {
          cursor: "pointer",
        },
        li: {
          listStyle: "none",
          padding: 0,
        },
        ul: {
          margin: 0,
          padding: 0
        },
      },
    },
  },
  palette: {
    primary: indigo,
  },
});
