import { getSeasonAvg } from "features/fixture/fixtureSlice";
import { BucketType } from "features/fixture/interfaces";
import { selectFixtureTrn } from "features/fixture/selectFixtureTrn";
import { selectFixtureContestants } from "features/fixture/selectors";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePeriodSeasonalEffect = (
  id: string,
  lowerMinute: BucketType | undefined,
  upperMinute: BucketType | undefined,
  gameLimit: number,
  enabled: boolean
) => {
  const trn = useSelector(selectFixtureTrn(id));
  const [homeId, awayId] = useSelector(selectFixtureContestants(id));
  const dispatch = useDispatch();
  useEffect(() => {
    if (trn && lowerMinute && upperMinute && enabled)
      dispatch(
        getSeasonAvg({
          seasonId: trn.id,
          teams: [
            { id: homeId, side: "home" },
            { id: awayId, side: "away" },
          ],
          lowerMinute,
          upperMinute,
          gameLimit,
        })
      );
  }, [
    awayId,
    dispatch,
    enabled,
    gameLimit,
    homeId,
    lowerMinute,
    trn,
    upperMinute,
  ]);
};
