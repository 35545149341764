import { useHistory } from "react-router-dom";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { useQuery } from "utils/useQuery";

export const useStatsViewIndex = (defaultType: Nullable<StatType> = null) => {
  const history = useHistory();
  const query = useQuery();
  const index = (query.get("stat") || defaultType) as Nullable<StatType>;
  const setIndex = (index: Nullable<StatType>) => {
    if (index === null) {
      query.delete("stat");
    } else {
      query.set("stat", index);
    }
    history.replace({ search: query.toString() });
  };
  return { index, setIndex };
};
