import { useSelector } from "react-redux";

export const useCountryCalendars = (countryId: string) =>
  useSelector((state) =>
    state.calendar.ids.filter(
      (id) =>
        state.calendar.byId[id].details.competition.country.countryId ===
          countryId &&
        (state.calendar.live
          ? state.calendar.countMatches[id]?.live
          : state.calendar.countMatches[id]?.all)
    )
  );
