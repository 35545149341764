import { BucketType } from "features/fixture/interfaces";
import { createContext } from "react";
import { useQueryState } from "../Stats/useQueryState";

interface TrnFilter {
  bounds: [BucketType, BucketType];
  gameLimit?: number;
  side?: "home" | "away";
  setBounds: (bounds: [BucketType, BucketType]) => void;
  setGameLimit: (limit?: number) => void;
  setSide: (side?: "home" | "away") => void;
}

const filter: TrnFilter = {
  bounds: ["PM", "FT"],
  setBounds: (bounds: [BucketType, BucketType]) => {},
  setGameLimit: (limit?: number) => {},
  setSide: (side?: "home" | "away") => {},
};

export const TrnFilterContext = createContext(filter);

export const useTrnFilter = () => {
  const [bounds, setBounds] = useQueryState<[BucketType, BucketType]>(
    "bounds",
    ["PM", "FT"]
  );
  const [gameLimit, setGameLimit] = useQueryState<number | undefined>("gl");
  const [side, setSide] = useQueryState<"home" | "away" | undefined>("sd");

  return {
    bounds,
    gameLimit,
    side,
    setGameLimit,
    setBounds,
    setSide,
  };
};
