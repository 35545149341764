import { Box } from "@material-ui/core";
import { Period } from "features/fixture/dto/Period";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { PeriodId } from "shared/enums";
import {
  selectFixturePeriod,
  selectMergedPeriodEvents,
} from "../../../features/fixture/selectors";
import { BasicFixtureEvent } from "./FixtureEvents/BasicFixtureEvent";
import { PeriodEvent } from "./FixtureEvents/PeriodEvent";
interface Props {
  fixtureId: string;
  period: Period;
  periodId: PeriodId;
}
export const PeriodHighlights: FC<Props> = ({
  fixtureId,
  period,
  periodId,
}) => {
  const { score = "", events } = useSelector(
    selectMergedPeriodEvents(fixtureId, period, periodId)
  );

  const current = useSelector(selectFixturePeriod(fixtureId));
  return (
    <>
      {current && current !== Period.PRE_MATCH && (
        <>
          <PeriodEvent period={period} score={score} />
          {events.length === 0 && <Empty />}
          {events.map((e, i) => (
            <BasicFixtureEvent event={e} key={i} />
          ))}
        </>
      )}
    </>
  );
};

const Empty = () => <Box textAlign="center">-</Box>;
