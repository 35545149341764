import { Box, Button, Paper, Typography } from "@material-ui/core";
import { submitForgetPass } from "app/API";
import { CreateForgetPass } from "features/user/dto/create-forget-pass";
import { Field, Form, Formik } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FailedMessage } from "../core/FailedMessage";
import { TextField } from "../core/inputs/TextField";

interface Props {}
export const ForgetPassForm: FC<Props> = () => {
  const initialValues: CreateForgetPass = {
    email: "",
  };
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email().required(),
      }),
    []
  );
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values, helper) => {
        const { setStatus, setSubmitting } = helper;
        try {
          const { data } = await submitForgetPass(values);
          if (data.success) {
            setSubmitting(false);
            setStatus({ success: true });
          }
        } catch (err) {
          /* @ts-expect-error */
          const error = err.response.data.message;
          setStatus({ error });
          setSubmitting(false);
        }
      }}
      initialStatus={{
        isLoading: false,
        success: false,
        error: "",
      }}
    >
      {({ isSubmitting, status, values }) => (
        <>
          {status.success && (
            <Paper variant="outlined">
              <Box py={2}>
                <Box px={4} py={2}>
                  <Typography variant="body1" align="center">
                    {t("forget_password.success", { email: values.email })}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          )}
          {!status.success && (
            <>
              <Form>
                <Box display="flex" flexDirection="column">
                  <Field
                    type="email"
                    name="email"
                    label={t("accounts.email_field")}
                    component={TextField}
                  />
                  <FailedMessage error={status.error} />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                  >
                    {t("button.submit")}
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </>
      )}
    </Formik>
  );
};
