import { fetchRefereeReq } from "app/API";
import { httpErrorHandler } from "features/fixture/httpErrorHandler";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import { fetchReferee, refereeFetched } from "./refereesSlice";

export const fetchRefereeEpic: Epic = (action$) =>
  action$.pipe(
    ofActionCreator(fetchReferee),
    mergeMap(({ payload }) =>
      fetchRefereeReq(payload.calId, payload.refereeId).pipe(
        map(({ data }) => refereeFetched(data)),
        catchError((e) => httpErrorHandler(e, fetchReferee))
      )
    )
  );
