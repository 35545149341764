import { createSelector } from "@reduxjs/toolkit";
import { getPlayerStatType } from "features/fixture/usePlayerStatType";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { selectCalendar } from "./selectCalendar";

export const selectTrnPlayersStats = (id: string, type: Nullable<StatType>) =>
  createSelector(selectCalendar(id), ({ stats }) => {
    const playerStatType = getPlayerStatType(type);
    return playerStatType ? stats.players[playerStatType] : [];
  });
