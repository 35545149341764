import { Box, Button } from "@material-ui/core";
import { submitNewPass } from "app/API";
import { Paths } from "app/routes/Paths";
import { CreateNewPass } from "features/user/dto/create-new-pass";
import { JwtPayload } from "features/user/types";
import { successLogin } from "features/user/userSlice";
import { Field, Form, Formik } from "formik";
import JwtDecode from "jwt-decode";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { LocalStorageKeys } from "shared/constants";
import { saveItem } from "shared/local-storage";
import * as Yup from "yup";
import { FailedMessage } from "../core/FailedMessage";
import { PasswordTextField } from "../core/inputs/PasswordTextField";
export const NewPassForm: FC = () => {
  /* @ts-expect-error */
  const { token } = useParams();
  const initialValues: CreateNewPass = {
    password: "",
    confirmPassword: "",
    token,
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  let { from } = location.state || { from: { pathname: Paths.APP } };
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string().min(8).required(),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], t("validation.pass_field_must_match")!)
          .required(),
      }),
    [t]
  );

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values, helper) => {
        const { setStatus, setSubmitting } = helper;
        try {
          const { data } = await submitNewPass(values);
          const { accessToken } = data;
          if (accessToken) {
            dispatch(successLogin(data));
            const jwtPayload = JwtDecode<JwtPayload>(accessToken);
            setSubmitting(false);
            saveItem(LocalStorageKeys.USER, jwtPayload);
            history.replace(from);
          }
        } catch (err) {
          /* @ts-expect-error */
          const error = err.response.data.message;
          setStatus({ error });
          setSubmitting(false);
        }
      }}
      initialStatus={{
        isLoading: false,
        success: false,
        error: "",
      }}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <Field
              name="password"
              label={t("accounts.password_field")}
              helperText={t("accounts.password_help")}
              component={PasswordTextField}
            />
            <Field
              name="confirmPassword"
              label={t("accounts.confirm_password_field")}
              component={PasswordTextField}
            />
            <FailedMessage error={status.error} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isSubmitting}
            >
              {t("button.submit")}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
