import { Box, Typography, useTheme } from "@material-ui/core";
import { RefereeStatType } from "features/referees/RefereeStatType";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  RedCardIcon,
  RefereeIcon,
  YellowCardIcon,
} from "../Calendar/EventIcons";
import { Grid } from "../core/Grid";

type Props = {
  name?: string;
} & Partial<Record<RefereeStatType, number | string>>;

export const RefereeStatsView: FC<Props> = ({
  name,
  cards_per_game,
  fouls,
  fouls_per_card,
  games,
  penalties,
  red_cards,
  yellow_cards,
}) => {
  const theme = useTheme();
  const [t] = useTranslation();
  return (
    <Box my={{ md: 8, xs: 4 }} mx={{ md: 8, xs: 4 }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <RefereeIcon fontSize={theme.typography.h3.fontSize} />
        <Typography variant="h4" align="center">
          {name}
        </Typography>
      </Box>
      <Box bgcolor={theme.palette.grey[100]} py={2} my={2}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Box textAlign="center" position="relative">
              <Typography variant="h1">{games}</Typography>
              <Box position="absolute" bottom="-0.75rem" left={0} right={0}>
                <Typography variant="subtitle2">
                  {t("glossary.games")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                py={1}
              >
                <RedCardIcon fontSize={theme.typography.h2.fontSize} />
                <Box minWidth="2.5rem" mr="3.75rem">
                  <Typography variant="h4">{red_cards}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                py={1}
              >
                <YellowCardIcon fontSize={theme.typography.h2.fontSize} />
                <Box minWidth="2.5rem" mr="3.75rem">
                  <Typography variant="h4">{yellow_cards}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Table
        fouls_per_card={fouls_per_card}
        cards_per_game={cards_per_game}
        penalties={penalties}
        fouls={fouls}
      />
    </Box>
  );
};
interface StatProps {
  name: string;
  value: string | number;
  row: string;
  col: string;
}
const Stat: FC<StatProps> = ({ name, value, row, col }) => (
  <Box textAlign="center" bgcolor="white" gridRow={row} gridColumn={col} py={4}>
    <Typography variant="h4">{value}</Typography>
    <Typography variant="subtitle2" color="textSecondary">
      {name}
    </Typography>
  </Box>
);

const Table: FC<
  Pick<Props, "fouls_per_card" | "cards_per_game" | "penalties" | "fouls">
> = ({
  fouls_per_card = "-",
  cards_per_game = "-",
  penalties = "-",
  fouls = "-",
}) => {
  const theme = useTheme();
  const [t] = useTranslation();
  return (
    <Box
      display="grid"
      gridGap="2px"
      gridTemplateRows="1fr 1fr"
      gridTemplateColumns="1fr 1fr"
      bgcolor={theme.palette.grey[100]}
    >
      <Stat
        name={t("glossary.fouls_per_card")}
        value={fouls_per_card}
        row="1"
        col="1"
      />
      <Stat
        name={t("glossary.cards_per_game")}
        value={cards_per_game}
        row="1"
        col="2"
      />
      <Stat name={t("glossary.penalties")} value={penalties} row="2" col="1" />
      <Stat name={t("glossary.fouls")} value={fouls} row="2" col="2" />
    </Box>
  );
};
