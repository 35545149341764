import { Hidden } from "@material-ui/core";
import React, { FC } from "react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import { Grid } from "../core/Grid";

export const SwiperWrapper: FC = ({ children }) => {
  const [HOME, AWAY] = React.Children.toArray(children);
  return (
    <>
      <Hidden mdDown>
        <Grid container>
          <Grid item md={6}>{HOME}</Grid>
          <Grid item md={6}>{AWAY}</Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Swiper>
          <SwiperSlide>{HOME}</SwiperSlide>
          <SwiperSlide>{AWAY}</SwiperSlide>
        </Swiper>
      </Hidden>
    </>
  );
};
