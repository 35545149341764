import { ToggleButton } from "@material-ui/lab";
import React, { FC } from "react";
import styled from "styled-components";

interface FilterProps {
  name?: string;
  value: string | null;
  disabled?: boolean;
}
const CustomToggleButton = styled(ToggleButton)`
  white-space: nowrap;
  color: inherit;
  border-color: inherit;
`;
export const FilterButton: FC<FilterProps> = ({
  name,
  value,
  children,
  ...rest
}) => {
  return (
    <CustomToggleButton value={value} {...rest}>
      {name || children}
    </CustomToggleButton>
  );
};
