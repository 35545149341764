import { Grid as MuiGrid } from "@material-ui/core";
import {
  borders,
  BordersProps,
  palette,
  PaletteProps,
  positions,
  PositionsProps, sizing,
  SizingProps, spacing,
  SpacingProps,
  typography,
  TypographyProps
} from "@material-ui/system";
import styled from "styled-components";
export const Grid = styled(MuiGrid)<
  BordersProps &
    SpacingProps &
    TypographyProps &
    PositionsProps &
    PaletteProps &
    SizingProps & { noGutter?: boolean }
>`
  width: ${(props) => props.container && "100%"};
  margin: 0;
  ${spacing}
  ${typography}
  ${positions}
  ${palette}
  ${borders}
  ${sizing}
  .MuiGrid-item:first-child {
    padding-left: ${(props) => props.noGutter && "0px"};
  }
  .MuiGrid-item:last-child {
    padding-right: ${(props) => props.noGutter && "0px"};
  }
`;
