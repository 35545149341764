import { Box, Link, Typography } from "@material-ui/core";
import WhistleIcon from "@material-ui/icons/Sports";
import { selectFixtureTrn } from "features/fixture/selectFixtureTrn";
import { selectMatchOfficial } from "features/fixture/selectors";
import { fetchReferee } from "features/referees/refereesSlice";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Props } from "./FixtureInfo";
import { RefereeStatsDialog } from "./RefereeStatsDialog";

export const Referee: FC<Props> = ({ fixtureId }) => {
  const [open, setOpen] = React.useState(false);

  const official = useSelector(selectMatchOfficial(fixtureId));

  const trn = useSelector(selectFixtureTrn(fixtureId));

  const dispatch = useDispatch();
  const openHandler = () => {
    setOpen(true);
    dispatch(fetchReferee({ refereeId: official?.uId || "", calId: trn?.id }));
  };

  const closeHandler = () => {
    setOpen(false);
  };
  return (
    <>
      {official && (
        <>
          <Link onClick={openHandler}>
            <Box display="flex" alignItems="center" position="relative">
              <WhistleIcon fontSize="inherit" />
              <Box px={1}>
                <Typography variant="caption" noWrap>
                  {`${official.officialName.first.charAt(0)}.${
                    official.officialName.last
                  }`}
                </Typography>
              </Box>
            </Box>
          </Link>
          <RefereeStatsDialog
            id={official.uId}
            open={open}
            closeHandler={closeHandler}
            setOpen={setOpen}
          />
        </>
      )}
    </>
  );
};
