import { Box, Link as MuiLink, styled, Typography } from "@material-ui/core";
import LangIcon from "@material-ui/icons/LanguageRounded";
import React from "react";
import { useTranslation } from "react-i18next";
const Link = styled(MuiLink)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
}));
const LANGS: Record<string, string> = {
  "en-US": "English",
  fr: "Français",
};
export const LangSwitcher = () => {
  const [, i18n] = useTranslation();

  return (
    <Box py={1} display="flex" alignItems="center" justifyContent="center">
      <Box mx={0.5} clone>
        <LangIcon fontSize="small" />
      </Box>
      <Typography variant="subtitle2">{LANGS[i18n.language]} | </Typography>
      {Object.entries(LANGS)
        .filter(([code]) => code !== i18n.language)
        .map(([code, lang]) => (
          <Link
            key={code}
            color="textSecondary"
            onClick={() => i18n.changeLanguage(code)}
          >
            {lang}
          </Link>
        ))}
    </Box>
  );
};
