import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ContestantState {
  id: string;
  name: string;
}

interface PlayerState {
  id: string;
  name: string;
  personName: {
    first: string;
    last: string;
    known: string;
  };
  firstName: string;
  knownName: string;
  lastName: string;
  jerseyNum: string;
  shirtNumber: number;
}

interface PlayersState {
  players: {
    byId: Record<string, PlayerState>;
  };
  contestants: {
    byId: Record<string, ContestantState>;
  };
}

type SetPlayersPayload = Record<string, any>;
type SetContestantsPayload = Record<string, any>;

const initialState: PlayersState = {
  players: { byId: {} },
  contestants: { byId: {} },
};

const metaDataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setPlayers(state, action: PayloadAction<SetPlayersPayload>) {
      state.players.byId = action.payload;
    },
    setContestants(state, action: PayloadAction<SetContestantsPayload>) {
      state.contestants.byId = action.payload;
    },
  },
});

export const { setPlayers , setContestants} = metaDataSlice.actions;
export default metaDataSlice.reducer;
