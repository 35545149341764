import { blue } from "@material-ui/core/colors";
import { motion } from "framer-motion";
import React from "react";

export const Polygon = () => (
  <>
    <motion.svg
      viewBox="0 0 536 436"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={blue[200]}
    >
      <motion.path
        style={{ strokeWidth: 6 }}
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.6,
        }}
        d="M500.189 405H36"
        stroke="inherit"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round" />

      <motion.path
        style={{ strokeWidth: 6 }}
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          duration: 0.5,
        }}
        d="M35 405L267.095 3"
        stroke="inherit"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <motion.path
        style={{ strokeWidth: 6 }}
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.3,
        }}
        d="M268 3L500.095 405"
        stroke="inherit"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M267.5 50L458.459 380.75H76.5414L267.5 50Z"
        stroke={blue[500]}
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </motion.svg>
  </>
);
