import { reAuthorize } from "app/API";
import { RootState } from "app/rootReducer";
import JwtDecode from "jwt-decode";
import { combineEpics, Epic, ofType } from "redux-observable";
import { EMPTY, iif, of } from "rxjs";
import {
  catchError, map,
  mergeMap,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { LocalStorageKeys } from "shared/constants";
import { removeItem, saveItem } from "shared/local-storage";
import { isUnauthorized } from "utils/isUnauthorized";
import {
  accessTokenExpired,
  authorize,
  successLogin,
  successLogout
} from "./userSlice";

const authorizeEpic: Epic<any, any, RootState> = (actions, state) =>
  actions.pipe(
    ofType(authorize.type),
    withLatestFrom(state),
    mergeMap(reAuthorize),
    tap(({ data }) =>
      saveItem(LocalStorageKeys.USER, JwtDecode(data.accessToken))
    ),
    map(({ data }) => successLogin(data)),
    catchError((e) =>
      iif(
        () => isUnauthorized(e),
        of(successLogout()).pipe(tap(() => removeItem(LocalStorageKeys.USER))),
        EMPTY
      )
    )
  );

const expiredTokenEpic: Epic<any, any, RootState> = (actions) =>
  actions.pipe(
    ofType(accessTokenExpired.type),
    map(() => authorize())
  );

export const authEpic = combineEpics(authorizeEpic, expiredTokenEpic);
