import { Box } from "@material-ui/core";
import {
  selectFixtureRemoteStatus,
  selectMatchKit,
  selectSubPlayers,
} from "features/fixture/selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { SubPlayer } from "./SubPlayer";

interface Props {
  contId: string;
}

export const Substitutes: FC<Props> = ({ contId }) => {
  const playerIds = useSelector(selectSubPlayers(contId));
  const kit = useSelector(selectMatchKit(contId));
  const status = useSelector(selectFixtureRemoteStatus);
  return (
    <Box display="flex" component="ul">
      {playerIds.map((id, index) => (
        <Box key={index} component="li">
          <SubPlayer key={index} id={id} status={status} kitColour={kit} />
        </Box>
      ))}
    </Box>
  );
};
