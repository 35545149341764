import { useContext } from "react";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { StatsViewContext } from "./StatsViewContext";

export const getPlayerStatType = (statType: Nullable<StatType>) => {
  switch (statType) {
    case StatType.total_yel_card:
      return StatType.total_yel_card;

    case StatType.total_red_card:
      return StatType.total_red_card;

    case StatType.successful_open_play_pass:
      return StatType.accurate_pass;

    default:
      return statType;
  }
};

export const usePlayerStatType = () => {
  const { index } = useContext(StatsViewContext);
  return getPlayerStatType(index);
};
