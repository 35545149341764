export const getGridArea = (formation: string): string[] => {
  const form = [1, ...formation.split("-").map((x) => parseInt(x))];
  const width = Math.max(...form) > 4 ? Math.max(...form) : 4;
  return form
    .reduce(
      (pre, cur) => ({
        grid: [
          ...pre.grid,
          [...Array(cur).keys()].map((i) => pre.count + i + 1),
        ],
        count: pre.count + cur,
      }),
      {
        grid: [] as Array<Array<number>>,
        count: 0,
      }
    )
    .grid.map((row, index) => [
      ...Array(width - form[index]).fill(0),
      ...row.reduce((pre, cur) => [...pre, cur, cur], [] as Array<number>),
      ...Array(width - form[index]).fill(0),
    ])
    .map((row) => row.map((value) => (value ? "p" + value : ".")).join(" "));
};

export const rotateGrid = (grid: string[] = [""]) => {
  const matrix = grid.map((row) => row.split(" "));
  const flipped = matrix[0].map((_, colIndex) =>
    matrix.map((row) => row[colIndex])
  );
  return flipped
    .reverse()
    .map((row) =>
      row.reduce((result, current) => result.concat(" ", current).trimEnd())
    );
};
