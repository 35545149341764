import { Hidden } from "@material-ui/core";
import React, { FC } from "react";
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import SwiperType from 'swiper/types/swiper-class';
import { Grid } from "../core/Grid";


export const ControlledSwiperWrapper: FC = ({ children }) => {
  const [controlledSwiper, setControlledSwiper] = React.useState<SwiperType>();
  const [HOME, HOME_FOOTER, AWAY, AWAY_FOOTER] = React.Children.toArray(children);
  return (
    <>
      <Hidden mdDown>
        <Grid container>
          <Grid item md={6}>
            <>
              {HOME}
              {HOME_FOOTER}
            </>
          </Grid>
          <Grid item md={6}>
            <>
              {AWAY}
              {AWAY_FOOTER}
            </>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Swiper modules={[Controller]} controller={{ control: controlledSwiper }}>
          <SwiperSlide>{HOME}</SwiperSlide>
          <SwiperSlide>{AWAY}</SwiperSlide>
        </Swiper>
        <Swiper modules={[Controller]} onSwiper={setControlledSwiper} allowTouchMove={false}>
          <SwiperSlide >{HOME_FOOTER}</SwiperSlide>
          <SwiperSlide >{AWAY_FOOTER}</SwiperSlide>
        </Swiper>
      </Hidden>
    </>
  );
};
