import { Box, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Goal, PeriodEvent } from "features/fixture/interfaces";
import { selectPlayerName } from "features/fixture/selectors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GoalIcon } from "../EventIcons";
import { PlayerName, PlayerNameHelper, TimeMin } from "./styled";
interface Props {
  event: PeriodEvent<Goal>;
}
interface AssistNameProps {
  playerRef: string;
}

const AssistName: FC<AssistNameProps> = ({ playerRef }) => {
  const name = useSelector(selectPlayerName(playerRef));
  return <PlayerNameHelper name={name} />;
};
export const GoalEvent: FC<Props> = ({ event }) => {
  const { time, playerRef, type, assist, side } = event;
  const name = useSelector(selectPlayerName(playerRef));
  const [t] = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection={side === "Home" ? "row" : "row-reverse"}
      alignItems="center"
    >
      <TimeMin time={time} />
      <GoalIcon fill={type === "Own" ? red[700] : "auto"} />
      <PlayerName name={name} />
      {type === "Own" && (
        <Typography variant="caption" color="textSecondary">
          {`(${t("glossary.own_goal_short")})`}
        </Typography>
      )}
      {type === "Penalty" && (
        <Typography variant="caption" color="textSecondary">
          {`(${t("glossary.penalty_short")})`}
        </Typography>
      )}
      {assist && <AssistName playerRef={assist.playerRef} />}
    </Box>
  );
};
