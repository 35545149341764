import { fetchSeasonAvg } from "app/API";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import { getSeasonAvg, setSeasonAvg } from "./fixtureSlice";
import { httpErrorHandler } from "./httpErrorHandler";

export const fetchSeasonAvgEpic: Epic = (actions$) =>
  actions$.pipe(
    ofActionCreator(getSeasonAvg),
    mergeMap(({ payload }) =>
      fetchSeasonAvg(payload).pipe(
        map(({ data }) =>
          setSeasonAvg({
            key: `${payload.lowerMinute}-${payload.upperMinute}`,
            data,
          })
        ),
        catchError((e) => httpErrorHandler(e, getSeasonAvg(payload)))
      )
    )
  );
