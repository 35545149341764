import { Box, Typography } from "@material-ui/core";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { MobileAvgStatView } from "./MobileAvgStatView";
import { StatDescTooltip } from "./StatDescTooltip";
import { StatCard, StatValue } from "./Styled";
import { TextSkeleton } from "./TextSkeleton";

interface Props {
  value: [number, number];
  matchAvg: [string, string];
  periodAvg: [string, string];
  quarterAvg: [string, string];
  name: string;
  desc: string;
  period: string;
  quarter: string;
  status: RemoteStatus;
  isSelected: boolean;
  clickHandler: () => void;
}
export const MobileStatView: FC<Props> = ({
  value: [home, away],
  matchAvg: [homeM, awayM],
  periodAvg: [homeP, awayP],
  quarterAvg: [homeQ, awayQ],
  name,
  desc,
  status,
  isSelected,
  clickHandler,
  period,
  quarter,
}) => (
  <StatCard
    variant={isSelected ? "outlined" : "elevation"}
    selected={isSelected}
  >
    <Box p={1} onClick={clickHandler}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={1}
      >
        <StatValue variant="h5" isMin={home < away}>
          <TextSkeleton status={status} text={home || "0"} />
        </StatValue>
        <Box px={4} clone>
          <Typography
            variant="button"
            align="center"
            color="textSecondary"
            component="h5"
            noWrap
          >
            <Box display="flex" alignItems="center">
              <StatDescTooltip title={desc} />
              <Box mx={0.5} component="span">
                {name}
              </Box>
            </Box>
          </Typography>
        </Box>
        <StatValue variant="h5" isMin={away < home}>
          <TextSkeleton status={status} text={away || "0"} />
        </StatValue>
      </Box>
      <Box px={4}>
        <MobileAvgStatView
          home={homeM}
          away={awayM}
          status={status}
          title="FT"
        />
        <MobileAvgStatView
          home={homeP}
          away={awayP}
          status={status}
          title={period}
        />
        <MobileAvgStatView
          home={homeQ}
          away={awayQ}
          status={status}
          title={quarter}
        />
      </Box>
    </Box>
  </StatCard>
);
