import { STAT_ITEMS } from "app/components/Stats/STAT_ITEMS";
import { StatsViewContext } from "features/fixture/StatsViewContext";
import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { MobileStatsList } from "./MobileStatsList";
import { MobileStatView } from "./MobileStatView";
import { useContestantStats } from "./useContestantStats";
import { useRecentStats } from "./useRecentStats";
import { useTimeBucketTitle } from "./useTimeBucketTitle";

interface Props {
  homeId: string;
  awayId: string;
  fixtureId: string;
}
export const MobileStatsListContainer: FC<Props> = ({
  homeId,
  awayId,
  fixtureId,
}) => {
  const { index, setIndex } = useContext(StatsViewContext);
  const homeStats = useContestantStats(homeId);
  const awayStats = useContestantStats(awayId);
  const status = useSelector((state) => state.fixtures.pbStatus);

  const { period, quarter } = useTimeBucketTitle(fixtureId);
  const {
    matchAvg: homeMAvg,
    periodAvg: homePAvg,
    quarterAvg: homeQAvg,
  } = useRecentStats(fixtureId, homeId);
  const {
    matchAvg: awayMAvg,
    periodAvg: awayPAvg,
    quarterAvg: awayQAvg,
  } = useRecentStats(fixtureId, awayId);
  return (
    <MobileStatsList
      index={index}
      items={STAT_ITEMS}
      viewHoc={(name, desc, statType) => (
        <MobileStatView
          status={status}
          name={name}
          desc={desc}
          value={[
            homeStats[statType]?.value || 0,
            awayStats[statType]?.value || 0,
          ]}
          isSelected={index === statType}
          clickHandler={() =>
            index === statType ? setIndex(null) : setIndex(statType)
          }
          matchAvg={[homeMAvg[statType] || "0", awayMAvg[statType] || "0"]}
          quarterAvg={[homeQAvg[statType] || "0", awayQAvg[statType] || "0"]}
          periodAvg={[homePAvg[statType] || "0", awayPAvg[statType] || "0"]}
          period={period}
          quarter={quarter}
        />
      )}
    />
  );
};
