import { PeriodEvent, VarPeriodEvent } from "features/fixture/interfaces";
import React, { FC } from "react";
import {
  Booking,
  Goal,
  Substitution,
} from "../../../../features/fixture/interfaces";
import { CardEvent } from "./CardEvent";
import { GoalEvent } from "./GoalEvent";
import { SubstituteEvent } from "./SubstituteEvent";
import { VarEvent } from "./VarEvent";

type Props = {
  event:
    | PeriodEvent<Goal>
    | PeriodEvent<Booking>
    | PeriodEvent<Substitution>
    | VarPeriodEvent;
};

export const BasicFixtureEvent: FC<Props> = ({ event }) => (
  <>
    {event.variant === "goal" && <GoalEvent event={event} />}
    {event.variant === "booking" && <CardEvent event={event} />}
    {event.variant === "substitution" && <SubstituteEvent event={event} />}
    {event.variant === "varEvent" && <VarEvent event={event} />}
  </>
);
