import { Box, Typography } from "@material-ui/core";
import { Grid } from "app/components/core/Grid";
import { Period } from "features/fixture/dto/Period";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
interface Props {
  period: Period;
  score: string;
}

export const PeriodEvent: FC<Props> = ({ period, score }) => {
  const [t] = useTranslation();
  return (
    <Box
      bgcolor="grey.100"
      border={1}
      borderLeft={0}
      borderRight={0}
      borderTop={0}
      borderColor="grey.200"
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" align="right">
            {period === Period.FIRST_HALF && t("glossary.first_half")}
            {period === Period.SECOND_HALF && t("glossary.second_half")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            {score}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
