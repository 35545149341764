import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
interface Props {
  id: string;
}
export const TrnTeamName: FC<Props> = ({ id }) => {
  const name = useSelector((state) => state.metadata.contestants.byId[id].name);
  return <Typography variant="h6">{name}</Typography>;
};
