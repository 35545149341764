import { Typography } from "@material-ui/core";
import { selectFixtureWeek } from "features/fixture/selectors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
interface Props {
  id: string;
}
export const FixtureWeek: FC<Props> = ({ id }) => {
  const week = useSelector(selectFixtureWeek(id));
  const [t] = useTranslation();
  return (
    <Typography variant="caption" color="textSecondary">
      {week && t("glossary.match_day", { day: week })}
    </Typography>
  );
};
