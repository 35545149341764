import { STAT_ITEMS } from "app/components/Stats/STAT_ITEMS";
import {
  selectFixtureInitStatus,
  selectPbStatus
} from "features/fixture/selectors";
import { StatsViewContext } from "features/fixture/StatsViewContext";
import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { MatchStatsList } from "./MatchStatsList";
import { useContestantStats } from "./useContestantStats";
import { useRecentStats } from "./useRecentStats";
import { useTimeBucketTitle } from "./useTimeBucketTitle";

interface Props {
  fixtureId: string;
  contestantId: string;
  opponentId: string;
  side: "Home" | "Away";
}
export const StatsListContainer: FC<Props> = ({
  fixtureId,
  contestantId,
  opponentId,
  side,
}) => {
  const { index, setIndex } = useContext(StatsViewContext);
  const stats = useContestantStats(contestantId);
  const oppStats = useContestantStats(opponentId);
  const status = useSelector(selectPbStatus);
  const initStatus = useSelector(selectFixtureInitStatus);
  const recentStats = useRecentStats(fixtureId, contestantId);
  const { period, quarter } = useTimeBucketTitle(fixtureId);
  return (
    <MatchStatsList
      index={index}
      setIndex={setIndex}
      stats={stats}
      oppStats={oppStats}
      side={side}
      status={status === "unknown" ? initStatus : status}
      items={STAT_ITEMS}
      period={period}
      quarter={quarter}
      {...recentStats}
    />
  );
};
