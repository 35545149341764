import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { FC } from "react";
import { PeriodStatus } from "../Calendar/PeriodStatus";

interface Props {
  firstHalfStart: boolean;
  homeScore?: number;
  awayScore?: number;
  fhHomeScore?: number;
  fhAwayScore?: number;
  fixtureId: string;
  variant?: "default" | "minimal";
  isLoading: boolean;
}
const Loading = () => <Skeleton variant="circle" width={30} height={30} />;

export const Score: FC<Props> = ({
  homeScore,
  fhHomeScore,
  awayScore,
  fhAwayScore,
  firstHalfStart,
  variant = "default",
  fixtureId,
  isLoading,
}) => (
  <Box
    display="flex"
    position="relative"
    justifyContent="space-around"
    alignItems="center"
  >
    <Typography variant="h4">
      {isLoading ? <Loading /> : firstHalfStart && homeScore}
    </Typography>
    {variant === "minimal" ? (
      <PeriodStatus fixtureId={fixtureId} variant={variant} />
    ) : (
      <Typography variant="h4">{"-"}</Typography>
    )}
    <Typography variant="h4">
      {isLoading ? <Loading /> : firstHalfStart && awayScore}
    </Typography>
    {variant === "default" && (
      <Box position="absolute" right={0} left={0} bottom={"-0.5rem"}>
        <Typography variant="caption" component="span" color="textSecondary">
          {firstHalfStart && `(${fhHomeScore} - ${fhAwayScore})`}
        </Typography>
      </Box>
    )}
  </Box>
);
