import { Box, Chip } from "@material-ui/core";
import React, { FC } from "react";

export const FilterChip: FC<{
  active: boolean;
  label: string;
  onClick: () => void;
}> = ({ active, label, onClick }) => {
  return (
    <Box mr={0.5}>
      <Chip
        label={label}
        color={active ? "secondary" : "default"}
        variant={active ? "default" : "outlined"}
        onClick={onClick}
      />
    </Box>
  );
};
