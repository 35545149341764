import {
  selectFixPeriodBucket,
  selectFixQuarterBucket,
} from "features/fixture/selectors";
import { selectRecentStat } from "features/fixture/selectRecentStat";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
export const useRecentStats = (fixtureId: string, contestantId: string) => {
  const [sp, ep] = useSelector(selectFixPeriodBucket(fixtureId));
  const [sq, eq] = useSelector(selectFixQuarterBucket(fixtureId));
  const seasonStats = useSelector(selectRecentStat(contestantId));
  const aggregateAvg = (interval: string) => {
    const stats = seasonStats[interval] || {};
    const count = stats[StatType.matches_played];
    return Object.fromEntries(
      Object.entries(stats)
        .filter(
          ([statType, v]) =>
            statType !== StatType.matches_played &&
            statType !== StatType.possession_percentage
        )
        .map(([k, v]) => [k, (+v / +count).toFixed(1)])
        .concat([
          [StatType.matches_played, stats[StatType.matches_played]],
          [
            StatType.possession_percentage,
            stats[StatType.possession_percentage],
          ],
        ])
    );
  };
  return {
    matchAvg: aggregateAvg("PM-FT"),
    periodAvg: aggregateAvg(`${sp}-${ep}`),
    quarterAvg: aggregateAvg(`${sq}-${eq}`),
  };
};
