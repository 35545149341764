import { PeriodFilterContext } from "app/components/Stats/PeriodFilterContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { filterValue } from "utils/filterValue";
import { PlayerPosition } from "./interfaces";
export const usePlayerStat = (id: string, type: StatType) => {
  const { bounds } = useContext(PeriodFilterContext);
  const stat = useSelector((state) => {
    const { stats, bucketStats, position } = state.fixtures.players[id];
    if (type === null) return 0;

    if (
      position !== PlayerPosition.GOALKEEPER &&
      type === StatType.goals_conceded
    )
      return 0;

    const [low, up] = bounds;

    if (low && up && bucketStats && bucketStats[low.type]) {
      const lowStats = bucketStats[low.type];
      const upStats = bucketStats[up.type] || stats;
      const periodStats = filterValue(
        [lowStats, upStats],
        [low.minute, up.minute]
      );
      return periodStats[type]?.value || 0;
    }
    return stats[type]?.value || 0;
  });
  return stat;
};
