import { fetchCalendars } from "app/API";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import { httpErrorHandler } from "./httpErrorHandler";
import {
  calendarsFetched,
  getCalendars,
} from "features/calendar/calendarSlice";
import ofActionCreator from "utils/ofActionCreator";

export const fetchCalendarsEpic: Epic = (action$) =>
  action$.pipe(
    ofActionCreator(getCalendars),
    mergeMap(({ payload }) =>
      fetchCalendars(payload).pipe(
        map(({ data }) => calendarsFetched(data)),
        catchError((e) => httpErrorHandler(e, calendarsFetched))
      )
    )
  );
