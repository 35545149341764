export enum Period {
  PRE_MATCH = "PreMatch",
  FIRST_HALF = "FirstHalf",
  HALF_TIME = "HalfTime",
  SECOND_HALF = "SecondHalf",
  EXTRA_FIRST_HALF = "ExtraFirstHalf",
  EXTRA_SECOND_HALF = "ExtraSecondHalf",
  EXTRA_HALF_TIME = "ExtraHalfTime",
  SHOOT_OUT = "ShootOut",
  FULL_TIME = "FullTime",
  FULL_TIME_90 = "FullTime90",
  FULL_TIME_PENS = "FullTimePens",
  ABANDONED = "Abandoned",
}
