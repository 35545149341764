import { Box, Collapse } from "@material-ui/core";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { StatDescTooltip } from "./StatDescTooltip";
import { StatCard, StatName, StatValue } from "./Styled";
import { TextSkeleton } from "./TextSkeleton";

type SubStat = Omit<Props, "subStats" | "status" | "details" | "reverse">;

interface Props {
  reverse: boolean;
  status: RemoteStatus;
  // details?: any;
  detailsComponent?: React.ReactNode;
  statType: Nullable<StatType>;
  statName: string;
  statDesc: string;
  stat: number;
  isMin?: boolean;
  subStats?: SubStat[];
  isSelected: boolean;
  clickHandler: () => void;
}

const Container: FC<Omit<Props, "subStats">> = ({
  reverse,
  status,
  detailsComponent: Details,
  statName,
  stat = 0,
  isMin = false,
  isSelected,
  statDesc,
  clickHandler,
}) => (
  <StatCard
    variant={isSelected ? "outlined" : "elevation"}
    selected={isSelected}
    onClick={clickHandler}
  >
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={2}
      py={0.5}
      flexDirection={reverse ? "row-reverse" : "row"}
    >
      <StatName variant="button">
        <Box
          display="flex"
          alignItems="center"
          flexDirection={reverse ? "row-reverse" : "row"}
        >
          <StatDescTooltip title={statDesc} />
          <Box mx={0.5} component="span">
            {statName}
          </Box>
        </Box>
      </StatName>
      <StatValue variant="h6" isMin={isMin}>
        <TextSkeleton status={status} text={stat} />
      </StatValue>
    </Box>
    {Details}
  </StatCard>
);

const SubStatsView: FC<
  Pick<Props, "subStats" | "status" | "reverse" | "detailsComponent">
> = ({ subStats = [], status, reverse }) => {
  return (
    <Box pl={reverse ? 2 : 0} pr={reverse ? 0 : 2} pt={1} pb={2}>
      {subStats.map((props) => (
        <Container
          status={status}
          reverse={reverse}
          detailsComponent={props.detailsComponent}
          {...props}
        />
      ))}
    </Box>
  );
};
export const StatView = React.forwardRef<HTMLDivElement, Props>(
  (
    { status, subStats, reverse, detailsComponent, isSelected, ...rest },
    ref
  ) => {
    const isSubSelected = React.useMemo(
      () => Boolean(subStats?.filter(({ isSelected }) => isSelected).length),
      [subStats]
    );
    return (
      <div ref={ref}>
        <Container
          isSelected={isSelected || isSubSelected}
          status={status}
          reverse={reverse}
          detailsComponent={detailsComponent}
          {...rest}
        />
        {subStats && (
          <Collapse in={isSelected || isSubSelected}>
            <SubStatsView
              subStats={subStats}
              status={status}
              reverse={reverse}
              {...rest}
            />
          </Collapse>
        )}
      </div>
    );
  }
);
