import { Box, Card } from "@material-ui/core";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { Period } from "features/fixture/dto/Period";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Grid } from "../core/Grid";
import { LoadingFeedback } from "../core/LoadingFeedback";
import { CalendarSchedule } from "./CalendarsSchedule";
import { CalendarSummary } from "./CalendarSummary";
import { FixturePanel } from "./FixtureAccordion";
import { NoFixtures } from "./NoFixtures";
interface Props {
  id: string;
}
const selectFixtureIds = (id: string) =>
  createSelector(
    (state: RootState) => state.calendar.byId[id].fixtureIds,
    (state: RootState) => state.fixtures.byId,
    (state: RootState) => state.calendar.live,
    (ids, fixtures, live) =>
      !live
        ? ids
        : ids.filter(
            (id) =>
              fixtures[id].type === "Latest" &&
              fixtures[id].matchInfo.period !== Period.PRE_MATCH
          )
  );
export const Calendar: FC<Props> = ({ id }) => {
  const status = useSelector(
    (state) => state.socketStatus.updateCalendarStatus
  );
  const fixtureIds = useSelector(selectFixtureIds(id));
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Box display="flex" alignItems="center" p={1}>
              <Box flexGrow={1}>
                <CalendarSchedule />
              </Box>
            </Box>
            <CalendarSummary id={id} />
            {status === "loading" && <LoadingFeedback />}
            {status === "success" && fixtureIds.length === 0 && <NoFixtures />}
            {status === "success" &&
              fixtureIds.map((id, i) => (
                <FixturePanel fixtureId={id} key={i} />
              ))}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
