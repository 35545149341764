import { Box, styled, Typography, useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Skeleton as MuiSkeleton } from "@material-ui/lab";
import {
  selectContCode,
  selectContName,
  selectTeamStanding,
} from "features/fixture/selectContestant";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RankView } from "../standings/RankView";
interface Props {
  contestantId: string;
  minimal?: boolean;
  position: "home" | "away";
}
const Skeleton = styled(MuiSkeleton)({ margin: "auto" });
const TeamTitle = styled(Typography)({});
const Subtitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  whiteSpace: "nowrap",
  textAlign: "center",
  left: 0,
  right: 0,
  bottom: "-1rem",
}));
export const ContestantInfo: FC<Props> = ({
  contestantId,
  minimal = false,
  position,
}) => {
  const standing = useSelector(selectTeamStanding(contestantId));
  const name = useSelector(selectContName(contestantId));
  const contCode = useSelector(selectContCode(contestantId));
  const code = contCode
    ? contCode
    : name
    ? name.substring(0, 3).toUpperCase()
    : "";

  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={position === "home" ? "row-reverse" : "row"}
    >
      <Box position="relative" flexGrow={1}>
        <TeamTitle variant="h6" noWrap>
          {upMd ? name : code}
          {(!name || !code) && <Skeleton variant="text" width={60} />}
        </TeamTitle>
        {!upMd && !minimal && (
          <Subtitle
            variant="caption"
            color="textSecondary"
          >{`(${name})`}</Subtitle>
        )}
      </Box>
      <RankView
        rank={standing?.position}
        result={standing?.result}
        reverse={position === "home"}
      />
    </Box>
  );
};
