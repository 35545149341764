import { StatType } from "shared/enums";
import { StatItem } from "../../interface/StatItem";
export const STAT_ITEMS: StatItem[] = [
  {
    name: "Matches Played",
    statType: StatType.matches_played,
  },
  {
    name: "Minutes played",
    statType: StatType.mins_played,
  },
  {
    name: "Ratings",
    statType: StatType.avg_ratings,
  },
  {
    name: "Possession %",
    statType: StatType.possession_percentage,
  },
  {
    name: "Goals",
    statType: StatType.goals,
  },
  { name: "Goals Conceded", statType: StatType.goals_conceded },
  { name: "Goal Assist", statType: StatType.goal_assist },
  {
    name: "Shots on target",
    statType: StatType.ontarget_scoring_att,
  },
  { name: "Shots  Off Target", statType: StatType.shot_off_target },
  { name: "Key Passes", statType: StatType.fwd_pass },
  { name: "Goal Attempts", statType: StatType.goal_attempts },
  { name: "Dangerous Attacks", statType: StatType.dangerous_play },
  { name: "Attacks", statType: StatType.attacks },
  { name: "Corners Kicks", statType: StatType.corner_taken },
  { name: "Total Throws", statType: StatType.foul_throw_in },
  {
    name: "Fouls",
    statType: StatType.fk_foul_lost,
  },
  { name: "Total Tackle", statType: StatType.total_tackle },
  {
    name: "Yellow Cards",
    statType: StatType.total_yel_card,
  },
  {
    name: "Red Cards",
    statType: StatType.total_red_card,
  },
  { name: "Offsides", statType: StatType.total_offside },
  { name: "Woodwork Hits", statType: StatType.hit_woodwork },
  { name: "Saves", statType: StatType.saves },
  { name: "Goal Kicks", statType: StatType.goal_kicks },
  { name: "Counter Attacks", statType: StatType.counter_attacks },
  { name: "Total Pass", statType: StatType.total_pass },
  { name: "Successful passes", statType: StatType.successful_open_play_pass },
  { name: "Successful dribbles", statType: StatType.successful_dribbles },
  { name: "Successful interceptions", statType: StatType.interception },
  { name: "Through ball", statType: StatType.total_through_ball },
];
