import { RemoteStatus } from "features/fixture/interfaces";
import {
  selectPlayerName,
  selectPlayerShirtNum,
} from "features/fixture/selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { PlayerStat } from "./PlayerStat";
import { TopPlayerCont } from "./PlayerStyles";

interface Props {
  id: string;
  stat: number;
  avg: string;
  status: RemoteStatus;
  kitColour: string;
  reverse?: boolean;
}

export const TopPlayer: FC<Props> = ({
  id,
  reverse,
  kitColour,
  status,
  stat,
  avg,
}) => {
  const name = useSelector(selectPlayerName(id));
  const shirtNum = useSelector(selectPlayerShirtNum(id));
  return (
    <TopPlayerCont reverse={reverse}>
      <PlayerStat
        status={status}
        stat={stat}
        kitColour={kitColour}
        name={name}
        jerseyNum={shirtNum}
        ftAvg={avg}
      />
    </TopPlayerCont>
  );
};
