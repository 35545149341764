import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useInterval } from "./useInterval";

const HALF_PERIOD_SECS = 45 * 60;

const durationTillNow = (from: string) => {
  if (!from) return 0;

  return moment
    .duration(moment().diff(moment.utc(from, "YYYY-MM-DDThh:mm:ss")))
    .asSeconds();
};

export const useFixtureClock = (fixtureId: string) => {
  const {
    first_half_start,
    first_half_stop,
    second_half_start,
    second_half_stop,
  } = useSelector((state) => state.fixtures.byId[fixtureId].periods);

  let [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (first_half_start && !first_half_stop) {
      const init = durationTillNow(first_half_start);
      setSeconds(init);
    }
  }, [first_half_start, first_half_stop]);

  useEffect(() => {
    if (first_half_stop) setSeconds(HALF_PERIOD_SECS);
  }, [first_half_stop]);

  useEffect(() => {
    if (second_half_start && !second_half_stop) {
      const init = durationTillNow(second_half_start) + HALF_PERIOD_SECS;
      setSeconds(init);
    }
  }, [second_half_start, second_half_stop]);

  useEffect(() => {
    if (second_half_stop) {
      const init = 2 * HALF_PERIOD_SECS;
      setSeconds(init);
    }
  }, [second_half_stop]);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (second_half_start && second_half_stop) setIsActive(false);
  }, [second_half_start, second_half_stop]);

  useEffect(() => {
    if (first_half_start && first_half_stop) setIsActive(false);
  }, [first_half_start, first_half_stop]);

  useEffect(() => {
    if (first_half_start && !first_half_stop) setIsActive(true);
  }, [first_half_start, first_half_stop]);

  useEffect(() => {
    if (second_half_start && !second_half_stop) setIsActive(true);
  }, [second_half_start, second_half_stop]);

  useInterval(
    () => setSeconds((seconds) => seconds + 1),
    isActive ? 1_000 : null
  );

  return [Math.floor(seconds / 60), Math.floor(seconds % 60)];
};
