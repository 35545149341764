import { Box } from "@material-ui/core";
import { RemoteStatus, StatDto } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { StatItem } from "../../interface/StatItem";
import { TeamStatDetails } from "./SeasonStatDetails";
import { StatView } from "./StatView";
import { List } from "./Styled";

interface Props {
  contId: string;
  side?: "Home" | "Away";
  index: Nullable<StatType>;
  setIndex: (i: Nullable<StatType>) => void;
  stats?: { [key: string]: StatDto };
  oppStats?: { [key: string]: StatDto };
  status: RemoteStatus;
  items: StatItem[];
  detailsComponent?: React.ReactNode;
}

export const SeasonStatsList: FC<Props> = ({
  stats = {},
  oppStats = {},
  items,
  index,
  setIndex,
  side,
  contId,
  ...rest
}) => {
  const [tName] = useTranslation("stats");
  const [tDesc] = useTranslation("statsDesc");
  const view = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    view.current?.scrollIntoView({ block: "center" });
  }, []);
  return (
    <Box p={2} position="relative">
      <Box />
      <List>
        {items.map(({ statType, subStats }, i) => (
          <StatView
            key={statType}
            ref={index === statType ? view : undefined}
            statType={statType}
            statName={tName(statType)}
            statDesc={tDesc(statType)}
            stat={stats[statType]?.value}
            isMin={oppStats[statType]?.value > stats[statType]?.value}
            subStats={subStats?.map(({ statType: subType }) => ({
              statName: tName(subType),
              statDesc: tDesc(subType),
              stat: stats[subType]?.value,
              isMin: oppStats[subType]?.value > stats[subType]?.value,
              statType: subType,
              isSelected: index === subType,
              clickHandler: () => {
                subType === index ? setIndex(null) : setIndex(subType);
              },
              detailsComponent: (
                <TeamStatDetails contId={contId} statType={subType} />
              ),
            }))}
            clickHandler={() =>
              statType === index ? setIndex(null) : setIndex(statType)
            }
            isSelected={statType === index}
            reverse={side === "Away"}
            detailsComponent={
              <TeamStatDetails contId={contId} statType={statType} />
            }
            {...rest}
          />
        ))}
      </List>
    </Box>
  );
};
