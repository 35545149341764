import { BucketType } from "features/fixture/interfaces";
import { createContext } from "react";
import { useQueryState } from "./useQueryState";

interface FilterBound {
  type: BucketType;
  minute: number;
}
interface PeriodFilter {
  bounds: [FilterBound, FilterBound] | [];
  setBounds: (bounds: [FilterBound, FilterBound] | []) => void;
}

const filter: PeriodFilter = {
  bounds: [],
  setBounds: (bounds: [FilterBound, FilterBound] | []) => {},
};

export const PeriodFilterContext = createContext(filter);

export const usePeriodFilter = () => {
  const [bounds, setBounds] = useQueryState<[FilterBound, FilterBound] | []>(
    "bd",
    []
  );
  return {
    bounds,
    setBounds,
  };
};
