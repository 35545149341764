import { refreshToken } from "app/API";
import { RootState } from "app/rootReducer";
import { addFixtureIds } from "features/calendar/calendarSlice";
import {
  fixtureInitSucceed,
  setTeamStandings,
  updateContestantRatings,
  updateEvents,
  updatePlayersRatings,
  updateStat,
} from "features/fixture/fixtureSlice";
import {
  fixtureSchema,
  teamStandingSchema,
} from "features/fixture/schemas/fixtureSchema";
import { successLogin } from "features/user/userSlice";
import { normalize } from "normalizr";
import { combineEpics, Epic } from "redux-observable";
import { merge } from "rxjs";
import {
  concatMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import {
  InitFixtureMsg,
  TeamStanding,
  UpdateCalendarMsg,
  UpdateRatingsMsg,
  UpdateStatMsg,
} from "./interfaces";
import {
  emitGetCalendar,
  serverConnected,
  serverConnecting,
  serverDisconnected,
  serverReconnecting,
  subscribeCalendars,
  subscribeFixtures,
  successGetCalendar,
  successInitCalendars,
  successInitFixtures,
} from "./socketSlice";
import { WS } from "./socketUtils";
type NormalStandings = TeamStanding;

const normalizeMessage = (fixtures: any[]) => {
  const normalPayload = normalize(fixtures, [fixtureSchema]);
  return normalPayload;
};

const updateCalender = (body: UpdateCalendarMsg) => {
  const normalFixtures = normalizeMessage(body.fixtures);

  const normalStandings = normalize<
    NormalStandings,
    { teamStandings: Record<string, TeamStanding> }
  >(body.standings, [teamStandingSchema]);
  const fixturesIds = normalFixtures.result;

  return [
    fixtureInitSucceed(normalFixtures),
    addFixtureIds({ calendarId: body.calendarId, fixturesIds }),
    setTeamStandings(normalStandings),
    successInitCalendars(),
  ];
};
const initFixture = (msg: InitFixtureMsg) => {
  const { fixtures } = msg;

  const normalFixtures = normalizeMessage(fixtures);
  const [fixtureId] = normalFixtures.result;

  const normalStandings = normalize<
    NormalStandings,
    { teamStandings: Record<string, TeamStanding> }
  >(msg.standings, [teamStandingSchema]);
  return [
    fixtureInitSucceed(normalFixtures),
    updateContestantRatings({ fixtureId }),
    setTeamStandings(normalStandings),
    successInitFixtures(),
  ];
};

const updateFixtureRatings = (body: UpdateRatingsMsg) => {
  const { fixtures } = body;
  const normalFixtures = normalizeMessage(fixtures);
  const [fixtureId] = normalFixtures.result;
  return [
    updatePlayersRatings(normalFixtures),
    updateContestantRatings({ fixtureId }),
  ];
};
const updateMatchStats = (body: UpdateStatMsg) => {
  const { fixtures } = body;
  const normalFixtures = normalizeMessage(fixtures);
  return [updateStat(normalFixtures)];
};

const updateMatchEvents = (body: UpdateStatMsg) => {
  const { fixtures } = body;
  const normalFixtures = normalizeMessage(fixtures);
  return [updateEvents(normalFixtures)];
};

const openConnectionEpic: Epic<any, any, RootState> = (actions$) =>
  actions$.pipe(
    ofActionCreator(serverConnecting),
    map(() => WS.io().connect()),
    switchMap((connection) =>
      merge(
        connection.eventHandler("connect").pipe(map(() => serverConnected())),
        connection
          .eventHandler("reconnecting")
          .pipe(map(() => serverReconnecting())),
        connection
          .eventHandler("disconnect")
          .pipe(map(() => serverDisconnected())),
        connection
          .eventHandler("set-calendar")
          .pipe(concatMap(({ body }) => [successGetCalendar()])),
        connection
          .eventHandler("update-calendar")
          .pipe(concatMap(({ body }) => updateCalender(body))),
        connection
          .eventHandler("update-events")
          .pipe(concatMap(({ body }) => updateMatchEvents(body))),
        connection
          .eventHandler("init-fixture")
          .pipe(concatMap(({ body }) => initFixture(body))),
        connection
          .eventHandler("update-stat")
          .pipe(concatMap(({ body }) => updateMatchStats(body))),
        connection
          .eventHandler("update-ratings")
          .pipe(concatMap(({ body }) => updateFixtureRatings(body))),
        connection.eventHandler("exception").pipe(
          filter(
            (exp) =>
              exp.error !== undefined && exp.error.name === "TokenExpiredError"
          ),
          mergeMap(refreshToken),
          map(({ data }) => successLogin(data))
        )
      )
    )
  );

const emitGetCalendarsEpic: Epic<any, any, RootState> = (actions$) =>
  actions$.pipe(
    ofActionCreator(emitGetCalendar),
    tap(({ payload }) => WS.io().emit("get-calendar", payload)),
    ignoreElements()
  );
const subscribeCalendarsEpic: Epic<any, any, RootState> = (actions$) =>
  actions$.pipe(
    ofActionCreator(subscribeCalendars),
    tap(({ payload }) => WS.io().emit("calendars", payload.initialMessage)),
    ignoreElements()
  );

const subscribeFixturesEpic: Epic<any, any, RootState> = (actions$) =>
  actions$.pipe(
    ofActionCreator(subscribeFixtures),
    tap(({ payload }) => WS.io().emit("fixtures", payload.initialMessage)),
    ignoreElements()
  );
const reconnectOnAuthenticateEpic: Epic = (action$) =>
  action$.pipe(
    ofActionCreator(successLogin),
    tap(() => WS.io().disconnect()),
    map(() => serverConnecting())
  );
export const socketEpic = combineEpics(
  openConnectionEpic,
  subscribeCalendarsEpic,
  subscribeFixturesEpic,
  emitGetCalendarsEpic,
  reconnectOnAuthenticateEpic
);
