import { Accordion } from "@material-ui/core";
import React, { FC } from "react";
import { CustomAccordionDetails } from "./CustomAccordionDetails";
import { CustomAccordionSummary } from "./CustomAccordionSummary";
import { FixtureDetails } from "./FixtureDetails";
import { FixtureSummary } from "./FixtureSummary";

interface Props {
  fixtureId: string;
}

export const FixturePanel: FC<Props> = ({ fixtureId }) => (
  <Accordion TransitionProps={{ unmountOnExit: true }}>
    <CustomAccordionSummary>
      <FixtureSummary fixtureId={fixtureId} />
    </CustomAccordionSummary>
    <CustomAccordionDetails>
      <FixtureDetails fixtureId={fixtureId} />
    </CustomAccordionDetails>
  </Accordion>
);
