import { selectTrnPlayerName } from "features/calendar/selectors/selectTrnPlayerName";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectTrnPlayerJersey } from "../../../features/calendar/selectors/selectTrnPlayerJersey";
import { PlayerStat } from "../Fixture/Pitch/PlayerStat";
import { TopPlayerCont } from "../Fixture/Pitch/PlayerStyles";

interface Props {
  id: string;
  stat: number;
  status: RemoteStatus;
  reverse?: boolean;
}

export const TrnPlayerStat: FC<Props> = ({
  id,
  reverse,
  status,
  stat,
}) => {
  const name = useSelector(selectTrnPlayerName(id));
  const shirtNum = useSelector(selectTrnPlayerJersey(id));
  return (
    (stat && (
      <TopPlayerCont reverse={reverse}>
        <PlayerStat
          status={status}
          stat={stat}
          kitColour={"#FFFFFF"}
          name={name}
          jerseyNum={shirtNum}
        />
      </TopPlayerCont>
    )) ||
    null
  );
};
