import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RefereeStats } from "./RefereeStats";

interface Props {
  id: string;
  open: boolean;
  setOpen: (b: boolean) => void;
  closeHandler: () => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const RefereeStatsDialog: FC<Props> = ({
  id,
  open,
  setOpen,
  closeHandler,
}) => {
  const [t] = useTranslation();
  const fullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <Dialog
      scroll="body"
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <Toolbar>
        <Box flexGrow={1}>
          <Typography variant="h6">{t("glossary.referee_stats")}</Typography>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={closeHandler}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider variant="fullWidth" />
      <RefereeStats id={id} />
    </Dialog>
  );
};
