import { createSelector } from "@reduxjs/toolkit";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { selectContestant } from "./selectContestant";

export const selectSeasonStatRatio = (
  id: string,
  type: Nullable<StatType>,
  ratioType: "side" | "time"
) =>
  createSelector(
    selectContestant(id),
    (cont) => {
      if (type === null) {
        return "0";
      }

      const value = cont?.seasonalStats?.[type]?.value || 0;
      const total =
        ratioType === "side"
          ? cont?.allSideSeasonalStats?.[type]?.value || value
          : cont?.fullTimeSeasonalStats?.[type]?.value || value;

      const ratio = value / total || 0;

      return `${(ratio * 100).toFixed()} %`;
    }
  );
