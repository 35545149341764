import { STAT_ITEMS } from "app/components/Stats/STAT_ITEMS";
import { StatItem } from "app/interface/StatItem";
import { flow, keyBy, map } from "lodash/fp";
import { schema } from "normalizr";
import { StatType } from "shared/enums";
import { getPlayerStatType } from "../usePlayerStatType";

const initContestant = ({ statType }: StatItem) => ({
  type: statType,
  value: 0,
});

const initPlayer = ({ statType }: StatItem) => ({
  type: getPlayerStatType(statType),
  value: 0,
});

const lineupSchema = new schema.Entity(
  "players",
  {},
  {
    idAttribute: "playerRef",
    processStrategy: ({ stat, ...rest }, parent, key) => {
      const playerStats = flow(keyBy("type"))(stat);
      const initialStats = flow(
        map(initPlayer),
        keyBy("type")
      )([
        ...STAT_ITEMS,
        ...STAT_ITEMS.flatMap(({ subStats = [] }) => subStats),
      ]);
      const stats = { ...initialStats, ...playerStats };
      return { ...rest, stats };
    },
  }
);
const ratingsSchema = new schema.Entity(
  "players",
  {},
  {
    idAttribute: "playerRef",
    processStrategy: ({ value }, parent, key) => {
      const stats = {
        [StatType.avg_ratings]: {
          type: StatType.avg_ratings,
          value,
        },
      };
      return { stats };
    },
    mergeStrategy: (playerA, playerB) => {
      const { stats: statsA, ...restA } = playerA;
      const { stats: statsB, ...restB } = playerB;

      return {
        ...restA,
        ...restB,
        stats: { ...statsA, ...statsB },
      };
    },
  }
);
const contRatingsSchema = new schema.Entity(
  "contestants",
  {},
  {
    idAttribute: "teamRef",
    processStrategy: ({ value }, parent, key) => {
      const stats = {
        [StatType.avg_ratings]: {
          type: StatType.avg_ratings,
          value,
        },
      };
      return { stats };
    },
    mergeStrategy: (contA, contB) => {
      const { stats: statsA, ...restA } = contA;
      const { stats: statsB, ...restB } = contB;
      return {
        ...restA,
        ...restB,
        stats: { ...statsA, ...statsB },
      };
    },
  }
);
const contestantsSchema = new schema.Entity(
  "contestants",
  {
    lineup: [lineupSchema],
  },
  {
    idAttribute: "teamRef",
    processStrategy: (value, parent, key) => {
      const { stat, playerLineUp, ...rest } = value;
      const { matchPlayer: lineup, formation } = playerLineUp || {};
      const matchStat = flow(keyBy("type"))(stat);
      const initialStats = flow(
        map(initContestant),
        keyBy("type")
      )([
        ...STAT_ITEMS,
        ...STAT_ITEMS.flatMap(({ subStats = [] }) => subStats),
      ]);
      const stats = { ...initialStats, ...matchStat };
      return { ...rest, lineup, stats, formation };
    },
  }
);
export const periodStatsSchema = {
  contestants: [contestantsSchema],
  playerRatings: [ratingsSchema],
  contestantRatings: [contRatingsSchema],
};
