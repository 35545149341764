import { RootState } from "app/rootReducer";
import { StatsViewContext } from "features/fixture/StatsViewContext";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { SeasonalFilterContext } from "../seasonal/SeasonalFilterContext";
import { MobileStatsList } from "./MobileStatsList";
import {
  MobileTeamStatsViewProps,
  MobileTeamStatView,
} from "./MobileTeamStatView";
import { SEASONAL_STATS_ITEMS } from "./SEASONAL_STATS_ITEMS";
import { useContSeasonDetails } from "./useContSeasonalDetails";

interface Props {
  homeId: string;
  awayId: string;
}

const TeamStatDetails: FC<
  Omit<
    MobileTeamStatsViewProps,
    "value" | "avg" | "timeRatio" | "sideRatio"
  > & {
    homeId: string;
    awayId: string;
    statType: StatType;
  }
> = ({
  status,
  name,
  desc,
  isSelected,
  clickHandler,
  statType,
  homeId,
  awayId,
}) => {
  const {
    stat: homeStat,
    avg: homeAvg,
    sideRatio: homeSideRatio,
    timeRatio: homeTimeRatio,
  } = useContSeasonDetails(homeId, statType);
  const {
    stat: awayStat,
    avg: awayAvg,
    sideRatio: awaySideRatio,
    timeRatio: awayTimeRatio,
  } = useContSeasonDetails(awayId, statType);

  const [t] = useTranslation();
  const {
    side,
    bounds: [lowerMinute, upperMinute],
    gameLimit,
  } = useContext(SeasonalFilterContext);
  const sideTitle =
    side
      ?.split("-")
      .map((s) => t(`glossary.${s}`))
      .join("-") +
    " / " +
    t("glossary.total");
  const timeTitle =
    t(`filters.period_${lowerMinute}_${upperMinute}`) +
    " / " +
    t("glossary.fulltime");

  return (
    <MobileTeamStatView
      status={status}
      name={name}
      desc={desc}
      value={[homeStat, awayStat]}
      avg={[homeAvg, awayAvg]}
      sideRatio={[homeSideRatio, awaySideRatio]}
      timeRatioTitle={
        (lowerMinute !== "PM" || upperMinute !== "FT") && timeTitle
      }
      sideRatioTitle={!gameLimit && side && sideTitle}
      avgTitle={t("glossary.average_per_match")}
      timeRatio={[homeTimeRatio, awayTimeRatio]}
      isSelected={isSelected}
      clickHandler={clickHandler}
    />
  );
};
export const MobileSeasonalStatsListContainer: FC<Props> = ({
  homeId,
  awayId,
}) => {
  const { index, setIndex } = useContext(StatsViewContext);

  const status = useSelector((state: RootState) => state.fixtures.h2hStatus);
  return (
    <MobileStatsList
      index={index}
      items={SEASONAL_STATS_ITEMS}
      viewHoc={(name, desc, statType) => (
        <TeamStatDetails
          homeId={homeId}
          awayId={awayId}
          status={status}
          statType={statType}
          name={name}
          desc={desc}
          isSelected={index === statType}
          clickHandler={() =>
            index === statType ? setIndex(null) : setIndex(statType)
          }
        />
      )}
    />
  );
};
