import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Toolbar,
} from "@material-ui/core";
import MenuOpenIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import {
  getCalendars,
  getCountMatchesByDate,
  toggleLiveFilter,
} from "features/calendar/calendarSlice";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDocumentTitle } from "utils/useDocumentTitle";
import { CalendarContainer } from "../Calendar/CalendarCont";
import { CountryItem } from "./CountryItem";
import { FilterChip } from "./FilterChip";
import { useCalendarId } from "./useCalendarId";
import { useCountries } from "./useCountries";
const LiveFilter = () => {
  const live = useSelector((state) => state.calendar.live);
  const dispatch = useDispatch();
  return (
    <>
      <FilterChip
        label="All"
        onClick={() => dispatch(toggleLiveFilter({ live: false }))}
        active={!live}
      />
      <FilterChip
        label="Live"
        onClick={() => dispatch(toggleLiveFilter({ live: true }))}
        active={live}
      />
    </>
  );
};
export const Home: FC = () => {
  const schedule = useSelector((state) => state.calendar.schedule);
  const connection = useSelector((state) => state.socketStatus.serverStatus);
  const [calendarId, setCalendarId] = useCalendarId();
  const calendars = useSelector((state) => state.calendar.byId);
  const calendarIds = useSelector((state) => state.calendar.ids);
  const getCalendar = () => {
    if (calendarIds.length === 0) return null;
    if (!calendarId && calendarIds.length > 0) {
      setCalendarId(calendarIds[0]);
    }
    return calendars[calendarId!];
  };

  const dispatch = useDispatch();

  const calendar = getCalendar();
  useDocumentTitle(calendar ? calendar.name : " ");
  useEffect(() => {
    if (connection === "connected") {
      dispatch(getCalendars({ page: 1, per_page: 100 }));
    }
  }, [connection, dispatch]);

  useEffect(() => {
    if (connection === "connected" && calendarIds.length > 0) {
      dispatch(
        getCountMatchesByDate({
          date: schedule,
          trnIds: calendarIds,
          timezoneOffset: new Date(schedule).getTimezoneOffset(),
        })
      );
    }
  }, [calendarIds, connection, dispatch, schedule]);
  const countries = useCountries();
  const [nav, setNav] = useState(false);
  return (
    <>
      <Hidden mdUp>
        <Button onClick={() => setNav(true)} endIcon={<MenuOpenIcon />}>
          All Leagues
        </Button>
        <Drawer open={nav} onClose={() => setNav(false)}>
          <Box position="sticky" top={0} zIndex="appBar" bgcolor="white">
            <Toolbar>
              <Box display="flex" flexGrow={1}>
                <LiveFilter />
              </Box>
              <IconButton onClick={() => setNav(false)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Divider />
          </Box>

          <List component="nav">
            {countries.map((cont) => (
              <CountryItem key={cont.countryId} {...cont}></CountryItem>
            ))}
          </List>
        </Drawer>
      </Hidden>
      <Box display="flex">
        <Hidden mdDown>
          <Box width={280} flexShrink={0} clone>
            <Drawer open variant="permanent">
              <Toolbar />
              <Box display="flex" px={1} pb={2}>
                <LiveFilter />
              </Box>
              <Divider />
              <Box overflow="auto">
                <List component="nav">
                  {countries.map((cont) => (
                    <CountryItem key={cont.countryId} {...cont}></CountryItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </Box>
        </Hidden>
        <Box width="calc(100% - 280px)" flexGrow={1}>
          {calendarId ? (
            calendars[calendarId] ? (
              <CalendarContainer id={calendarId} schedule={schedule} />
            ) : null
          ) : null}
        </Box>
      </Box>
    </>
  );
};
