import { useEffect } from "react";

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITLE} :: ${title}`;

    return () => {
      document.title = `${process.env.REACT_APP_TITLE}`;
    };
  }, [title]);
};
