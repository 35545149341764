import {
  Box,
  Button,
  Link as MuiLink,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { updateUser } from "app/API";
import { Paths } from "app/routes/Paths";
import { UpdateUserDto } from "features/user/dto/update-user.dto";
import { setUser } from "features/user/userSlice";
import { Field, Form, Formik } from "formik";
import React, { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { InputContainer } from "../core/inputs/InputContainer";
import { PasswordTextField } from "../core/inputs/PasswordTextField";
import { TextField } from "../core/inputs/TextField";
const FailedMessage: FC<{ error: string }> = ({ error }) => (
  <>
    {error && (
      <Box mb={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )}
  </>
);

const TermsLink: FC = ({ children }) => (
  <Link to={Paths.TERMS}>
    <MuiLink>{children}</MuiLink>
  </Link>
);
export const UpdateUserForm: FC<{ email?: string }> = ({ email }) => {
  const initialValues: UpdateUserDto = {
    firstName: "",
    lastName: "",
    password: "",
  };
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().max(70).required(),
        lastName: Yup.string().max(70).required(),
        password: Yup.string().min(8).required(),
      }),
    []
  );
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values, helper) => {
        const { setStatus, setSubmitting } = helper;
        try {
          const { data } = await updateUser(values);
          dispatch(setUser(data));
          setSubmitting(false);
          setStatus({ success: true });
          history.replace(Paths.CHECKOUT);
        } catch (err) {
          /* @ts-expect-error */
          const error = err.response.data.message;
          setStatus({ error });
          setSubmitting(false);
        }
      }}
      initialStatus={{
        isLoading: false,
        success: false,
        error: "",
      }}
    >
      {({ isSubmitting, status, values }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <Field
              type="text"
              name="firstName"
              label={t("accounts.first_name_field")}
              component={TextField}
            />
            <Field
              type="text"
              name="lastName"
              label={t("accounts.last_name_field")}
              component={TextField}
            />
            <InputContainer>
              <MuiTextField
                type="email"
                value={email}
                disabled
                label={t("accounts.email_field")}
                variant="outlined"
                fullWidth
              />
            </InputContainer>
            <Field
              name="password"
              label={t("accounts.password_field")}
              helperText={t("accounts.password_help")}
              component={PasswordTextField}
            />
            <Box my={1}>
              <Typography variant="caption">
                <Trans i18nKey={"create_account.agree_to_terms"}>
                  {"By creating account I agree with Statistigoal "}
                  <TermsLink>{"Terms of Condition"}</TermsLink>
                </Trans>
              </Typography>
            </Box>
            <FailedMessage error={status.error} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isSubmitting}
            >
              {t("button.submit")}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
