import axios from "axios";
import {
  GetCountMatchesByDate,
  GetPaginatedCalendars,
  SetCountMatchesByDate,
} from "features/calendar/calendarSlice";
import { CalendarDto } from "features/calendar/interfaces";
import { FetchStandingsParams } from "features/calendar/standings/FetchStandingsParams";
import { CountMatchesPlayedDto } from "features/fixture/dto/CountMatchesPlayedDto";
import { PeriodStatsDto } from "features/fixture/interfaces";
import { SeasonAvgData } from "features/fixture/types";
import { CreateForgetPass } from "features/user/dto/create-forget-pass";
import { CreateLoginDto } from "features/user/dto/create-login.dto";
import { CreateNewPass } from "features/user/dto/create-new-pass";
import { CreateUserDto } from "features/user/dto/create-user.dto";
import { JWTTokenDto } from "features/user/dto/jwtToken.dto";
import { LoginDto } from "features/user/dto/login.dto";
import { UpdateUserDto } from "features/user/dto/update-user.dto";
import { UserDto } from "features/user/dto/user.dto";
import qs from "qs";
import { from } from "rxjs";
import { SeasonalStatsDto } from "../features/fixture/dto/SeasonalStatsDto";
import {
  GetPeriodBucket,
  GetSeasonalStats,
} from "../features/fixture/fixtureSlice";
export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_V0,
  withCredentials: true,
  paramsSerializer: qs.stringify,
});
const createPeriodStatEndpoint = (bounds: GetPeriodBucket) =>
  API.get<PeriodStatsDto>(`/match_stats`, {
    params: { bounds },
  });

export const fetchPeriodStats = (bounds: GetPeriodBucket) =>
  from(createPeriodStatEndpoint(bounds));

export const submitLogin = (data: CreateLoginDto) => {
  return API.post<LoginDto>("/auth/login", data);
};
export const submitSignUp = (data: CreateUserDto) => {
  return API.post<UserDto>("/auth/users", data);
};
export const updateUser = (data: UpdateUserDto) =>
  API.put<UserDto>("/users", data);
export const submitForgetPass = (data: CreateForgetPass) => {
  return API.post<any>("/users/password/reset", data);
};

export const submitNewPass = (data: CreateNewPass) => {
  return API.post<any>("/auth/password", data);
};

export const refreshToken = () => {
  return API.post<JWTTokenDto>("/auth");
};

export const reAuthorize = () => from(refreshToken());

const createSeasonalEndpoint = ({ seasonId, ...params }: GetSeasonalStats) =>
  API.get<SeasonalStatsDto>(`/match_stats/seasonal/${seasonId}`, {
    params,
  });

const createSeasonAvgEndpoint = ({ seasonId, ...params }: GetSeasonalStats) =>
  API.get<SeasonAvgData>(`/match_stats/seasonal/${seasonId}`, {
    params,
  });
export const fetchSeasonAvg = (action: GetSeasonalStats) =>
  from(createSeasonAvgEndpoint(action));

const createCountedPlayedEndpoint = (seasonId: string, contestantId: string) =>
  API.get<CountMatchesPlayedDto>(
    `/match_stats/seasonal/${seasonId}/contestant/${contestantId}/count/played`
  );

export const fetchCountedPlayersPlayed = (
  seasonId: string,
  contestantId: string
) => from(createCountedPlayedEndpoint(seasonId, contestantId));

export const fetchSeasonalStats = (params: GetSeasonalStats) =>
  from(createSeasonalEndpoint(params));

export const logout = () => API.post("/auth/logout");

const createFetchRefereeEndpoint = (calId: string, refereeId: string) =>
  API.get<any>(`/calendars/${calId}/referees/${refereeId}`);

export const fetchRefereeReq = (calId: string, refereeId: string) =>
  from(createFetchRefereeEndpoint(calId, refereeId));

const createFetchStandingsEndpoint = (
  calId: string,
  params?: FetchStandingsParams
) =>
  API.get<any>(`/calendars/${calId}/standings`, {
    params,
  });

export const fetchStandingsReq = (
  calId: string,
  params?: FetchStandingsParams
) => from(createFetchStandingsEndpoint(calId, params));

const createFetchCountMatchesEndpoint = (params: GetCountMatchesByDate) =>
  API.get<SetCountMatchesByDate[]>("/matches/count", {
    params,
  });

export const fetchCountMatchesReq = (params: GetCountMatchesByDate) =>
  from(createFetchCountMatchesEndpoint(params));

export const fetchCalendars = (params?: GetPaginatedCalendars) =>
  from(createCalendars(params));

const createCalendars = (params?: GetPaginatedCalendars) =>
  API.get<CalendarDto[]>("/calendars", {
    params,
  });

export const fetchPlans = <P>() => {
  return API.get<P[]>("/subscription/plans");
};

export const createCheckoutSession = (priceId: string) => {
  return API.post<{ url: string }>("/subscription/checkout", {
    priceId,
  }).then((res) => res.data);
};

export const createBillingPortal = () => {
  return API.post<{ url: string }>("/subscription/billing").then(
    (res) => res.data
  );
};
