import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RemoteStatus } from "features/fixture/interfaces";
import { flow, keyBy, mapValues } from "lodash/fp";
import { RefereeStatType } from "./RefereeStatType";

interface Stat {
  value: number | string;
  type: RefereeStatType;
}

type Referee = {
  name?: string;
  status: RemoteStatus;
} & Partial<Record<RefereeStatType, string | number>>;

interface Referees {
  byId: Record<string, Referee>;
  allIds: string[];
}

interface FetchReferee {
  calId: string;
  refereeId: string;
}
interface RefereeDto {
  uId: string;
  name: string;
  stat: Stat[];
}
interface RefereeFetched {
  calId: string;
  referees: RefereeDto[];
}

const initialState: Referees = {
  byId: {},
  allIds: [],
};

const refereesSlice = createSlice({
  name: "referees",
  initialState,
  reducers: {
    fetchReferee(state, action: PayloadAction<FetchReferee>) {
      const { refereeId } = action.payload;
      state.byId[refereeId] = { status: "loading" };
    },
    refereeFetched(state, action: PayloadAction<RefereeFetched>) {
      const { referees } = action.payload;
      const [ref] = referees;
      const { name, stat } = ref;
      const stats = flow(keyBy("type"), mapValues("value"))(stat);
      state.byId[ref.uId] = { name, status: "success", ...stats };
    },
  },
});

export const { fetchReferee, refereeFetched } = refereesSlice.actions;
export default refereesSlice.reducer;
