import { Box, styled } from "@material-ui/core";
export const BlurEffect = styled(Box)({
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    backdropFilter: "blur(4px)",
  },
});
