import { fetchCountedPlayersPlayed } from "app/API";
import { Epic } from "redux-observable";
import { forkJoin } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import { getPlayersPlayed, setPlayersPlayed } from "./fixtureSlice";
import { httpErrorHandler } from "./httpErrorHandler";

export const fetchPlayersPlayedEpic: Epic = (actions$) =>
  actions$.pipe(
    ofActionCreator(getPlayersPlayed),
    mergeMap(({ payload }) =>
      forkJoin({
        home: fetchCountedPlayersPlayed(payload.seasonId, payload.homeId).pipe(
          map(({ data }) => data)
        ),
        away: fetchCountedPlayersPlayed(payload.seasonId, payload.awayId).pipe(
          map(({ data }) => data)
        ),
      }).pipe(
        map(setPlayersPlayed),
        catchError((e) => httpErrorHandler(e, getPlayersPlayed(payload)))
      )
    )
  );
