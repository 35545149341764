import { Socket } from "app/interface";
import { fromEvent } from "rxjs";
import io from "socket.io-client";
import { Message, MessageType } from "./interfaces";

export class WS {
  private static instance: WS;
  private wsIO: Socket;
  private constructor() {
    this.wsIO = io(`${process.env.REACT_APP_BASE_URL}`, {
      autoConnect: false,
    });
  }

  public static io(): WS {
    if (!WS.instance) {
      WS.instance = new WS();
    }
    return WS.instance;
  }

  connect() {
    this.wsIO.connect();
    return this;
  }

  eventHandler<T extends MessageType>(eventType: T) {
    return fromEvent<Message<T>>(this.wsIO, eventType);
  }

  emit(eventType: "get-calendar" | "fixtures" | "calendars", message: any) {
    this.wsIO.emit(eventType, message);
  }

  disconnect() {
    this.wsIO.disconnect();
  }
}
