import { httpErrorHandler } from "features/fixture/httpErrorHandler";
import { teamSchema } from "features/fixture/schemas/fixtureSchema";
import { setContestants, setPlayers } from "features/metadata/metadataSlice";
import { flow, keyBy, mapValues } from "lodash/fp";
import { normalize } from "normalizr";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import { fetchTrnStats, setTrnStats } from "../calendarSlice";
import { fetchTrnStatsReq } from "./fetchTrnStats";
import { FetchTrnStatsData } from "./FetchTrnStatsData";

const toTrnStatsPayload = (id: string, data: FetchTrnStatsData) => {
  const { contestants, players } = data.stats;
  const teamsMap = flow(keyBy("type"), mapValues("teamStats"))(contestants);
  const trnAvgStats = flow(keyBy("type"), mapValues("trnAvg"))(contestants);
  const playersMap = flow(keyBy("type"), mapValues("positionStats"))(players);
  const { entities } = normalize(data.names, [teamSchema]);
  return {
    id,
    players: playersMap,
    contestants: teamsMap,
    trnAvgStats,
    metadata: entities,
  };
};

export const fetchTrnStatsEpic: Epic = (action$) =>
  action$.pipe(
    ofActionCreator(fetchTrnStats),
    mergeMap(({ payload }) =>
      fetchTrnStatsReq(payload.id, payload.params).pipe(
        map(({ data }) => toTrnStatsPayload(payload.id, data)),
        mergeMap(({ metadata, ...stats }) => {
          const { players = {}, contestants = {} } = metadata;
          return [setPlayers(players), setContestants(contestants), setTrnStats(stats)];
        }),
        catchError((e) => httpErrorHandler(e, fetchTrnStats))
      )
    )
  );
