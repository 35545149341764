import {
  Box,
  CircularProgress as MuiCircularProgress,
  CircularProgressProps,
  styled,
  Typography,
  withStyles
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Grid } from "app/components/core/Grid";
import React, { FC } from "react";

interface Props {
  title: string;
  success: number;
  error: number;
}

const Stripped = styled("div")(({ theme }) => ({
  "&:nth-child(even)": { backgroundColor: theme.palette.grey[200] },
}));
export const Comparison: FC<Props> = ({ title, success, error }) => (
  <Stripped>
    <Grid container alignItems="center">
      <Grid item xs={4}>
        <Gauge value={success} type="error" />
      </Grid>
      <Grid item xs={4}>
        <ComparisonTitle variant="subtitle1">{title}</ComparisonTitle>
      </Grid>
      <Grid item xs={4}>
        <Gauge value={error} type="success" />
      </Grid>
    </Grid>
  </Stripped>
);
const ComparisonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  lineHeight: 1.5,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
const CircularProgress = withStyles<
  string,
  {},
  CircularProgressProps & {
    type: "success" | "error" | undefined;
  }
>((theme) => ({
  root: {
    color: (props) => (props.type === "error" ? red[900] : green[900]),
  },
  circle: {
    fill: (props) => (props.type === "error" ? red[50] : green[50]),
  },
}))(MuiCircularProgress);
const Gauge: FC<{ value: number; type: "error" | "success" }> = ({
  value,
  type,
}) => (
  <Box
    position="relative"
    display="inline-flex"
    color={type === "error" ? red[900] : green[900]}
  >
    <CircularProgress variant="determinate" value={value} type={type} />
    <Box
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="button">{`${value}%`}</Typography>
    </Box>
  </Box>
);
