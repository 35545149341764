import { Box, ButtonBase, styled, Typography } from "@material-ui/core";
import React, { FC } from "react";

interface FeatureTitleProps {
  index: number;
  title: string;
  subtitle: string;
  isActive: boolean;
  onClick: () => void;
}

interface IconProps {
  num: string;
  variant?: "outlined" | "contained" | false;
}

const Title = styled(Typography)({
  lineHeight: 1.2,
});

const NumberIcon: FC<IconProps> = ({ num, variant = "outlined" }) => (
  <Box
    component="span"
    border={2}
    borderRadius="50%"
    width="2rem"
    height="2rem"
    mx={1}
    display="flex"
    justifyContent="center"
    alignItems="center"
    fontWeight={900}
    fontSize="subtitle2.fontSize"
    color={variant === "contained" ? "primary.main" : "common.white"}
    bgcolor={variant === "contained" ? "common.white" : "transparent"}
  >
    {num}
  </Box>
);
export const FeatureTitle: FC<FeatureTitleProps> = ({
  index,
  title,
  subtitle,
  isActive,
  onClick,
}) => (
  <Box my={2} color={isActive ? "inherit" : "grey.400"}>
    <Box clone>
      <ButtonBase onClick={onClick}>
        <Box>
          <NumberIcon
            num={index.toString()}
            variant={isActive && "contained"}
          />
        </Box>
        <Box lineHeight={1}>
          <Title variant="h6" align="left">
            {title}
          </Title>
        </Box>
      </ButtonBase>
    </Box>
    {isActive && (
      <Box px="3rem" clone>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
    )}
  </Box>
);
