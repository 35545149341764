import { Box, Card } from "@material-ui/core";
import React from "react";
import { FixtureDate } from "../Calendar/FixtureDate";
import { FixtureWeek } from "../Calendar/FixtureWeek";
import { PeriodStatus } from "../Calendar/PeriodStatus";
import { Grid } from "../core/Grid";
import { ContestantInfo } from "./ContestantInfo";
import { ScoreCont } from "./ScoreCont";

interface Props {
  homeId: string;
  awayId: string;
  fixtureId: string;
}
export const FixtureOverview = React.forwardRef<HTMLElement | undefined, Props>(
  ({ homeId, awayId, fixtureId }, ref) => (
    /*@ts-expect-error*/
    <Box ref={ref}>
      <Card variant="outlined">
        <Box py={2} px={{ xs: 1, md: 4 }}>
          <Grid container>
            <Grid item xs={4}>
              <FixtureDate id={fixtureId} />
            </Grid>
            <Grid item xs={4} textAlign="center">
              <PeriodStatus fixtureId={fixtureId} />
            </Grid>
            <Grid item xs={4} textAlign="right">
              <FixtureWeek id={fixtureId} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" textAlign="center" py={1}>
            <Grid item xs={4}>
              <ContestantInfo contestantId={homeId} position="home" />
            </Grid>
            <Grid item xs={4}>
              <ScoreCont fixtureId={fixtureId} />
            </Grid>
            <Grid item xs={4}>
              <ContestantInfo contestantId={awayId} position="away" />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  )
);
