import { Skeleton } from "@material-ui/lab";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";

interface Props {
  status: RemoteStatus;
  text: any;
}
export const TextSkeleton: FC<Props> = ({ status, text }) => (
  <>
    {status === "loading" && <Skeleton variant="text" width="1.25rem" />}
    {(status === "success" || status === "unknown") && text}
  </>
);
