import { AccordionDetails } from "@material-ui/core";
import styled from "styled-components";

export const CustomAccordionDetails = styled(AccordionDetails)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[500]};
  background: ${({ theme }) => theme.palette.background.paper};
  &:hover {
    background: ${({ theme }) => theme.palette.grey[50]};
  }
  padding: 0;
`;
