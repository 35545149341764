export const loadItem = <T = any>(key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item === null) {
      return undefined;
    }

    return JSON.parse(item) as T;
  } catch (error) {
    return undefined;
  }
};

export const saveItem = <T>(key: string, obj: T) => {
  try {
    const item = JSON.stringify(obj);
    localStorage.setItem(key, item);
  } catch (error) {}
};

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {}
};
