import { Box, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { Paths } from "app/routes/Paths";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { LiveFeedback } from "../core/LiveFeedback";

interface Props {
  variant: "fullWidth" | "scrollable";
  isMatchActive: boolean;
  isLive: boolean;
}
const LiveLabel: FC<{ label: string }> = ({ label }) => (
  <Box display="flex" alignItems="center">
    <LiveFeedback />
    <Typography variant="button">{label}</Typography>
  </Box>
);
export const TabBar: FC<Props> = ({ isLive, variant, isMatchActive }) => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [, id, path] = pathname.match(/\/app\/fixtures\/(\w+)\/(\w+)/) ?? [];
  return (
    <Box bgcolor="background.default">
      <Paper>
        <Tabs
          value={path}
          onChange={(e, tab) => push(`${Paths.FIXTURES}/${id}/${tab}`)}
          indicatorColor="primary"
          textColor="primary"
          variant={variant}
          aria-label="full width tabs example"
        >
          <Tab
            label={
              isLive ? (
                <LiveLabel label={t("glossary.live_stats")} />
              ) : (
                t("glossary.match_stats")
              )
            }
            value="match_stats"
            disabled={isMatchActive}
          />
          <Tab label={t("glossary.team_stats")} value="team_stats" />
          <Tab label={t("glossary.league_stats")} value="league_stats" />
          <Tab label={t("glossary.standing")} value="standing" />
        </Tabs>
      </Paper>
    </Box>
  );
};
