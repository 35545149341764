import { selectFixtureContestants } from "features/fixture/selectors";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Grid } from "../core/Grid";
import { ContestantInfo } from "../Fixture/ContestantInfo";
import { ScoreCont } from "../Fixture/ScoreCont";
import { FixtureWeek } from "./FixtureWeek";
import { PeriodStatus } from "./PeriodStatus";
interface Props {
  fixtureId: string;
}

export const FixtureSummary: FC<Props> = ({ fixtureId }) => {
  const [homeId, awayId] = useSelector(selectFixtureContestants(fixtureId));
  return (
    <>
      <Grid
        container
        alignItems="center"
        alignContent="center"
        textAlign="center"
      >
        <Grid item xs={12}>
          <Grid container py={1} px={1}>
            <Grid item md={2} />
            <Grid item md={3} xs={4}></Grid>
            <Grid item md={2} xs={4}>
              <PeriodStatus fixtureId={fixtureId} />
            </Grid>
            <Grid item md={3} xs={4} pr={3}>
              <FixtureWeek id={fixtureId} />
            </Grid>
            <Grid item md={2} />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          textAlign="center"
          pb={1}
          px={1}
        >
          <Grid item md={2} />
          <Grid item md={3} xs={4}>
            <ContestantInfo contestantId={homeId} position="home" />
          </Grid>
          <Grid item md={2} xs={4}>
            <ScoreCont fixtureId={fixtureId} />
          </Grid>
          <Grid item md={3} xs={4}>
            <ContestantInfo contestantId={awayId} position="away" />
          </Grid>
          <Grid item md={2} />
        </Grid>
      </Grid>
    </>
  );
};
