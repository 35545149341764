import React from "react";
import styled from "styled-components";
import { BulletIcon } from "../Calendar/EventIcons";

const Container = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin: 4px;
  height: 1em;
  width: 1em;

  & > svg {
    left: 4px;
    position: absolute;
    margin: 0 auto;
    animation: live 1s infinite ease-in-out;
    animation-fill-mode: both;
    &:nth-child(1) {
      animation-delay: -0.1s;
    }
  }
  @keyframes live {
    0%,
    80%,
    100% {
      transform: scale(0.8);
    }
    40% {
      transform: scale(1);
    }
  }
`;

export const LiveFeedback = () => (
  <Container>
    <BulletIcon fontSize="0.5rem" fill="#FF6659" />
  </Container>
);
