import { createSelector } from "@reduxjs/toolkit";
import { getGridArea } from "app/interface/formations";
import { RootState } from "app/rootReducer";
import { PeriodId, StatType } from "shared/enums";
import { formatDate, toLocalTime } from "utils/momentUtils";
import { Period } from "./dto/Period";
import {
  Booking,
  BucketType,
  Contestant,
  EventVariant,
  Goal,
  PeriodEvent,
  Substitution,
  VarEventType,
} from "./interfaces";
import { selectContestant } from "./selectContestant";

export const selectMatchKit = (id: string) =>
  createSelector(
    selectContestant(id),
    (cont) => cont?.kit?.colour1 || "#FFFFFF"
  );

export const selectTeamKit = (id: string) =>
  createSelector(
    selectContestant(id),
    (cont) => cont && (cont.teamKits?.[cont.position]?.colour1 || "#FFFFFF")
  );

export const selectTeamPosition = (id: string) =>
  createSelector(selectContestant(id), (cont) => cont?.position);

// export const selectTopPlayersStats = (id: string, type: Nullable<StatType>) =>
//   createSelector(selectContestant(id), (cont) =>
//     type ? cont?.topPlayers?.[type] : 0
//   );

export const selectFormation = (id: string) =>
  createSelector(selectContestant(id), (cont) => {
    const formation = cont?.formation;
    if (!formation) return {};
    const gridArea = getGridArea(formation);
    const involved = cont?.startPlayers;
    return { involved, gridArea };
  });
export const selectSide = (id: string) =>
  createSelector(selectContestant(id), (contestant) => contestant?.side);

export const selectPbStatus = (state: RootState) => state.fixtures.pbStatus;

export const selectFixtureInitStatus = (state: RootState) =>
  state.socketStatus.initFixtureStatus;

export const selectFixtureRemoteStatus = createSelector(
  [selectPbStatus, selectFixtureInitStatus],
  (status, initStatus) => (status === "unknown" ? initStatus : status)
);

const selectPlayer = (id: string) => (state: RootState) =>
  state.fixtures.players[id] || {};

export const selectPlayerName = (id: string) =>
  createSelector(
    selectPlayer(id),
    ({ personName, name, firstName, knownName, lastName }) =>
      knownName ||
      (name && `${firstName[0]}.${lastName}`) ||
      (personName && personName.known) ||
      (personName && `${personName.first[0]}.${personName.last}`) ||
      "-"
  );

const selectPlayerSubOff = (id: string) =>
  createSelector(selectPlayer(id), ({ stats }) =>
    Boolean(stats[StatType.total_sub_off]?.value)
  );

const selectPlayerSubOn = (id: string) =>
  createSelector(selectPlayer(id), ({ stats }) =>
    Boolean(stats[StatType.total_sub_on]?.value)
  );

export const selectPlayerPlace = (id: string) =>
  createSelector(selectPlayer(id), (player) => `p${player.formation_position}`);

export const selectPlayerShirtNum = (id: string) =>
  createSelector(
    selectPlayer(id),
    ({ jerseyNum, shirtNumber }) => jerseyNum || shirtNumber?.toString() || ""
  );

const selectPlayerScored = (id: string) =>
  createSelector(selectPlayer(id), ({ stats }) =>
    Boolean(stats[StatType.goals]?.value)
  );
const selectPlayerOwnGoal = (id: string) =>
  createSelector(selectPlayer(id), ({ stats }) =>
    Boolean(stats[StatType.own_goals]?.value)
  );

const selectPlayerYCard = (id: string) =>
  createSelector(
    selectPlayer(id),
    ({ stats }) =>
      Boolean(stats[StatType.total_yel_card]?.value) &&
      !Boolean(stats[StatType.second_yellow]?.value)
  );

const selectPlayerRCard = (id: string) =>
  createSelector(selectPlayer(id), ({ stats }) =>
    Boolean(stats[StatType.total_red_card]?.value)
  );

const selectPlayerDirectRCard = (id: string) =>
  createSelector(
    [selectPlayerRCard(id), selectPlayer2ndYCard(id)],
    (redCard, secYCard) => redCard && !secYCard
  );

const selectPlayer2ndYCard = (id: string) =>
  createSelector(selectPlayer(id), ({ stats }) =>
    Boolean(stats[StatType.second_yellow]?.value)
  );

export const selectPlayerEvents = (id: string) =>
  createSelector(
    [
      selectPlayerScored(id),
      selectPlayerSubOn(id),
      selectPlayerSubOff(id),
      selectPlayerYCard(id),
      selectPlayer2ndYCard(id),
      selectPlayerDirectRCard(id),
      selectPlayerOwnGoal(id),
    ],
    (scored, subOn, subOff, yCard, secYCard, dirRCard, ownGoal) => ({
      scored,
      subOn,
      subOff,
      yCard,
      secYCard,
      dirRCard,
      ownGoal,
    })
  );

export const selectFixtureContestants = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.matchInfo.contestant || []);

const selectPeriodEvents = <T extends Goal | Booking | Substitution>(
  id: string,
  period: Period,
  periodId: PeriodId,
  variant: EventVariant<T>
) =>
  createSelector(
    selectFixtureContestants(id),
    (state: RootState) => state.fixtures.contestants,
    ([homeId, awayId], contestants) => {
      const hPeriodEvents = getContestantPeriodEvents(
        period,
        periodId,
        variant,
        contestants[homeId]
      );
      const aPeriodEvents = getContestantPeriodEvents(
        period,
        periodId,
        variant,
        contestants[awayId]
      );

      const score =
        variant === "goal"
          ? `${hPeriodEvents.length} - ${aPeriodEvents.length}`
          : undefined;
      return { events: [...hPeriodEvents, ...aPeriodEvents], score };
    }
  );
export const getContestantPeriodEvents = <
  T extends Goal | Booking | Substitution
>(
  period: Period,
  periodId: PeriodId,
  variant: EventVariant<T>,
  contestant: Contestant
) => {
  const events = (contestant[variant] || []) as T[];
  const periodEvents: PeriodEvent<T>[] = events
    .filter(({ period: p }) => p === period || p === periodId)
    .map((event) => ({ ...event, variant, side: contestant.side }));
  return periodEvents;
};

const retrieveSide = (
  teamRef: string,
  ids: [string, string]
): "Home" | "Away" => (teamRef === ids[0] ? "Home" : "Away");

const retrieveTime = (min: number, sec: number) => min + Math.round(sec / 60);

const selectVarData = <T extends VarEventType>(
  fixtureId: string,
  period: Period,
  periodId: PeriodId,
  variant: EventVariant<T>
) =>
  createSelector(selectFixture(fixtureId), ({ varData, contestants }) => {
    return {
      events:
        varData?.varEvent
          .filter(({ period: p }) => p === period)
          .map(({ min, sec, teamRef, ...rest }) => ({
            variant,
            min,
            sec,
            teamRef,
            side: retrieveSide(teamRef, contestants),
            ...rest,
            time: retrieveTime(min, sec),
          })) || [],
    };
  });
export const selectMergedPeriodEvents = (
  id: string,
  period: Period,
  periodId: PeriodId
) =>
  createSelector(
    selectPeriodEvents<Goal>(id, period, periodId, "goal"),
    selectPeriodEvents<Booking>(id, period, periodId, "booking"),
    selectPeriodEvents<Substitution>(id, period, periodId, "substitution"),
    selectVarData<VarEventType>(id, period, periodId, "varEvent"),
    (
      { events: goals, score },
      { events: bookings },
      { events: subs },
      { events: varEvents }
    ) => ({
      score,
      events: [...goals, ...bookings, ...subs, ...varEvents].sort(
        ({ time: t1 }, { time: t2 }) => t1 - t2
      ),
    })
  );
export const selectFixture = (id: string) => (state: RootState) =>
  state.fixtures.byId[id];

export const selectFixturePeriod = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.matchInfo.period);

export const selectFixtureTime = (id: string) =>
  createSelector(selectFixture(id), (fix) => toLocalTime(fix?.matchInfo.time));
export const selectFixtureWeek = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.matchInfo.week);

export const selectFixtureDate = (id: string) =>
  createSelector(selectFixture(id), (fix) => formatDate(fix?.matchInfo.date));

export const selectIsFirstStarted = (id: string) =>
  createSelector(selectFixture(id), (fix) =>
    Boolean(fix?.periods.first_half_start)
  );
export const selectFixPeriodBucket = (id: string) =>
  createSelector(selectFixture(id), (fix): [BucketType?, BucketType?] => {
    switch (fix?.matchInfo.period) {
      case Period.PRE_MATCH:
        return ["PM", "HT"];
      case Period.HALF_TIME:
        return ["PM", "HT"];
      case Period.FIRST_HALF:
        return ["PM", "HT"];
      case Period.SECOND_HALF:
        return ["HT", "FT"];
      case Period.FULL_TIME:
        return ["HT", "FT"];
      default:
        return [];
    }
  });

export const selectFixQuarterBucket = (id: string) =>
  createSelector(selectFixture(id), (fix): [BucketType?, BucketType?] => {
    const matchMin = fix?.matchMin || 0;
    switch (fix?.matchInfo.period) {
      case Period.PRE_MATCH:
        return ["PM", "15"];
      case Period.HALF_TIME:
        return ["30", "HT"];
      case Period.FIRST_HALF:
        return matchMin < 15
          ? ["PM", "15"]
          : matchMin < 30
          ? ["15", "30"]
          : ["30", "HT"];
      case Period.SECOND_HALF:
        return matchMin < 60
          ? ["HT", "60"]
          : matchMin < 75
          ? ["60", "75"]
          : ["75", "FT"];
      case Period.FULL_TIME:
        return ["75", "FT"];
      default:
        return [];
    }
  });

export const selectFixtureBuckets = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.buckets || {});

export const selectMatchOfficial = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.matchOfficial);

export const selectMatchVenue = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.matchInfo.venue);

export const selectSubPlayers = (contId: string) =>
  createSelector(selectContestant(contId), (cont) => cont?.subPlayers || []);

export const selectScore = (id: string) =>
  createSelector(selectContestant(id), (cont) => cont?.goal?.length || 0);

export const selectFhScore = (id: string) =>
  createSelector(
    selectContestant(id),
    (cont) =>
      cont?.goal?.filter((g) => g.period === Period.FIRST_HALF)?.length || 0
  );

export const selectFixtureResult = (id: string) =>
  createSelector(selectFixture(id), (fix) => fix?.matchInfo.result);

export const selectHasMatchStats = (id: string) =>
  createSelector(
    selectFixturePeriod(id),
    selectFixtureResult(id),
    (period, result) => !period || result?.type === "Postponed"
  );

export const selectIsLiveFixture = (id: string) =>
  createSelector(
    selectFixturePeriod(id),
    selectFixtureResult(id),
    (period, result) =>
      !result &&
      !!period &&
      !(
        period === Period.PRE_MATCH ||
        period === Period.FULL_TIME ||
        period === Period.ABANDONED
      )
  );
