import { useHistory } from "react-router-dom";
import { useQuery } from "utils/useQuery";

export const useCalendarId = (): [string | null, (i: string) => void] => {
  const query = useQuery();
  const history = useHistory();
  const calendarId = query.get("trn");
  const setCalendarId = (id: string) => {
    query.set("trn", id.toString());
    history.push({ search: query.toString() });
  };
  return [calendarId, setCalendarId];
};
