import { Box, Link as MuiLink, Typography } from "@material-ui/core";
import { Paths } from "app/routes/Paths";
import { theme } from "app/theme";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";

export const SignUp: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box pb={2}>
        <Typography variant="h6" align="center">
          {t("accounts.create_account")}
        </Typography>
      </Box>
      <SignUpForm />

      <Box
        my={2}
        p={2}
        borderColor={theme.palette.action.focus}
        textAlign="center"
      >
        <Link to={Paths.LOGIN}>
          <MuiLink>{t("accounts.login")}</MuiLink>
        </Link>
      </Box>
    </>
  );
};
