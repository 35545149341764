import { rotateGrid } from "app/interface/formations";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectFixtureRemoteStatus,
  selectFormation,
  selectMatchKit,
} from "../../../../features/fixture/selectors";
import { Pitch } from "./Pitch";
import { Player } from "./Player";
interface FormationContProps {
  reverse?: boolean;
  gridArea?: string[];
}
const FormationCont = styled.div<FormationContProps>`
  padding: 0.5rem;
  position: relative;
  z-index: 5;
  display: grid;
  width: 100%;
  height: 100%;
  transform: ${({ reverse }) => reverse && "rotate(180deg);"};
  grid-template-areas: ${({ gridArea }) =>
    rotateGrid(gridArea).map((row, i) => `"${row}"`)};
`;

interface Props {
  contestantId: string;
  side: "Home" | "Away";
}

export const Formation: FC<Props> = ({ contestantId, side }) => {
  const { involved, gridArea } = useSelector(selectFormation(contestantId));
  const kit = useSelector(selectMatchKit(contestantId));
  const status = useSelector(selectFixtureRemoteStatus);
  return (
    <Pitch side={side}>
      <FormationCont gridArea={gridArea} reverse={side === "Away"}>
        {involved &&
          involved.map((id, i) => (
            <Player
              key={i}
              kitColour={kit}
              id={id}
              reverse={side === "Away"}
              status={status}
            />
          ))}
      </FormationCont>
    </Pitch>
  );
};
