import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { BucketType, PlayerPosition } from "./interfaces";

export const usePlayerAvgStat = (
  id: string,
  period: `${BucketType}-${BucketType}`,
  type: StatType
) => {
  const position = useSelector((s) => s.fixtures.players[id].position);
  const season = useSelector(
    (s) => s.fixtures.players[id].seasonAvg?.[period]?.[type] || "0"
  );
  const count = useSelector(
    (s) =>
      +s.fixtures.players[id].seasonAvg?.[period]?.[StatType.matches_played] ||
      0
  );
  return (type === StatType.goals_conceded &&
    position !== PlayerPosition.GOALKEEPER) ||
    type === StatType.matches_played ||
    count === 0
    ? "0.0"
    : (Number(season) / count).toFixed(1);
};
