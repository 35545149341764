import { flow, keyBy, mapValues } from "lodash/fp";
import { schema } from "normalizr";
import { StatType } from "shared/enums";
import { ContestantDto } from "../interfaces";

const lineupSchema = new schema.Entity(
  "players",
  {},
  {
    idAttribute: "playerRef",
    processStrategy: ({ stat, ...rest }) => {
      const playerStats = flow(keyBy("type"))(stat);
      return { ...rest, stats: playerStats };
    },
  }
);

const contestantSchema = new schema.Entity(
  "contestants",
  { lineup: [lineupSchema] },
  {
    idAttribute: "teamRef",
    processStrategy: (
      {
        stat,
        playerLineUp = { matchPlayer: [], formation: "" },
        teamKits,
        ...rest
      }: ContestantDto,
      parent,
      key
    ) => {
      const { match_time: matchMin } = parent.periods;
      const { matchPlayer: lineup, formation } = playerLineUp;
      const matchStat = flow(keyBy("type"))(stat);
      const startPlayers = lineup
        .filter(({ status }) => status === "Start")
        .map(({ playerRef }) => playerRef);
      const subPlayers = lineup
        .filter(({ status }) => status === "Sub")
        .sort(({ stat: a }, { stat: b }) => b.length || 0 - a.length || 0)
        .map(({ playerRef }) => playerRef);

      return {
        ...rest,
        formation,
        lineup,
        stats: matchStat,
        matchMin,
        startPlayers,
        subPlayers,
      };
    },
  }
);

const playerSchema = new schema.Entity(
  "players",
  {},
  {
    idAttribute: "uId",
    processStrategy: ({ info, ...rest }, parent, key) => ({ ...info, ...rest }),
  }
);

export const teamSchema = new schema.Entity(
  "contestants",
  { player: [playerSchema] },
  {
    idAttribute: "uId",
    processStrategy: ({ teamKits, ...rest }) => {
      const kit = teamKits && keyBy("type")(teamKits.kit);
      return { ...rest, teamKits: kit };
    },
  }
);

const contestantsInfoSchema = new schema.Entity(
  "contestants",
  {},
  { idAttribute: "opId" }
);

const ratingsSchema = new schema.Entity(
  "players",
  {},
  {
    idAttribute: "playerRef",
    processStrategy: ({ value }, parent, key) => {
      const stats = {
        [StatType.avg_ratings]: {
          value: Math.floor(value),
          type: StatType.avg_ratings,
        },
      };
      return { stats };
    },
    mergeStrategy: (playerA, playerB) => {
      const { stats: statsA, ...restA } = playerA;
      const { stats: statsB, ...restB } = playerB;

      return {
        ...restA,
        ...restB,
        stats: { ...statsA, ...statsB },
      };
    },
  }
);
export const teamStandingSchema = new schema.Entity(
  "teamStandings",
  {},
  {
    idAttribute: "participant_id",
  }
);
export const fixtureSchema = new schema.Entity(
  "fixtures",
  {
    matchInfo: { contestant: [contestantsInfoSchema] },
    contestants: [contestantSchema],
    team: [teamSchema],
    ratings: [ratingsSchema],
    squads: [teamSchema],
  },
  {
    idAttribute: "opId",
    processStrategy: ({ stats, matchInfo, buckets, ...rest }, parent, key) => {
      const { periods, matchInfo: info, ...details } = stats || {};
      const periodsMap = flow(keyBy("type"), mapValues("value"))(periods);
      const bucketsMap = flow(keyBy("type"))(buckets);
      return {
        matchInfo: {
          ...info,
          ...matchInfo,
        },
        buckets: bucketsMap,
        ...details,
        periods: periodsMap,
        events: { byId: {}, ids: [] },
        ...rest,
      };
    },
  }
);
