import { Button } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const ToggleShowButton: FC<any> = ({ showAll, toggleHandler }) => {
  const [t] = useTranslation();
  return (
    <Button variant="text" color="primary" onClick={toggleHandler} size="small">
      {!showAll && (
        <>
          <ExpandMoreIcon />
          {t("button.show_more")}
        </>
      )}
      {showAll && (
        <>
          <ExpandLessIcon />
          {t("button.show_less")}
        </>
      )}
    </Button>
  );
};
