import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./rootEpic";
import rootReducer from "./rootReducer";

const middleware = getDefaultMiddleware();
const epicMiddleware = createEpicMiddleware({});
const store = configureStore({
  middleware: [...middleware, epicMiddleware],
  reducer: rootReducer,
});

epicMiddleware.run(rootEpic);

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
