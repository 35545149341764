import { Box, Typography } from "@material-ui/core";
import Icon from "@material-ui/icons/PlaceOutlined";
import { selectMatchVenue } from "features/fixture/selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Props } from "./FixtureInfo";

const VenueIcon = styled(Icon)`
  position: absolute;
  left: -1.25rem;
  font-size: 1rem;
`;
export const Venue: FC<Props> = ({ fixtureId }) => {
  const venue = useSelector(selectMatchVenue(fixtureId));
  return (
    <Box display="flex" alignItems="center" position="relative">
      {venue && (
        <>
          <VenueIcon fontSize="inherit" />
          <Typography variant="caption" noWrap>
            {venue.shortName}
          </Typography>
        </>
      )}
    </Box>
  );
};
