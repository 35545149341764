import { createSelector } from "@reduxjs/toolkit";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { selectContestant } from "./selectContestant";

export const selectContSeasonStat = (id: string, type: Nullable<StatType>) =>
  createSelector(
    selectContestant(id),
    (cont) => `${(type && cont?.seasonalStats?.[type]?.value) || 0}`
  );
