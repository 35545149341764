import { Box, Typography } from "@material-ui/core";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { StatDescTooltip } from "./StatDescTooltip";
import { StatCard, StatValue } from "./Styled";
import { TextSkeleton } from "./TextSkeleton";

export interface MobileTeamStatsViewProps {
  value: [string, string];
  avg: [string, string];
  timeRatio: [string, string];
  sideRatio: [string, string];
  sideRatioTitle?: string | false;
  timeRatioTitle?: string | false;
  avgTitle?: string | null;
  name: string;
  desc: string;
  status: RemoteStatus;
  isSelected: boolean;
  clickHandler: () => void;
}

export const MobileTeamStatView: FC<MobileTeamStatsViewProps> = ({
  value: [home, away],
  avg: [homeAvg, awayAvg],
  timeRatio: [homeTimeRatio, awayTimeRatio],
  sideRatio: [homeSideRatio, awaySideRatio],
  avgTitle,
  sideRatioTitle,
  timeRatioTitle,
  name,
  desc,
  status,
  isSelected,
  clickHandler,
}) => (
  <StatCard
    variant={isSelected ? "outlined" : "elevation"}
    selected={isSelected}
  >
    <Box py={0.5} px={1} onClick={clickHandler}>
      <Typography
        variant="button"
        align="center"
        color="textSecondary"
        component="h5"
        noWrap
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <StatDescTooltip title={desc} />
          <Box mx={0.5} component="span">
            {name}
          </Box>
        </Box>
      </Typography>
      <Box display="flex" justifyContent="space-between" px={1}>
        <StatValue variant="h5" isMin={home < away}>
          <TextSkeleton status={status} text={home || "0"} />
        </StatValue>

        <StatValue variant="h5" isMin={away < home}>
          <TextSkeleton status={status} text={away || "0"} />
        </StatValue>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="button">{homeAvg}</Typography>
        <Typography variant="caption">{avgTitle}</Typography>
        <Typography variant="button">{awayAvg}</Typography>
      </Box>
      {timeRatioTitle && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="button">{homeTimeRatio}</Typography>
          <Typography variant="caption">{timeRatioTitle}</Typography>
          <Typography variant="button">{awayTimeRatio}</Typography>
        </Box>
      )}
      {sideRatioTitle && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="button">{homeSideRatio}</Typography>
          <Typography variant="caption">{sideRatioTitle}</Typography>
          <Typography variant="button">{awaySideRatio}</Typography>
        </Box>
      )}
    </Box>
  </StatCard>
);
