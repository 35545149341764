import { PlayerPosition } from "features/fixture/interfaces";
import React, { FC } from "react";
import styled from "styled-components";
interface Props {
  position: PlayerPosition;
  hidden?: boolean;
}
const Container = styled.div<Pick<Props, "position">>`
  grid-area: ${({ position }) => position};
`;

export const TopPosition: FC<Props> = ({ children, position, hidden }) => (
  <>{!hidden && <Container position={position}>{children}</Container>}</>
);
