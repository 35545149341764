import { Box, Typography } from "@material-ui/core";
import { selectTrnPlayersStats } from "features/calendar/selectors/selectTrnPlayersStats";
import { selectTrnStatsStatus } from "features/calendar/selectors/selectTrnStatsStatus";
import { PlayerPosition } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { FormationCont } from "../Fixture/Pitch/FormationCont";
import { TopPosition } from "../Fixture/Pitch/TopPosition";
import { TrnPitchHeader } from "./TrnPitchHeader";
import { TrnPlayerStat } from "./TrnPlayerStat";
import { TrnPositionTitles } from "./TrnPositionTitles";
interface Props {
  id: string;
  statType: Nullable<StatType>;
}

export const TrnPlayers: FC<Props> = ({ id, statType }) => {
  const [t] = useTranslation();
  const status = useSelector(selectTrnStatsStatus(id));
  const players = useSelector(selectTrnPlayersStats(id, statType));
  const reverse = true;
  return (
    <Box
      position="relative"
      zIndex="5"
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <TrnPitchHeader>
        <Box
          py={1.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{t("headers.tournament_top_players")}</Typography>
        </Box>
        <TrnPositionTitles reverse={reverse} />
      </TrnPitchHeader>
      <FormationCont reverse={reverse}>
        {players &&
          players.map(({ position, playerStats: stats }, i) => (
            <TopPosition
              position={position}
              hidden={
                statType === StatType.goals_conceded &&
                position !== PlayerPosition.GOALKEEPER
              }
            >
              {stats.map(({ id, value }, j) => (
                <TrnPlayerStat
                  key={`${i}${j}`}
                  id={id}
                  reverse={reverse}
                  status={status}
                  stat={value}
                />
              ))}
            </TopPosition>
          ))}
      </FormationCont>
    </Box>
  );
};
