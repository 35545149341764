import { selectFixtureContestants } from "features/fixture/selectors";
import { useSelector } from "react-redux";
import { useDocumentTitle } from "utils/useDocumentTitle";
import { selectContShortName } from "../../../features/fixture/selectContestant";

export const useFixtureTitle = (fixtureId: string) => {
  const [homeId, awayId] = useSelector(selectFixtureContestants(fixtureId));

  const homeName = useSelector(selectContShortName(homeId));
  const awayName = useSelector(selectContShortName(awayId));

  useDocumentTitle(
    (homeName && awayName && `${homeName} vs ${awayName}`) || ""
  );
};
