import {
  Box,
  ButtonBase,
  InputBase,
  styled,
  Typography,
} from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
const EmailInput: FC<FieldProps> = ({ field, form, ...props }) => (
  <Box px={2} py={1} clone>
    <InputBase {...field} {...props} />
  </Box>
);

const SubmitButton = styled(ButtonBase)(({ theme }) => ({
  margin: theme.spacing(0.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderRadius: 24,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
}));
interface Props {
  onSubmit: (email: string) => void;
}
export const EmailSubmitForm: FC<Props> = ({ onSubmit }) => {
  const [t] = useTranslation();
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(values) => onSubmit(values.email)}
    >
      <Form>
        <Box
          display="inline-flex"
          border={1}
          borderRadius={24}
          borderColor="grey.500"
        >
          <Field
            type="email"
            name="email"
            placeholder={t("accounts.enter_email")}
            component={EmailInput}
          />
          <SubmitButton type="submit">
            <Typography variant="button">{t("button.get_started")}</Typography>
          </SubmitButton>
        </Box>
      </Form>
    </Formik>
  );
};
