import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { IndexColumn } from "./IndexColumn";
import SortTrnTeamBtn from "./SortTrnTeamBtn";
import { TrnPitchHeader } from "./TrnPitchHeader";
import { TrnTeamStat } from "./TrnTeamStat";
interface Props {
  id: string;
  statType: Nullable<StatType>;
  status: RemoteStatus;
  desc: boolean;
  setDesc: (t: boolean) => void;
}

const PlaceHolder: FC = () => (
  <>
    {Array.from(Array(5)).map((_, i) => (
      <Box
        height="20%"
        display="flex"
        alignItems="center"
        justifyItems="center"
      >
        <Box px={4} borderRadius={12} width="100%">
          <Skeleton variant="rect" height="1.5rem" width="100%" />
        </Box>
      </Box>
    ))}
  </>
);

export const TrnTeamStats: FC<Props> = ({
  id: calId,
  statType,
  status,
  desc,
  setDesc,
}) => {
  const [t] = useTranslation();
  const stats = useSelector((state) => {
    const stats =
      (statType && state.calendar.byId[calId].stats.contestants[statType]) ||
      [];
    return desc ? stats.slice(0, 5) : stats.slice(-5).reverse();
  });
  return (
    <Box
      position="relative"
      zIndex="5"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <TrnPitchHeader>
        <Box display="flex" alignItems="center" justifyContent="center">
          <SortTrnTeamBtn value={desc} clickHandler={() => setDesc(!desc)} />
          <Typography>
            {t(
              desc
                ? "headers.tournament_top_teams"
                : "headers.tournament_bottom_teams"
            )}
          </Typography>
        </Box>
        <Box display="flex">
          <Box width={40}>
            <Typography align="center" variant="subtitle2">
              #
            </Typography>
          </Box>
          <Box px={2} flexGrow={1}>
            <Box display="flex" px={2}>
              <Box flexGrow={1}>
                <Typography variant="subtitle2">
                  {t("glossary.club")}
                </Typography>
              </Box>
              <Box width="25%">
                <Typography variant="subtitle2" align="center">
                  {t("glossary.stat")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </TrnPitchHeader>
      <Box display="flex" flexGrow={1}>
        <IndexColumn length={stats.length} />
        <Box flexGrow={1}>
          {status === "loading" && <PlaceHolder />}
          {status === "success" &&
            stats.map(({ id, value }, index) => (
              <TrnTeamStat key={index} id={id} value={value} />
            ))}
        </Box>
      </Box>
    </Box>
  );
};
