import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { LocalStorageKeys } from "shared/constants";
import { loadItem } from "shared/local-storage";
import { JWTTokenDto } from "./dto/jwtToken.dto";
import { UserDto } from "./dto/user.dto";
import { JwtPayload, User } from "./types";
const loadInitialState = (): User => {
  const user = loadItem<JwtPayload>(LocalStorageKeys.USER);
  return { ...user, isAuthorized: false } || { isAuthorized: false };
};
const initialState: User = loadInitialState();
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    successSignUp(state, action: PayloadAction<UserDto>) {},
    successLogin(state, action: PayloadAction<JWTTokenDto>) {
      const { accessToken } = action.payload;
      const payload = jwtDecode<JwtPayload>(accessToken);
      state.id = payload.id;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.exp = payload.exp;
      state.iat = payload.iat;
      state.status = payload.status;
      state.role = payload.role;
      state.subscriptionStatus = payload.subscriptionStatus;
      state.isAuthorized = true;
    },
    setUser(state, action: PayloadAction<UserDto>) {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.status = action.payload.status;
      state.role = action.payload.role;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },

    successLogout(state) {
      state.id = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.email = undefined;
      state.exp = undefined;
      state.iat = undefined;
      state.status = undefined;
      state.role = undefined;
      state.subscriptionStatus = undefined;
      state.isAuthorized = false;
    },
    authorize() {},
    accessTokenExpired(state) {},
  },
});

export const {
  successLogin,
  successSignUp,
  successLogout,
  authorize,
  accessTokenExpired,
  setUser,
} = userSlice.actions;
export default userSlice.reducer;
