import { selectContSeasonStat } from "features/fixture/selectContSeasonStat";
import { useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { selectSeasonStatRatio } from "../../../features/fixture/selectContSeasonSideRatio";
import { selectSeasonAvgStat } from "../../../features/fixture/selectSeasonAvgStat";

export const useContSeasonDetails = (id: string, index: Nullable<StatType>) => {
  const stat = useSelector(selectContSeasonStat(id, index));
  const avg = useSelector(selectSeasonAvgStat(id, index));
  const sideRatio = useSelector(selectSeasonStatRatio(id, index, "side"));
  const timeRatio = useSelector(selectSeasonStatRatio(id, index, "time"));
  return { stat, avg, sideRatio, timeRatio };
};
