import { Card, styled, Theme, Typography } from "@material-ui/core";

export const StatValue = styled(Typography)<Theme, { isMin?: boolean }>(
  ({ isMin, theme }) => ({
    fontWeight: "bold",
    color: isMin ? theme.palette.grey[500] : theme.palette.text.primary,
  })
);

export const StatName = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const StatCard = styled(Card)<Theme, { selected?: boolean }>(
  ({ theme, selected }) => ({
    background: selected ? theme.palette.action.selected : "white",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    "&:hover": {
      background: theme.palette.action.hover,
    },
    cursor: "pointer",
  })
);

export const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
});
