import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AvgStatView } from "./AvgStatView";
import { MatchStatViewProps } from "./MatchStatView";

type Props = Pick<
  MatchStatViewProps,
  "side" | "matchAvg" | "periodAvg" | "quarterAvg" | "period" | "quarter"
>;

export const AvgStatsContainer: FC<Props> = (props) => {
  const [t] = useTranslation();
  return (
    <>
      <Typography variant="caption">{t("glossary.average_short")}</Typography>
      <Box
        display="flex"
        justifyContent={props.side === "Away" ? "flex-end" : "flex-start"}
        alignItems="center"
        flexGrow={1}
        px={0.25}
      >
        <AvgStatView
          title={t("glossary.fulltime_short")}
          value={props.matchAvg || 0.0}
          reverse={false}
        />
        <Box px={0.25}>|</Box>
        <AvgStatView
          title={props.period}
          value={props.periodAvg || 0.0}
          reverse={false}
        />
        <Box px={0.25}>|</Box>
        <AvgStatView
          title={props.quarter}
          value={props.quarterAvg || 0.0}
          reverse={false}
        />
      </Box>
    </>
  );
};
