import { Box, BoxProps } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import React, { FC } from "react";
import {
  DownArrowIcon,
  GoalIcon,
  RedCardIcon,
  SecYellowCardIcon,
  UpArrowIcon,
  YellowCardIcon,
} from "../../Calendar/EventIcons";
const BaseContainer: FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    position="absolute"
    display="flex"
    justifyContent="center"
    alignItems="center"
    fontSize="1.5rem"
    {...rest}
  >
    {children}
  </Box>
);
interface Props {
  show: boolean | undefined;
}
export const SubOnIcon: FC<Props> = ({ show }) =>
  (show && (
    <BaseContainer
      bottom="-0.2rem"
      right="-0.6rem"
      border="1px solid gray"
      fontSize="0.5rem"
      bgcolor="white"
      borderRadius="50%"
      width="1rem"
      height="1rem"
    >
      <UpArrowIcon />
    </BaseContainer>
  )) ||
  null;

export const SubOffIcon: FC<Props> = ({ show }) =>
  (show && (
    <BaseContainer
      bottom="-0.2rem"
      right="-0.6rem"
      border="1px solid gray"
      fontSize="0.5rem"
      bgcolor="white"
      borderRadius="50%"
      width="1rem"
      height="1rem"
    >
      <DownArrowIcon />
    </BaseContainer>
  )) ||
  null;

export const ScoredIcon: FC<Props & { isOwnGoal?: boolean }> = ({
  show,
  isOwnGoal,
}) =>
  (show && (
    <BaseContainer
      top="-0.5rem"
      left="-0.6rem"
      bgcolor="transparent"
      fontSize="1.5rem"
      width="1rem"
      height="rem"
    >
      <GoalIcon fill={isOwnGoal ? red[800] : "inherit"} />
    </BaseContainer>
  )) ||
  null;

export const YellowCard: FC<Props> = ({ show }) =>
  (show && (
    <BaseContainer
      bottom="-0.2rem"
      left="-0.6rem"
      bgcolor="transparent"
      fontSize="1rem"
    >
      <YellowCardIcon />
    </BaseContainer>
  )) ||
  null;

export const RedCard: FC<Props> = ({ show }) =>
  (show && (
    <BaseContainer
      bottom="-0.2rem"
      left="-0.6rem"
      bgcolor="transparent"
      fontSize="1rem"
    >
      <RedCardIcon />
    </BaseContainer>
  )) ||
  null;

export const SecYellowCard: FC<Props> = ({ show }) =>
  (show && (
    <BaseContainer
      bottom="-0.2rem"
      left="-0.6rem"
      bgcolor="transparent"
      fontSize="1.5rem"
    >
      <SecYellowCardIcon />
    </BaseContainer>
  )) ||
  null;

export const PlayerAvg: FC<{
  ft?: string;
  period?: string;
  periodName?: string;
}> = ({ ft, period, periodName }) => (
  <>
    {ft && ft !== "0.0" && (
      <BaseContainer
        top={periodName ? "-1.5rem" : "-0.5rem"}
        right="-2.5rem"
        bgcolor="#00000063"
        fontSize="0.75rem"
        color="white"
        fontWeight="bold"
        display="flex"
        px={0.25}
        borderRadius={4}
      >
        {`avg ${ft}`}
        <br />
        {periodName && `${periodName} ${period}`}
      </BaseContainer>
    )}
  </>
);
