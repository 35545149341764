import styled from "styled-components";
interface Props {
  reverse?: boolean;
  gridArea?: string[];
}
export const FormationCont = styled.div<Props>`
  z-index: 5;
  position: relative;
  display: grid;
  height: 100%;
  flex-grow: 1;
  transform: ${({ reverse }) => reverse && "scale(-1,1);"};
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "Goalkeeper Defender Midfielder Forward";
`;
