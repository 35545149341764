import { Box, Hidden, Typography } from "@material-ui/core";
import { getSeasonalStats } from "features/fixture/fixtureSlice";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StatType } from "shared/enums";
import { useStatsViewIndex } from "utils/useStatsViewIndex";
import { StatsViewContext } from "../../../features/fixture/StatsViewContext";
import { Grid } from "../core/Grid";
import { ContestantTopPlayers } from "../Fixture/Pitch/ContestantTopPlayers";
import {
  SeasonalFilterContext,
  useSeasonalFilter,
} from "../seasonal/SeasonalFilterContext";
import { SeasonalPeriodTweak } from "../seasonal/SeasonalPeriodTweak";
import { FilterContainer } from "../trn-stats/FilterContainer";
import { MobileSeasonalStatsListContainer } from "./MobileSeasonalStatsListContainer";
import { MobileStatsWrapper } from "./MobileStatsWrapper";
import { SeasonalStatsListContainer } from "./SeasonalStatsListContainer";
import { SwiperWrapper } from "./SwiperWrapper";

const Title: FC = () => {
  const [t] = useTranslation("stats");
  const { index } = useStatsViewIndex();
  return (
    <Box py={1} bgcolor="#3b893e" color="white">
      <Typography variant="subtitle2" align="center">
        {t(index ?? StatType.matches_played)}
      </Typography>
    </Box>
  );
};
interface Props {
  homeContestantId: string;
  awayContestantId: string;
  seasonId: string;
}
export const SeasonalStats: FC<Props> = ({
  homeContestantId: homeId,
  awayContestantId: awayId,
  seasonId,
}) => {
  const filter = useSeasonalFilter();
  const statsViewCtx = useStatsViewIndex(StatType.matches_played);
  const { bounds, gameLimit, homeSide, awaySide } = filter;
  const [lowerMinute, upperMinute] = bounds;
  const dispatch = useDispatch();
  useEffect(() => {
    if (seasonId)
      dispatch(
        getSeasonalStats({
          teams: [
            { id: homeId, side: homeSide },
            { id: awayId, side: awaySide },
          ],
          seasonId,
          lowerMinute,
          upperMinute,
          gameLimit,
        })
      );
  }, [
    awayId,
    homeId,
    dispatch,
    lowerMinute,
    upperMinute,
    gameLimit,
    seasonId,
    homeSide,
    awaySide,
  ]);
  return (
    <StatsViewContext.Provider value={statsViewCtx}>
      <SeasonalFilterContext.Provider value={filter}>
        <FilterContainer>
          <SeasonalPeriodTweak />
        </FilterContainer>
        <Hidden mdUp>
          <Box>
            <MobileStatsWrapper>
              <MobileSeasonalStatsListContainer
                homeId={homeId}
                awayId={awayId}
              />
            </MobileStatsWrapper>
          </Box>
        </Hidden>
        <Grid container>
          <Grid item md={3}>
            <Hidden mdDown>
              <SeasonalStatsListContainer
                contestantId={homeId}
                opponentId={awayId}
              />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box position={{ md: "sticky" }} top={161}>
              <Title />
              <SwiperWrapper>
                <Box
                  height={{
                    xs: `calc(100vh - 191px)`,
                    md: `calc(100vh - 335px)`,
                  }}
                >
                  <ContestantTopPlayers contestantId={homeId} position="home" />
                </Box>
                <Box
                  height={{
                    xs: `calc(100vh - 191px)`,
                    md: `calc(100vh - 335px)`,
                  }}
                >
                  <ContestantTopPlayers contestantId={awayId} position="away" />
                </Box>
              </SwiperWrapper>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Hidden mdDown>
              <SeasonalStatsListContainer
                contestantId={awayId}
                opponentId={homeId}
              />
            </Hidden>
          </Grid>
        </Grid>
      </SeasonalFilterContext.Provider>
    </StatsViewContext.Provider>
  );
};
