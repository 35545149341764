import { Box, styled, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Ranking } from "../../../features/calendar/standings/Ranking";
import { LoadingFeedback } from "../core/LoadingFeedback";
import { FormView } from "./FormView";
import { RankView } from "./RankView";
import { ToggleShowButton } from "./ToggleShowButton";
const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface Props {
  data: Ranking[];
  showAll: boolean;
  toggleHandler: () => void;
  status: RemoteStatus;
}

const BodyData: FC<Pick<Props, "data">> = ({ data }) => (
  <>
    {data.map((row) => (
      <TableRow key={row.contestantId}>
        <TableCell align="center" component="th" scope="row">
          <RankView rank={row.rank} result={row.result} />
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2">{row.contestantShortName}</Typography>
        </TableCell>
        <TableCell align="center">{row.points}</TableCell>
        <TableCell align="center">{row.matchesPlayed}</TableCell>
        <TableCell align="center">{row.matchesWon}</TableCell>
        <TableCell align="center">{row.matchesLost}</TableCell>
        <TableCell align="center">{row.matchesDrawn}</TableCell>
        <TableCell align="center">{row.goalsFor}</TableCell>
        <TableCell align="center">{row.goalsAgainst}</TableCell>
        <TableCell align="center">{row.goaldifference}</TableCell>
        <TableCell align="center">
          <FormView form={row.lastSix} />
        </TableCell>
      </TableRow>
    ))}
  </>
);
export const StandingTableView: FC<Props> = ({
  data,
  status,
  showAll,
  toggleHandler,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <HeaderCell align="center">{t("standing.position")}</HeaderCell>
              <HeaderCell align="center">{t("standing.club")}</HeaderCell>
              <HeaderCell align="center">{t("standing.points")}</HeaderCell>
              <HeaderCell align="center">{t("standing.played")}</HeaderCell>
              <HeaderCell align="center">{t("standing.won")}</HeaderCell>
              <HeaderCell align="center">{t("standing.lost")}</HeaderCell>
              <HeaderCell align="center">{t("standing.drawn")}</HeaderCell>
              <HeaderCell align="center">{t("standing.GF")}</HeaderCell>
              <HeaderCell align="center">{t("standing.GA")}</HeaderCell>
              <HeaderCell align="center">{t("standing.GD")}</HeaderCell>
              <HeaderCell align="center">{t("standing.form")}</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {status === "success" && <BodyData data={data} />}
          </TableBody>
        </Table>
        {status === "loading" && <LoadingFeedback />}
        {status === "success" && (
          <Box position="sticky" left={0} textAlign="center" py={1}>
            <ToggleShowButton showAll={showAll} toggleHandler={toggleHandler} />
          </Box>
        )}
      </TableContainer>
    </>
  );
};
