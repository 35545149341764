import { fetchCountMatchesByDateEpic } from "features/calendar/fetchCountMatchesByDateEpic";
import { fetchPeriodStatsEpic } from "features/fixture/fetchPeriodStatsEpic";
import { fetchPlayersPlayedEpic } from "features/fixture/fetchPlayersPlayedEpic";
import { fetchSeasonAvgEpic } from "features/fixture/fetchSeasonAvgEpic";
import { socketEpic } from "features/socket/socketEpic";
import { authEpic } from "features/user/authEpic";
import { combineEpics } from "redux-observable";
import { fetchStandingsEpic } from "../features/calendar/fetchStandingsEpic";
import { fetchTrnStatsEpic } from "../features/calendar/trn-stats/fetchTrnStatsEpic";
import { fetchSeasonalStatsEpic } from "../features/fixture/fetchSeasonalStatsEpic";
import { fetchRefereeEpic } from "../features/referees/fetchRefereeEpic";
import { fetchCalendarsEpic } from "features/fixture/fetchCalendarsEpic";

export const rootEpic = combineEpics(
  socketEpic,
  fetchPeriodStatsEpic,
  fetchSeasonalStatsEpic,
  fetchRefereeEpic,
  fetchStandingsEpic,
  fetchTrnStatsEpic,
  fetchCountMatchesByDateEpic,
  fetchPlayersPlayedEpic,
  fetchSeasonAvgEpic,
  authEpic,
  fetchCalendarsEpic
);
