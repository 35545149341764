import {
  selectFixPeriodBucket,
  selectFixQuarterBucket,
} from "features/fixture/selectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const useTimeBucketTitle = (fixtureId: string) => {
  const [t] = useTranslation();
  const [sp] = useSelector(selectFixPeriodBucket(fixtureId));
  const [sq, eq] = useSelector(selectFixQuarterBucket(fixtureId));
  return {
    period:
      sp === "PM"
        ? t("filters.period_PM_HT")
        : sp === "HT"
        ? t("filters.period_HT_FT")
        : "",
    quarter: sq && eq ? t(`filters.period_${sq}_${eq}`) : "",
  };
};
