import React from "react";

export const YellowCardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.29068" width="10.4186" height="16" rx="1" fill="#FFEA00" />
  </svg>
);

export const RedCardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.29068" width="10.4186" height="16" rx="1" fill="#FF1744" />
  </svg>
);

export const SecYellowCardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 511.999 511.999" width="1em" height="1em" {...props}>
    <path
      d="M442.956 207.487l-68.734-35.961-112.665 215.343c-3.931 7.514-13.209 10.42-20.725 6.486L137.779 339.44l-31.498 60.202c-3.931 7.517-1.025 16.795 6.49 20.725l171.787 89.877c7.516 3.933 16.796 1.028 20.726-6.489L449.446 228.21c3.93-7.511 1.026-16.791-6.49-20.723z"
      data-original="#EA473B"
      fill="#ea473b"
    />
    <path
      d="M442.956 207.487l-68.734-35.961-112.665 215.343c-3.931 7.514-13.209 10.42-20.725 6.486l-30.674-16.047c23.166 45.99 59.327 84.304 103.673 110.119l135.616-259.215c3.929-7.513 1.025-16.793-6.491-20.725z"
      data-original="#F95450"
      className="prefix__active-path"
      data-old_color="#F95450"
      fill="#ea473b"
    />
    <path
      d="M261.557 387.902c-3.931 7.514-13.209 10.42-20.725 6.487L69.043 304.513c-7.515-3.932-10.421-13.212-6.489-20.726L206.712 8.24c3.931-7.513 13.214-10.42 20.725-6.487l171.79 89.877c7.516 3.931 10.42 13.211 6.488 20.725L261.557 387.902z"
      data-original="#F7AC16"
      data-old_color="#F7AC16"
      fill="#fabd25"
    />
    <path
      d="M181.963 258.276c0 43.366 10.42 84.296 28.887 120.429l29.981 15.685c7.517 3.933 16.795 1.028 20.725-6.487l144.161-275.546c3.932-7.515 1.028-16.795-6.488-20.725L298.355 38.855c-70.238 47.656-116.392 128.146-116.392 219.421z"
      data-original="#F4BD25"
      data-old_color="#F4BD25"
      fill="#fabd25"
    />
  </svg>
);

export const GoalIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height="1em" viewBox="0 0 480 480" width="1em" {...props}>
    <path
      d="M472 240c0 128.129-103.871 232-232 232S8 368.129 8 240 111.871 8 240 8s232 103.871 232 232zm0 0"
      fill="#fff"
    />
    <g>
      <path d="M416 192l16-80 32 72 8 72zm0 0M64 192l-16-80-32 72-8 72zm0 0M48 368l88 16 48 80-80-32zm0 0M432 368l-88 16-48 80 80-32zm0 0M152 24l88 40 88-40-88-24zm0 0M160 224l80-64 80 64-24 96H184zm0 0" />
    </g>
    <path d="M240 0C107.453 0 0 107.453 0 240s107.453 240 240 240 240-107.453 240-240C479.852 107.516 372.484.148 240 0zm185.863 115.121l-16.633 70.703-87.628 29.207L248 156.161V69.413l84-33.598a225.356 225.356 0 0193.863 79.305zm1.336 247.64l-79.336 12.528-43.199-57.066 22.008-88 86.656-28.88 49.64 59.567A222.537 222.537 0 01427.2 362.762zm-294.71 12.583L52.8 362.762a222.571 222.571 0 01-35.762-101.875l49.602-59.567 86.656 28.88 22.008 88.046zM16.07 237.016a222.554 222.554 0 0126.25-102.23l12.977 55.198zM190.246 312l-21.223-84.977L240 170.246l70.992 56.8L289.754 312zm234.457-122.047l12.977-55.2a222.541 222.541 0 0126.25 102.231zM310.183 27.32L240 55.39l-70.559-27.94a223.18 223.18 0 01140.743-.13zM147.618 36L232 69.441v86.72l-73.602 58.87-87.628-29.207-16.633-70.703A225.403 225.403 0 01147.617 36zM66.168 381.07l65.031 10.274 39.328 61.601A224.737 224.737 0 0166.168 381.07zm127.297 78.035l-47.738-74.738L188 328h104l42.879 56.64-41.598 72.903a223.307 223.307 0 01-99.808 1.602zm122.039-8.234l34.098-59.672 64.261-10.137a224.864 224.864 0 01-98.36 69.81zm0 0" />
  </svg>
);

export const UpArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512.008 512.008" width="1em" height="1em" {...props}>
    <path
      d="M263.556 3.123c-4.165-4.164-10.917-4.164-15.083 0L45.807 205.79c-21.838 21.838-21.838 57.245 0 79.083s57.245 21.838 79.083 0l77.781-77.781v251.584c0 29.455 23.878 53.333 53.333 53.333s53.333-23.878 53.333-53.333V207.091l77.781 77.781c21.838 21.838 57.245 21.838 79.083 0s21.838-57.245 0-79.083L263.556 3.123z"
      data-original="#455A64"
      className="prefix__active-path"
      data-old_color="#455A64"
      fill="#00AF80"
    />
  </svg>
);

export const DownArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 511.987 511.987" width="1em" height="1em" {...props}>
    <path
      d="M387.098 227.115l-77.781 77.803V53.333C309.316 23.878 285.438 0 255.983 0S202.65 23.878 202.65 53.333v251.584l-77.781-77.803c-21.838-21.838-57.245-21.838-79.083 0s-21.838 57.245 0 79.083l202.667 202.667c4.165 4.164 10.917 4.164 15.083 0l202.667-202.667c21.838-21.838 21.838-57.245 0-79.083s-57.245-21.838-79.083 0l-.022.001z"
      data-original="#455A64"
      className="prefix__active-path"
      data-old_color="#455A64"
      fill="#EA473B"
    />
  </svg>
);

export const RefereeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2612 16.2824C15.2613 8.3802 19.4637 6.00011 25.0667 6C30.6697 6.00011 34.7387 8.3802 34.7388 16.2824M15.328 34.0603C16.5961 32.9257 18.0911 32.1443 19.7039 31.7835C19.8536 31.75 20.0043 31.7202 20.1559 31.694M15.328 34.0603C12.9007 36.2321 10.5 39.6983 10.5 43.9861H15.328M15.328 34.0603V43.9861M22.9723 31.5567L21.7653 31.5565C21.2201 31.5565 20.6824 31.6031 20.1559 31.694M24.9839 43.9861H20.1559M15.328 43.9861H20.1559M20.1559 43.9861V31.694M22.9723 29.844C23.3991 29.9765 23.8319 30.0493 24.2646 30.0493H25.7033C26.1425 30.0493 26.5817 29.9743 27.0148 29.838M22.9723 29.844C19.789 28.8563 16.9373 24.5535 16.9373 22.426V16.0859C16.9373 16.0859 16.9373 13.6 20.1559 13.6C23.3746 13.6 23.3746 16.0859 23.3746 16.0859C23.3746 18.5719 26.5932 18.5719 26.5932 16.0859C26.5932 16.0859 26.5932 13.6 29.8119 13.6C33.0305 13.6 33.0305 16.0859 33.0305 16.0859V22.426C33.0305 24.5492 30.1903 28.839 27.0148 29.838M22.9723 29.844V31.5747M27.0148 29.838L27.0277 31.5672M28.2347 31.5704L27.0277 31.5706M28.2347 31.5704L27.0277 31.5672M28.2347 31.5704C28.7799 31.5704 29.3176 31.6169 29.8441 31.7078M34.672 34.0742C33.4039 32.9395 31.9089 32.1581 30.2961 31.7974C30.1464 31.7639 29.9957 31.734 29.8441 31.7078M34.672 34.0742C37.0993 36.246 39.5 39.7121 39.5 44H34.672M34.672 34.0742V44M22.9852 31.5567C22.9852 31.5616 22.9852 31.5665 22.9852 31.5714C22.9851 32.051 22.9723 32.9528 22.9723 32.9528C22.9354 35.5477 22.9096 35.1401 24.2838 35.1401L25.0065 35.1454M27.0277 31.5706V31.5672M27.0277 31.5706C27.0278 32.0542 27.0407 32.9633 27.0407 32.9633C27.0776 35.5582 27.1033 35.1506 25.7292 35.1506L25.0065 35.1454M25.0065 35.1454L25.0161 44H29.8441M34.672 44H29.8441M29.8441 44V31.7078M16.9925 21.2C15.0801 21.2 13.5299 21.2 13.5299 18.7412C13.5299 16.3323 15.1339 16.2833 16.9925 16.2824V21.2ZM33.0075 16.2824C35.1589 16.2824 36.903 16.2824 36.903 18.7412C36.903 21.1501 35.0985 21.199 33.0075 21.2V16.2824Z"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export const UpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 78 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.2022 15.453L16 38.6553L62.4045 38.6553L39.2022 15.453Z"
      stroke="#6ABF69"
      strokeWidth="30.7033"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 78 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.2022 39.2023L62.4045 16L16 16L39.2022 39.2023Z"
      stroke="#FF6659"
      strokeWidth="30.7033"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export const BulletIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 47 47"
    fill="#e9e9e9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="23.5" cy="23.5" r="23.5" fill="inherit" />
  </svg>
);

export const SortAscIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.00024 6L9.00024 6L9.00024 8L3.00024 8L3.00024 6ZM3.00024 18L3.00024 16L21.0002 16L21.0002 18L3.00024 18ZM3.00024 11L15.0002 11L15.0002 13L3.00024 13L3.00024 11Z"
      fill="white"
    />
  </svg>
);
export const SortDescIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z" fill="white" />
  </svg>
);

export const VarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.93574 15L9.35574 8H7.91574L7.36574 11.24L7.02574 13.34H6.96574L6.62574 11.24L6.07574 8H4.63574L6.05574 15H7.93574Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5596 13.89L12.7696 15H14.1096L12.8296 8H10.7596L9.40957 15H10.7696L10.9796 13.89H12.5596ZM11.3596 11.63L11.7496 9.3H11.7696L12.1596 11.63L12.3896 12.82H11.1396L11.3596 11.63Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6519 8H14.9919V15H16.3419V12.45H16.8719L17.7219 15H19.1219L18.1119 12.13C18.8186 11.71 19.1719 11.0533 19.1719 10.16C19.1719 9.61333 19.0219 9.15333 18.7219 8.78C18.4286 8.4 18.0719 8.14 17.6519 8ZM16.3419 11.33V9.2H17.2619C17.3752 9.22667 17.4886 9.34333 17.6019 9.55C17.7219 9.75667 17.7819 9.99667 17.7819 10.27C17.7819 10.5433 17.7119 10.7833 17.5719 10.99C17.4386 11.19 17.3152 11.3033 17.2019 11.33H16.3419Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18H6C6 18.5523 6.44772 19 7 19H17C17.5523 19 18 18.5523 18 18H21C21.5523 18 22 17.5523 22 17V6C22 5.44772 21.5523 5 21 5H3C2.44772 5 2 5.44772 2 6V17C2 17.5523 2.44772 18 3 18ZM21 6H3V17H21V6Z"
      fill="black"
    />
  </svg>
);
