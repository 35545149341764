import {
  selectFhScore,
  selectFixtureContestants,
  selectIsFirstStarted,
  selectScore,
} from "features/fixture/selectors";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Score } from "./Score";

interface Props {
  fixtureId: string;
  variant?: "default" | "minimal";
}
export const ScoreCont: FC<Props> = ({ fixtureId, variant = "default" }) => {
  const firstHalfStart = useSelector(selectIsFirstStarted(fixtureId));
  const [homeId, awayId] = useSelector(selectFixtureContestants(fixtureId));
  const homeScore = useSelector(selectScore(homeId));
  const awayScore = useSelector(selectScore(awayId));
  const fhHomeScore = useSelector(selectFhScore(homeId));
  const fhAwayScore = useSelector(selectFhScore(awayId));
  return (
    <Score
      homeScore={homeScore}
      fhHomeScore={fhHomeScore}
      awayScore={awayScore}
      fhAwayScore={fhAwayScore}
      firstHalfStart={firstHalfStart}
      variant={variant}
      fixtureId={fixtureId}
      isLoading={!homeId && !awayId}
    />
  );
};
