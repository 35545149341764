import { Box, styled, Typography } from "@material-ui/core";
import { submitSignUp } from "app/API";
import Appbar from "app/components/appbar/Appbar";
import { Grid } from "app/components/core/Grid";
import { Paths } from "app/routes/Paths";
import { setUser } from "features/user/userSlice";
import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dataImg from "../../../assets/images/data.jpeg";
import failedImg from "../../../assets/images/failed.svg";
import happyImg from "../../../assets/images/happy.jpeg";
import matchStatsScreen from "../../../assets/images/match-stats-screen.png";
import pitchImg from "../../../assets/images/pitch.jpeg";
import succussImg from "../../../assets/images/succuss.png";
import teamStatsScreen from "../../../assets/images/team-stats-screen.png";
import { Comparison } from "./Comparison";
import { EmailSubmitForm } from "./EmailSubmitForm";
import { FeatureTitle } from "./FeatureTitle";
import { Polygon } from "./Polygon";

const Headline = styled(Typography)({
  fontSize: `min(10vw, 3rem)`,
  fontWeight: 700,
  lineHeight: 1.4,
});

const Statistigoal = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  color: theme.palette.primary.main,
}));
const EmailSubmit = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const onSubmit = async (email: string) => {
    const { data } = await submitSignUp({ email });
    dispatch(setUser(data));
    push(Paths.APP);
  };
  return <EmailSubmitForm onSubmit={onSubmit} />;
};
const Hero = () => {
  const [t] = useTranslation("intro");
  return (
    <Grid id="s0" container px={1} height="inherit">
      <Grid item md={1} />
      <Grid item md={5} xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          p={1}
        >
          <Box>
            <Headline variant="h3">{t("you_like_football")}</Headline>
            <Headline variant="h3">{t("you_like_to_bet")}</Headline>
            <Headline variant="h3">
              <Trans i18nKey={"you_love_statistigoal"}>
                {"You'll love"}
                <Statistigoal variant="inherit">
                  {" STATISTIGOAL "}
                </Statistigoal>
                {/* <Box fontStyle="italic" color="primary.main" clone></Box> */}
              </Trans>
            </Headline>
          </Box>
          <Box my={4}>
            <Box clone>
              <Typography color="initial" variant="h6">
                {t("start_trial")}
              </Typography>
            </Box>
            <Box py={1}>
              <EmailSubmit />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={5}>
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="grid"
            gridTemplateColumns="repeat(7, 1fr)"
            gridTemplateRows="repeat(6, 1fr)"
            width="80%"
          >
            <Box
              gridColumn="2 / span 5"
              gridRow="1 / span 2"
              style={{
                background: `url(${pitchImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              borderRadius={4}
            />
            <Box
              gridRow="5 / span 2"
              gridColumn="1 / span 3"
              style={{
                background: `url(${happyImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              borderRadius={4}
            />
            <Box
              gridRow="5 / span 2"
              gridColumn="5 / span 3"
              style={{
                background: `url(${dataImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              borderRadius={4}
            />
            <Box
              gridRow="3 / span 2"
              gridColumn="3 / span 3"
              display="flex"
              alignItems="center"
            >
              <Polygon />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={1} />
    </Grid>
  );
};

const Features = () => {
  const [index, setIndex] = React.useState(0);
  const [t] = useTranslation("intro");
  const features = t("features", {
    returnObjects: true,
  }) as Array<{
    title: string;
    subtitle: string;
  }>;
  return (
    <Box
      id="s1"
      bgcolor="primary.main"
      color="common.white"
      display="flex"
      alignItems={["stretch", "center"]}
    >
      <Grid container py={4}>
        <Grid item md={1} />
        <Grid item md={5} xs={12}>
          <Box ml="3rem">
            <Headline>{t("how_it_works")}</Headline>
          </Box>
          <Box>
            {features.map((f, i) => (
              <FeatureTitle
                key={i.toString()}
                index={i + 1}
                title={f.title}
                subtitle={f.subtitle}
                isActive={index === i}
                onClick={() => setIndex(i)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box>
            <img
              src={index === 0 ? teamStatsScreen : matchStatsScreen}
              alt="match-stats-screen"
              width="100%"
            />
          </Box>
        </Grid>
        <Grid item md={1} />
      </Grid>
    </Box>
  );
};

const ComparisonTable = () => {
  const [t] = useTranslation("intro");
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Box py={4} clone>
        <Headline variant="h3" align="center">
          {t("bet_better")}
        </Headline>
      </Box>

      <Box
        id="s2"
        display="flex"
        alignItems="center"
        position="relative"
        mx={1}
      >
        <Grid container textAlign="center">
          <Grid
            item
            md={3}
            style={{
              background: `url(${failedImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            md={6}
            xs={12}
            border={1}
            borderRadius={8}
            borderColor="grey.400"
          >
            <Grid container p={1} alignItems="center">
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("without_statistigoal")}
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t("with_statistigoal")}
                </Typography>
              </Grid>
            </Grid>
            <Comparison title={t("part_of_affect")} success={80} error={40} />
            <Comparison title={t("part_of_luck")} success={70} error={30} />
            <Comparison title={t("part_of_analysis")} success={30} error={80} />
            <Comparison
              title={t("chance_of_winning")}
              success={35}
              error={65}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={{
              background: `url(${succussImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
      </Box>
    </Box>
  );
};
export const IntroPage: FC = () => {
  return (
    <>
      <Appbar />
      <Box my={4}>
        <Hero />
      </Box>
      <Box my={2}>
        <Features />
      </Box>
      <Box mt={2} mb={8}>
        <ComparisonTable />
      </Box>
    </>
  );
};
