import { useHistory } from "react-router";
import { useQuery } from "utils/useQuery";

export const useQueryState = <S>(key: string, init?: S) => {
  const history = useHistory();
  const query = useQuery();

  const value = query.get(key) || JSON.stringify(init);
  const state: S = value ? JSON.parse(value) : undefined;
  const setState = (newState: S) => {
    if (newState === undefined || null) {
      query.delete(key);
    } else {
      query.set(key, JSON.stringify(newState));
    }
    history.replace({ search: query.toString() });
  };
  return [state, setState] as [S, (s: S) => void];
};
