import Typography from "@material-ui/core/Typography";
import invert from "invert-color";
import styled from "styled-components";

export const StatContainer = styled.div<{ bgColour?: string }>`
  width: 2rem;
  height: 2rem;
  color: ${({ bgColour }) => bgColour && invert(bgColour, true)};
  background-color: ${({ bgColour }) => bgColour};
  border: 1px solid;
  border-color: ${({ bgColour }) => bgColour && invert(bgColour, true)};
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PlayerName = styled(Typography)`
  min-height: 2rem;
  color: #180404;
  font-size: 0.7rem;
  font-weight: 900;
`;
export const JerseyNum = styled(Typography)`
  font-weight: 500;
`;
export const PlayerCont = styled.div<any>`
  grid-area: ${({ post }) => post};
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: ${({ reverse }) => reverse && "rotate(180deg)"};
  ${StatContainer}, ${PlayerName} {
    opacity: ${({ hasStat }) => !hasStat && 0.5};
  }
  ${PlayerName} {
    color: white;
  }
`;
export const TopPlayerCont = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: ${({ reverse }) => reverse && "scale(-1,1)"};
  padding-left: 0;
  padding-right: 0;
  min-width: 25%;
  height: 20%;
  ${PlayerName} {
    color: white;
  }
`;
export const SubPlayerCont = styled.div<{ hasStat: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
  min-width: 6rem;
  ${StatContainer}, ${PlayerName} {
    opacity: ${({ hasStat }) => !hasStat && 0.4};
  }
  ${PlayerName} {
    white-space: nowrap;
    min-height: auto !important;
  }
`;
