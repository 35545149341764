import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { FC } from "react";

export const FailedMessage: FC<{ error: string }> = ({ error }) => (
  <>
    {error && (
      <Box mb={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )}
  </>
);