import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

interface Props {
  title: string;
  value: number;
  reverse: boolean;
}

export const AvgStatView: FC<Props> = ({ title, value, reverse }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection={reverse ? "row-reverse" : "row"}
    px={0.25}
  >
    <Typography variant="caption" color="textSecondary" noWrap>
      {title}
    </Typography>
    <Box px={0.25} />
    <Typography variant="button" color="textPrimary">
      {value}
    </Typography>
  </Box>
);
