import { Box } from "@material-ui/core";
import { fetchStandings } from "features/calendar/calendarSlice";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StandingFilterContext,
  StandingFormat,
  StandingSide,
} from "./StandingFilterContext";
import { StandingTableView } from "./StandingTableView";

interface Props {
  calId: string;
  teamIds: string[];
}
export const StandingTable: FC<Props> = ({ calId, teamIds }) => {
  const [format, setFormat] = useState<StandingFormat>("");
  const [side, setSide] = useState<StandingSide>("total");
  const [showAll, setShowAll] = useState(false);
  const toggleHandler = () => {
    setShowAll(!showAll);
  };
  const dispatch = useDispatch();

  const { status, rankings } = useSelector((state) => {
    const { status = "unknown", rankings = [] } =
      state.calendar.byId[calId]?.standings || {};
    const filtered =
      !showAll &&
      rankings.filter(({ contestantId }) => teamIds.includes(contestantId));
    return { status, rankings: filtered || rankings };
  });
  React.useEffect(() => {
    dispatch(fetchStandings({ calId }));
  }, [calId, dispatch, format, side]);

  return (
    <StandingFilterContext.Provider
      value={{ format, side, setFormat, setSide }}
    >
      <Box px={2} my={2}>
        <StandingTableView
          data={rankings}
          status={status}
          showAll={showAll}
          toggleHandler={toggleHandler}
        />
      </Box>
    </StandingFilterContext.Provider>
  );
};
