import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PublicIcon from "@material-ui/icons/Public";
import { Country } from "features/calendar/interfaces";
import { FC, useState } from "react";
import { CalendarItem } from "./CalendarItem";
import { useCountryCalendars } from "./useCountryCalendars";

export const CountryItem: FC<Country> = ({ countryId, name, image_path }) => {
  const [open, setOpen] = useState(false);
  const calendarIds = useCountryCalendars(countryId);

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          {image_path ? (
            <img src={image_path} width="24px" alt={name} />
          ) : (
            <PublicIcon />
          )}
        </ListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {calendarIds.map((id) => (
            <CalendarItem id={id} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
