import { Box, Button, Typography } from "@material-ui/core";
import { LockRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Paths } from "app/routes/Paths";
import { selectContestant } from "features/fixture/selectContestant";
import { selectFixtureTrn } from "features/fixture/selectFixtureTrn";
import { selectFixtureContestants } from "features/fixture/selectors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { BlurEffect } from "../core/BlurEffect";
import { Grid } from "../core/Grid";
import { StandingTable } from "../standings/StandingTable";
import { MatchStats } from "../Stats/MatchStats";
import { SeasonalStats } from "../Stats/SeasonalStats";
import { TrnStats } from "../trn-stats/TrnStats";
import { Pitch } from "./Pitch/Pitch";
import { useIsUserSubscribed } from "./useIsUserSubscribed";

interface Props {
  fixtureId: string;
}
const VerifyAlert: FC = () => {
  const [t] = useTranslation();
  return (
    <Grid container>
      <Grid item md={3} />
      <Grid item xs={12} md={6}>
        <Box position="relative">
          <BlurEffect position="relative">
            <Box display="flex">
              <Pitch>
                <Box minHeight={500}></Box>
              </Pitch>
              <Pitch side="Away">
                <Box minHeight={500}></Box>
              </Pitch>
            </Box>
          </BlurEffect>
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box zIndex={1000}>
              <Alert
                severity="warning"
                icon={<LockRounded />}
                variant="standard"
                action={
                  <Button href={Paths.CHECKOUT} color="secondary">
                    subscribe
                  </Button>
                }
              >
                <Typography variant="subtitle1">
                  {t(`headers.ask_to_verify`)}
                </Typography>
              </Alert>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={3} />
    </Grid>
  );
};
export const FixtureRoutes = React.forwardRef<HTMLElement | undefined, Props>(
  ({ fixtureId }, ref) => {
    const isSubscribed = useIsUserSubscribed();
    const trn = useSelector(selectFixtureTrn(fixtureId));
    const [homeId, awayId] = useSelector(selectFixtureContestants(fixtureId));
    const home = useSelector(selectContestant(homeId));
    const away = useSelector(selectContestant(awayId));

    return (
      <Switch>
        <Route path={`${Paths.FIXTURES}/:id/match_stats`}>
          <MatchStats
            homeContestantId={homeId}
            awayContestantId={awayId}
            fixtureId={fixtureId}
          />
        </Route>
        <Route path={`${Paths.FIXTURES}/:id/team_stats`}>
          {isSubscribed && (
            <SeasonalStats
              seasonId={trn?.id}
              homeContestantId={homeId}
              awayContestantId={awayId}
            />
          )}
          {!isSubscribed && <VerifyAlert />}
        </Route>
        <Route path={`${Paths.FIXTURES}/:id/league_stats`}>
          {trn && isSubscribed && <TrnStats id={trn.id} />}
          {!isSubscribed && <VerifyAlert />}
        </Route>
        <Route path={`${Paths.FIXTURES}/:id/standing`}>
          {trn && home && away && (
            <StandingTable calId={trn?.id} teamIds={[home.id, away.id]} />
          )}
        </Route>
      </Switch>
    );
  }
);
