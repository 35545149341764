import { flow, keyBy } from "lodash/fp";
import { schema } from "normalizr";

const contestantSchema = new schema.Entity<any>(
  "contestants",
  {},
  {
    processStrategy: (value, parent, key) => {
      const {
        stats: statArray,
        players,
        all_side_stats,
        full_time_stats,
        ...rest
      } = value;
      const matchStat = flow(keyBy("type"))(statArray);
      const all_side_obj = flow(keyBy("type"))(all_side_stats);
      const full_time_obj = flow(keyBy("type"))(full_time_stats);
      const topPlayers = players.map((p: any) => ({
        id: p.id,
        stats: Object.fromEntries(p.stats.map((s: any) => [s.type, s.value])),
      }));
      return {
        stats: matchStat,
        all_side_stats: all_side_obj,
        full_time_stats: full_time_obj,
        players: topPlayers,
        ...rest,
      };
    },
  }
);

export const seasonalStatsSchema = [contestantSchema];
