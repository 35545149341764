import { MatchPlayer } from "features/fixture/interfaces";
import { StatType } from "shared/enums";
export const avgRatings = (
  lineup: string[],
  players: { [key: string]: MatchPlayer },
  minute?: number
) => {
  const valuePath = minute || "value";

  const avgRatings = Object.values(players)
    .filter((p) => lineup.includes(p.playerRef) && p.stats[StatType.avg_ratings])
    .map(({ stats }) => stats[StatType.avg_ratings][valuePath])
    .filter((rating) => Boolean(rating))
    .reduce((avg, ratings, i, { length }) => avg + ratings / length, 0);

  return {
    [valuePath]: Number(avgRatings.toFixed(1)),
    type: StatType.avg_ratings,
  };
};
