import { Box } from "@material-ui/core";
import { RemoteStatus, StatDto } from "features/fixture/interfaces";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { StatItem } from "../../interface/StatItem";
import { MatchStatView } from "./MatchStatView";
import { List } from "./Styled";

interface Props {
  side?: "Home" | "Away";
  index: Nullable<StatType>;
  setIndex: (i: Nullable<StatType>) => void;
  stats?: { [key: string]: StatDto };
  oppStats?: { [key: string]: StatDto };
  status: RemoteStatus;
  items: StatItem[];
  detailsComponent?: React.ReactNode;
  matchAvg: any;
  quarterAvg: any;
  periodAvg: any;
  period: string;
  quarter: string;
}

export const MatchStatsList: FC<Props> = ({
  stats = {},
  oppStats = {},
  items,
  index,
  setIndex,
  side,
  matchAvg = {},
  periodAvg = {},
  quarterAvg = {},
  ...rest
}) => {
  const [tName] = useTranslation("stats");
  const [tDesc] = useTranslation("statsDesc");
  const view = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    view.current?.scrollIntoView({ block: "center" });
  }, []);
  return (
    <Box px={1} position="relative">
      <List>
        {items.map(({ statType }) => (
          <MatchStatView
            key={statType}
            ref={index === statType ? view : undefined}
            statType={statType}
            statName={tName(statType)}
            statDesc={tDesc(statType)}
            stat={stats[statType]?.value}
            matchAvg={matchAvg[statType]}
            periodAvg={periodAvg[statType]}
            quarterAvg={quarterAvg[statType]}
            isMin={oppStats[statType]?.value > stats[statType]?.value}
            clickHandler={() =>
              statType === index ? setIndex(null) : setIndex(statType)
            }
            isSelected={statType === index}
            side={side}
            {...rest}
          />
        ))}
      </List>
    </Box>
  );
};
