import { StatDto, StatsMap } from "features/fixture/interfaces";
import { mapValues } from "lodash/fp";
import { StatType } from "shared/enums";
const getPossessionValue = (
  upValue: number,
  loValue: number,
  upMin: number,
  lowMin: number
) => {
  const avg = (upValue * upMin - loValue * lowMin) / (upMin - lowMin);
  if (avg > 100) return 100;
  if (avg < 0) return 0;
  return Number(avg.toFixed(1));
};

export const filterValue = (
  [lowStats, upStats]: [StatsMap, StatsMap],
  [lowMin, upMin]: [number, number]
) => {
  return mapValues<StatDto, StatDto>((stat) => {
    if (!stat) return stat;
    const { value: upValue, type } = stat;
    const loValue = lowStats[type]?.value || 0;
    if (type === StatType.possession_percentage) {
      return {
        type,
        value: getPossessionValue(upValue, loValue, upMin, lowMin),
      };
    }

    return {
      type,
      value: Math.max(upValue - loValue, 0),
    };
  })(upStats);
};
