import { Box } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";
import { BucketType } from "features/fixture/interfaces";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../core/FilterButton";
import { GameLimitTweak } from "../seasonal/GameLimitTweak";
import { TrnFilterContext } from "./TrnFilterContext";
import { TrnSideTweak } from "./TrnSideTweak";

export const TrnPeriodTweak = () => {
  const [t] = useTranslation();
  const { bounds, setBounds, gameLimit, setGameLimit } =
    useContext(TrnFilterContext);
  const [lowerMinute, upperMinute] = bounds;
  const filter = `${lowerMinute}-${upperMinute}`;
  const onChange:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void)
    | undefined = (e, value: string) => {
    if (value === null) {
      setBounds(["PM", "FT"]);
      return;
    }
    const newBounds = value.split("-") as [BucketType, BucketType];
    setBounds(newBounds);
  };
  return (
    <>
      <Box px={1}>
        <TrnSideTweak />
      </Box>
      <Box px={1}>
        <GameLimitTweak
          gameLimit={gameLimit}
          setGameLimit={setGameLimit}
          options={["3", "5", "10"]}
        />
      </Box>
      <Box px={1}>
        <ToggleButtonGroup
          size="small"
          exclusive
          value={filter}
          onChange={onChange}
        >
          <FilterButton name={t("filters.period_match")!} value={"PM-FT"} />
          <FilterButton name={t("filters.period_PM_HT")!} value="PM-HT" />
          <FilterButton name={t("filters.period_HT_FT")!} value="HT-FT" />
        </ToggleButtonGroup>
      </Box>
      <Box px={1}>
        <ToggleButtonGroup
          size="small"
          exclusive
          value={filter}
          onChange={onChange}
        >
          <FilterButton name={t("filters.period_PM_15")!} value="PM-15" />
          <FilterButton name={t("filters.period_15_30")!} value="15-30" />
          <FilterButton name={t("filters.period_30_HT")!} value="30-HT" />
          <FilterButton name={t("filters.period_HT_60")!} value="HT-60" />
          <FilterButton name={t("filters.period_60_75")!} value="60-75" />
          <FilterButton name={t("filters.period_75_FT")!} value="75-FT" />
        </ToggleButtonGroup>
      </Box>
    </>
  );
};
