import { Box, Link as MuiLink } from "@material-ui/core";
import { Paths } from "app/routes/Paths";
import { theme } from "app/theme";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid } from "../core/Grid";
import { LoginForm } from "./LoginForm";

export const Login: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <LoginForm />
      <Box
        my={2}
        py={1}
        borderColor={theme.palette.action.focus}
        display="flex"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid item xs textAlign="right">
            <Link to={Paths.FORGET_PASS}>
              <MuiLink component="span">
                {t("accounts.forget_password")}
              </MuiLink>
            </Link>
          </Grid>
          <Grid item xs={"auto"}>
            {"○"}
          </Grid>
          <Grid item xs>
            <Link to={Paths.SIGN_UP}>
              <MuiLink component="span">{t("accounts.create_account")}</MuiLink>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
