import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RemoteStatus } from "features/fixture/interfaces";

interface SocketStatus {
  serverStatus:
    | "unknown"
    | "reconnecting"
    | "connecting"
    | "connected"
    | "disconnected";
  initFixtureStatus: RemoteStatus;
  getCalendarStatus: RemoteStatus;
  updateCalendarStatus: RemoteStatus;
}
const initialState: SocketStatus = {
  serverStatus: "unknown",
  initFixtureStatus: "unknown",
  getCalendarStatus: "unknown",
  updateCalendarStatus: "unknown",
};

interface SubscribeMsg {
  namespace: string;
  initialMessage: any;
}
interface EmitGetCalendar {
  id: string;
}
const socketSlice = createSlice({
  name: "socketStatus",
  initialState,
  reducers: {
    serverConnecting(state) {
      state.serverStatus = "connecting";
    },
    serverReconnecting(state) {
      state.serverStatus = "reconnecting";
    },
    serverConnected(state) {
      state.serverStatus = "connected";
    },
    serverDisconnected(state) {
      state.serverStatus = "disconnected";
    },
    emitGetCalendar(state, action: PayloadAction<EmitGetCalendar>) {
      state.getCalendarStatus = "loading";
    },
    subscribeCalendars(state, action: PayloadAction<SubscribeMsg>) {
      state.updateCalendarStatus = "loading";
    },
    successGetCalendar(state) {
      state.getCalendarStatus = "success";
    },
    successInitCalendars(state) {
      state.updateCalendarStatus = "success";
    },
    subscribeFixtures(state, action: PayloadAction<SubscribeMsg>) {
      state.initFixtureStatus = "loading";
    },

    successInitFixtures(state) {
      state.initFixtureStatus = "success";
    },

    
  },
});
export const {
  serverConnecting,
  serverConnected,
  serverDisconnected,
  subscribeCalendars,
  subscribeFixtures,
  successInitCalendars,
  successInitFixtures,
  emitGetCalendar,
  successGetCalendar,
  serverReconnecting,
} = socketSlice.actions;
export default socketSlice.reducer;
