import Box from "@material-ui/core/Box";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { Pitch } from "../Fixture/Pitch/Pitch";
import { SwiperWrapper } from "../Stats/SwiperWrapper";
import { TrnPlayers } from "./TrnPlayers";
import { TrnTeamStats } from "./TrnTeamStats";
interface Props {
  id: string;
  index: Nullable<StatType>;
  status: RemoteStatus;
  desc: boolean;
  setDesc: (d: boolean) => void;
}

export const TrnStatsWrapper: FC<Props> = ({
  id,
  status,
  index,
  desc,
  setDesc,
}) => (
  <SwiperWrapper>
    <Box
      height={{
        xs: `calc(100vh - 191px)`,
        md: `calc(100vh - 280px)`,
      }}
    >
      <Pitch>
        <TrnTeamStats
          id={id}
          statType={index}
          status={status}
          desc={desc}
          setDesc={setDesc}
        />
      </Pitch>
    </Box>
    <Box
      height={{
        xs: `calc(100vh - 191px)`,
        md: `calc(100vh - 280px)`,
      }}
    >
      <Pitch side="Away">
        <TrnPlayers id={id} statType={index} />
      </Pitch>
    </Box>
  </SwiperWrapper>
);
