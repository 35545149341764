import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@material-ui/core";
import SoccerIcon from "@material-ui/icons/SportsSoccer";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useCalendarId } from "./useCalendarId";
const Image = styled("img")({});
export const CalendarItem: FC<{ id: string }> = ({ id }) => {
  const calendar = useSelector((state) => state.calendar.byId[id]);
  const [calendarId, setCalendarId] = useCalendarId();
  const count = useSelector((state) =>
    state.calendar.live
      ? state.calendar.countMatches[id]?.live
      : state.calendar.countMatches[id]?.all
  );
  return (
    <ListItem
      button
      onClick={() => setCalendarId(id)}
      selected={id === calendarId}
      dense
      divider
    >
      <ListItemIcon>
        <Badge badgeContent={count || 0} color="primary">
          {calendar.details.competition.imagePath ? (
            <Image
              src={calendar.details.competition.imagePath}
              width="24px"
              alt={calendar.details.competition.name}
            />
          ) : (
            <SoccerIcon />
          )}
        </Badge>
      </ListItemIcon>
      <ListItemText primary={calendar.name} />
    </ListItem>
  );
};
