import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Paths } from "app/routes/Paths";
import { User } from "features/user/types";
import { successLogout } from "features/user/userSlice";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LocalStorageKeys } from "shared/constants";
import { removeItem } from "shared/local-storage";
import { createBillingPortal, logout } from "../../API";

interface Props {
  user: User;
}
export const Account: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    try {
      await logout();
      removeItem(LocalStorageKeys.USER);
      dispatch(successLogout());
      history.push(Paths.LOGIN);
    } catch {
      // server error handling
    }
  };
  return (
    <>
      <Box>
        <IconButton
          aria-label="account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          size="small"
        >
          <AccountCircle />
        </IconButton>
      </Box>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List dense>
          <ListItem>
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={user.email}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={async () => {
                      const session = await createBillingPortal();
                      window.location.href = session.url;
                    }}
                  >
                    {"subscription"}
                  </Button>
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography align="center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleLogout}
                    color="secondary"
                    fullWidth
                  >
                    {t("accounts.logout")}
                  </Button>
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Menu>
    </>
  );
};
