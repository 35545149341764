import { Box, Typography } from "@material-ui/core";
import { PeriodEvent, VarEventType } from "features/fixture/interfaces";
import React, { FC } from "react";
import { VarIcon } from "../EventIcons";
import { TimeMin } from "./styled";

interface Props {
  event: PeriodEvent<VarEventType> & { time: number };
}
export const VarEvent: FC<Props> = ({ event }) => {
  return (
    <Box
      display="flex"
      flexDirection={event.side === "Home" ? "row" : "row-reverse"}
      alignItems="center"
    >
      <TimeMin time={event.time} />
      <VarIcon fontSize={"1.5rem"} />
      <Box fontSize="subtitle1.fontSize" px={1}>
        {event.outcome}
        <Typography variant="caption">{` (${event.reason.substring(
          6
        )})`}</Typography>
      </Box>
    </Box>
  );
};
