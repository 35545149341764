import { Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { submitLogin } from "app/API";
import { Paths } from "app/routes/Paths";
import { CreateLoginDto } from "features/user/dto/create-login.dto";
import { JwtPayload } from "features/user/types";
import { successLogin } from "features/user/userSlice";
import { Field, Form, Formik } from "formik";
import JwtDecode from "jwt-decode";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LocalStorageKeys } from "shared/constants";
import { saveItem } from "shared/local-storage";
import * as Yup from "yup";
import { PasswordTextField } from "../core/inputs/PasswordTextField";
import { TextField } from "../core/inputs/TextField";

const FailedMessage: FC<{ error: string }> = ({ error }) => (
  <>
    {error && (
      <Box mb={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )}
  </>
);
export const LoginForm: FC = () => {
  const initialValues: CreateLoginDto = { email: "", password: "" };
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const { from } = location.state || { from: { pathname: Paths.APP } };
  const { t } = useTranslation();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      }),
    []
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values, helper) => {
        const { setStatus, setSubmitting } = helper;
        try {
          const resp = await submitLogin(values);
          const { accessToken } = resp.data;
          if (accessToken) {
            dispatch(successLogin(resp.data));
            const jwtPayload = JwtDecode<JwtPayload>(accessToken);
            setSubmitting(false);
            saveItem(LocalStorageKeys.USER, jwtPayload);
            history.replace(from);
          }
        } catch (err) {
          const error =
            /* @ts-expect-error */
            (err.response && err.response.data.message) || err.message;
          setStatus({ error });
          setSubmitting(false);
        }
      }}
      initialStatus={{
        isLoading: false,
        success: false,
        error: "",
      }}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <Field
              type="email"
              name="email"
              label={t("accounts.email_field")}
              component={TextField}
            />
            <Field
              name="password"
              label={t("accounts.password_field")}
              component={PasswordTextField}
            />
            <FailedMessage error={status.error} />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={isSubmitting}
            >
              {t("button.submit")}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
