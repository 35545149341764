import i18n, { TFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";
function buildYupLocale(_: unknown, t: TFunction): void {
  setLocale({
    mixed: {
      required: ({ path }) => t("validation.field_required", { path })!,
    },
    string: {
      email: () => t("validation.field_is_email"),
      min: ({ min, path }) =>
        t("validation.field_has_min_length", { path, min }),
    },
  });
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: process.env.NODE_ENV === "development",
      saveMissing: true,
      saveMissingPlurals: true,
      fallbackLng: "en",
      backend: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      },
      detection: {
        caches: ["cookie", "localStorage"],
      },
    },
    buildYupLocale
  );

export default i18n;
