export enum PeriodId {
  FIRST_HALF = "1",
  SECOND_HALF = "2",
  EXTRA_TIME_FIRST_HALF = "3",
  EXTRA_TIME_SECOND_HALF = "4",
  PENALTY_SHOOTOUT = "5",
  HALF_TIME = "10",
  END_OF_SECOND_HALF = "11",
  EXTRA_TIME_HALF_TIME = "12",
  END_OF_EXTRA_TIME = "13",
  FULL_TIME = "14",
  PRE_MATCH = "16",
}

export enum StatType {
  goals = "goals",
  possession_percentage = "possession_percentage",
  corner_taken = "corner_taken",
  total_yel_card = "total_yel_card",
  total_red_card = "total_red_card",
  fouls = "fouls",
  shot_off_target = "shot_off_target",
  ontarget_scoring_att = "ontarget_scoring_att",
  total_offside = "total_offside",
  hit_woodwork = "hit_woodwork",
  duel_won = "duel_won",
  touches = "touches",
  unsuccessful_touch = "unsuccessful_touch",
  saves = "saves",
  fk_foul_lost = "fk_foul_lost",
  formation_used = "formation_used",
  formation_place = "formation_place",
  mins_played = "mins_played",
  red_card = "red_card",
  yellow_card = "yellow_card",
  avg_ratings = "avg_ratings",
  total_sub_off = "total_sub_off",
  total_sub_on = "total_sub_on",
  second_yellow = "second_yellow",
  matches_played = "matches_played",
  goals_conceded = "goals_conceded",
  big_chance_created = "big_chance_created",
  big_chance_missed = "big_chance_missed",
  big_chance_scored = "big_chance_scored",
  touches_in_opp_box = "touches_in_opp_box",
  accurate_layoffs = "accurate_layoffs",
  att_one_on_one = "att_one_on_one",
  att_cmiss_high = "att_cmiss_high",
  att_cmiss_high_right = "att_cmiss_high_right",
  att_cmiss_high_left = "att_cmiss_high_left",
  att_cmiss_left = "att_cmiss_left",
  att_cmiss_right = "att_cmiss_right",
  attempts_ibox = "attempts_ibox",
  attempts_obox = "attempts_obox",
  final_third_entries = "final_third_entries",
  long_pass_own_to_opp = "long_pass_own_to_opp",
  long_pass_own_to_opp_success = "long_pass_own_to_opp_success",
  passes_left = "passes_left",
  passes_right = "passes_right",
  total_fastbreak = "total_fastbreak",
  total_fwd_zone_pass = "total_fwd_zone_pass",
  total_layoffs = "total_layoffs",
  fwd_pass = "fwd_pass",
  backward_pass = "backward_pass",
  leftside_pass = "leftside_pass",
  rightside_pass = "rightside_pass",
  successful_final_third_passes = "successful_final_third_passes",
  total_final_third_passes = "total_final_third_passes",
  poss_won_def_3rd = "poss_won_def_3rd",
  goal_fastbreak = "goal_fastbreak",
  pen_area_entries = "pen_area_entries",
  poss_won_mid_3rd = "poss_won_mid_3rd",
  poss_won_att_3rd = "poss_won_att_3rd",
  goal_assist = "goal_assist",
  stand_saves = "stand_saves",
  lost_corners = "lost_corners",
  att_freekick_goal = "att_freekick_goal",
  att_hd_goal = "att_hd_goal",
  att_pen_goal = "att_pen_goal",
  accurate_cross_nocorner = "accurate_cross_nocorner",
  accurate_fwd_zone_pass = "accurate_fwd_zone_pass",
  accurate_pass = "accurate_pass",
  att_fastbreak = "att_fastbreak",
  att_ibox_goal = "att_ibox_goal",
  att_obox_goal = "att_obox_goal",
  att_setpiece = "att_setpiece",
  ball_recovery = "ball_recovery",
  dispossessed = "dispossessed",
  goals_conceded_ibox = "goals_conceded_ibox",
  goals_conceded_obox = "goals_conceded_obox",
  goals_openplay = "goals_openplay",
  interceptions_in_box = "interceptions_in_box",
  offtarget_att_assist = "offtarget_att_assist",
  ontarget_att_assist = "ontarget_att_assist",
  second_goal_assist = "second_goal_assist",
  total_att_assist = "total_att_assist",
  total_scoring_att = "total_scoring_att",
  total_pass = "total_pass",
  interception_won = "interception_won",
  winning_goal = "winning_goal",
  shot_fastbreak = "shot_fastbreak",
  blocked_pass = "blocked_pass",
  accurate_through_ball = "accurate_through_ball",
  att_corner = "att_corner",
  att_openplay = "att_openplay",
  defender_goals = "defender_goals",
  duel_lost = "duel_lost",
  forward_goals = "forward_goals",
  fouled_final_third = "fouled_final_third",
  midfielder_goals = "midfielder_goals",
  penalty_conceded = "penalty_conceded",
  total_contest = "total_contest",
  total_throws = "total_throws",
  goal_assist_openplay = "goal_assist_openplay",
  goal_assist_setplay = "goal_assist_setplay",
  att_assist_openplay = "att_assist_openplay",
  att_assist_setplay = "att_assist_setplay",
  goal_assist_deadball = "goal_assist_deadball",
  open_play_pass = "open_play_pass",
  successful_open_play_pass = "successful_open_play_pass",
  error_lead_to_goal = "error_lead_to_goal",
  error_lead_to_shot = "error_lead_to_shot",
  poss_lost_all = "poss_lost_all",
  total_cross_nocorner = "total_cross_nocorner",
  own_goals = "own_goals",
  total_tackle = "total_tackle",
  goal_kicks = "goal_kicks",
  goal_attempts = "goal_attempts",
  dangerous_play = "dangerous_play",
  attacks = "attacks",
  foul_throw_in = "foul_throw_in",
  counter_attacks = "counter_attacks",
  successful_dribbles = "successful_dribbles",
  interception = "interception",
  total_through_ball = "total_through_ball",
}
