import { Box, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export const NoFixtures = () => {
  const { palette } = useTheme();
  const [t] = useTranslation();
  return (
    <Box mt={1} mb={2} display="flex" justifyContent="center">
      <Box
        border={1}
        p={1}
        width={{ xs: "100%", md: "50%" }}
        borderColor={palette.divider}
      >
        <Typography variant="subtitle2" align="center">
          {t("headers.no_match")}
        </Typography>
      </Box>
    </Box>
  );
};
