import { IconButton } from "@material-ui/core";
import React, { FC } from "react";
import { SortAscIcon, SortDescIcon } from "../Calendar/EventIcons";

interface Props {
  value: boolean;
  clickHandler: () => void;
}
const SortTrnTeamBtn: FC<Props> = ({ clickHandler, value }) => {
  return (
    <IconButton onClick={clickHandler}>
      {value ? <SortDescIcon /> : <SortAscIcon />}
    </IconButton>
  );
};

export default SortTrnTeamBtn;
