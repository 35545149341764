import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useField } from "formik";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps
} from "formik-material-ui";
import React, { FC } from "react";
import { InputContainer } from "./InputContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.Mui-error": {
        color: theme.palette.text.secondary,
      },
    },
  })
);
export const TextField: FC<MuiTextFieldProps> = (props) => {
  const [, meta] = useField(props.field.name);
  const { helperText, ...rest } = props;
  const inputClasses = useStyles();
  return (
    <InputContainer>
      <MuiTextField
        {...rest}
        variant="outlined"
        fullWidth
        helperText={meta.touched && meta.error ? meta.error : helperText}
        InputLabelProps={{ classes: inputClasses }}
      />
    </InputContainer>
  );
};
