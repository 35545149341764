import { Box } from "@material-ui/core";
import { Booking, PeriodEvent } from "features/fixture/interfaces";
import { selectPlayerName } from "features/fixture/selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RedCardIcon, SecYellowCardIcon, YellowCardIcon } from "../EventIcons";
import { PlayerName, TimeMin } from "./styled";

interface Props {
  event: PeriodEvent<Booking>;
}

const CardIcon: FC<{ cardType: PeriodEvent<Booking>["cardType"] }> = ({
  cardType,
}) => (
  <>
    {cardType === "Yellow" && <YellowCardIcon />}
    {cardType === "SecondYellow" && <SecYellowCardIcon />}
    {cardType === "StraightRed" && <RedCardIcon />}
  </>
);

export const CardEvent: FC<Props> = ({ event }) => {
  const name = useSelector(selectPlayerName(event.playerRef));
  return (
    <>
      {event.playerRef && (
        <Box
          display="flex"
          flexDirection={event.side === "Home" ? "row" : "row-reverse"}
          alignItems="center"
        >
          <TimeMin time={event.time} />
          <CardIcon cardType={event.cardType} />
          <PlayerName name={name} />
        </Box>
      )}
    </>
  );
};
