import { Box, IconButton, useTheme } from "@material-ui/core";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import { createSelector, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { setCalendarSchedule } from "features/calendar/calendarSlice";
import moment from "moment";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "./DatePicker";

export const selectCalendarDetails = (id: string) =>
  createSelector(
    (state: RootState) => state.calendar.byId[id],
    ({ details, index }) => ({
      calendarName: details?.name,
      competition: details?.competition,
      index,
    })
  );

const selectCalendarDate = createSelector<RootState, number, moment.Moment>(
  (state) => state.calendar.schedule,
  (date) => moment(date)
);

const createDateChangeHandler =
  (dispatch: Dispatch) => (d: moment.Moment | null) => {
    dispatch(setCalendarSchedule({ schedule: d?.valueOf() || 0 }));
  };

export const CalendarSchedule: FC = () => {
  const date = useSelector(selectCalendarDate);
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const dateHandler = createDateChangeHandler(dispatch);
  const nextDay = () => dateHandler(date?.clone().add(+1, "day") || null);
  const prevDay = () => dateHandler(date?.clone().add(-1, "day") || null);
  return (
    <Box display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
      <Box
        display="flex"
        border={1}
        borderRadius={32}
        borderColor={palette.grey[500]}
      >
        <IconButton onClick={prevDay}>
          <LeftIcon />
        </IconButton>
        <DatePicker date={date} changeHandler={dateHandler} />
        <IconButton onClick={nextDay}>
          <RightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
