import { fetchSeasonalStats } from "app/API";
import { normalize } from "normalizr";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import {
  getSeasonalStats,
  SeasonalStatsEntity,
  setSeasonalStats
} from "./fixtureSlice";
import { httpErrorHandler } from "./httpErrorHandler";
import { seasonalStatsSchema } from "./schemas/seasonalStatsSchema";

export const fetchSeasonalStatsEpic: Epic = (actions$) =>
  actions$.pipe(
    ofActionCreator(getSeasonalStats),
    mergeMap(({ payload }) =>
      fetchSeasonalStats(payload).pipe(
        map(({ data }) =>
          normalize<any, SeasonalStatsEntity>(data, seasonalStatsSchema)
        ),
        map((normalized) => setSeasonalStats(normalized)),
        catchError((e) => httpErrorHandler(e, getSeasonalStats(payload)))
      )
    )
  );
