import {
  AppBar,
  Box,
  Button as MuiButton,
  styled,
  Toolbar,
} from "@material-ui/core";
import { EmailRounded, Person } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Paths } from "app/routes/Paths";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Grid } from "../core/Grid";
import { LangSwitcher } from "../core/LangSwitcher";
import { Account } from "./Account";
import { BackButton } from "./BackButton";
import { Logo } from "./Logo";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.divider,
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
}));

const Button = styled(MuiButton)({ borderRadius: 24 });
const LoginButton = () => {
  const [t] = useTranslation();
  return (
    <Button
      variant="text"
      color="primary"
      href={Paths.LOGIN}
      startIcon={<Person />}
    >
      {t("button.login")}
    </Button>
  );
};
const VerifyAlert = () => {
  const [t] = useTranslation();
  return (
    <Alert severity="warning" icon={<EmailRounded />}>
      {t("headers.ask_to_verify")}
    </Alert>
  );
};
const Appbar = () => {
  const user = useSelector((state) => state.user);
  const visibleBackButton = useRouteMatch(Paths.FIXTURES);
  const visibleLogin = useRouteMatch(Paths.INTRO);
  return (
    <>
      <StyledAppBar position="relative" color="inherit">
        <Toolbar variant="dense">
          <Grid container alignItems="center">
            <Grid item xs={4} textAlign="left">
              {visibleBackButton && <BackButton />}
            </Grid>
            <Grid item xs={4}>
              <Logo />
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <LangSwitcher />
                {visibleLogin?.isExact && <LoginButton />}
                {user.status === "ACTIVE" && <Account user={user} />}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        <Box>{user.status === "CREATED" && <VerifyAlert />}</Box>
      </StyledAppBar>
    </>
  );
};
export default Appbar;
