import { IconButton, Menu, MenuItem } from "@material-ui/core";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import { ToggleButtonGroup } from "@material-ui/lab";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../core/FilterButton";
import { SeasonalFilterContext, SideFilter } from "./SeasonalFilterContext";
export const SideTweak = () => {
  const [t] = useTranslation();
  const options = ["home-away", "home-home", "away-away", "away-home"] as const;
  const titles = {
    "home-away": `${t("glossary.home")}-${t("glossary.away")}`,
    "home-home": `${t("glossary.home")}-${t("glossary.home")}`,
    "away-away": `${t("glossary.away")}-${t("glossary.away")}`,
    "away-home": `${t("glossary.away")}-${t("glossary.home")}`,
  };
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [index, setIndex] = React.useState(0);
  const { side, setSide } = useContext(SeasonalFilterContext);
  const onChange:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void)
    | undefined = (e, value: SideFilter) => {
    if (value == null) {
      setSide();
      return;
    }
    setSide(value);
  };
  return (
    <ToggleButtonGroup size="small" exclusive value={side} onChange={onChange}>
      <FilterButton value={options[index]}>
        {`${titles[options[index]]}`}
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setAnchor(e.currentTarget);
          }}
        >
          <DownIcon fontSize="inherit" />
        </IconButton>
      </FilterButton>
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((item, i) => (
          <MenuItem
            key={item}
            onClick={() => {
              setIndex(i);
              setSide(item as any);
              setAnchor(null);
            }}
          >
            {titles[item]}
          </MenuItem>
        ))}
      </Menu>
    </ToggleButtonGroup>
  );
};
