import { PlayerRating, TeamStanding } from "features/socket/interfaces";
import { NormalizedSchema } from "normalizr";
import { PeriodId, StatType } from "shared/enums";
import { MatchInfo } from "./dto/MatchInfo";
import { Period } from "./dto/Period";
import { PeriodMap } from "./dto/PeriodMap";

/**
 * DTO
 * **/

export interface StatDto {
  type: StatType;
  value: number;
  fh?: number;
  sh?: number;
  [key: number]: number;
}

export interface ContestantDto {
  type: "home" | "away";
  name: string;
  stats: StatDto[];
  stat: any;
  playerLineUp?: {
    matchPlayer: any[];
    formation: string;
  };
  teamKits: any;
}

export interface FixtureDto {
  awayContestantForeignId: string;
  homeContestantForeignId: string;
  date: string;
  foreignId: string;
  status: string;
  time: string;
  tournamentCalendar: string;
  currentPeriod: number;
  contestants: ContestantDto[];
  __v: number;
  _id: string;
}

/**
 * STATE
 * **/

export type NormalFixtures = NormalizedSchema<any, string[]>;
export type RemoteStatus = "unknown" | "loading" | "success" | "failed";
export interface FixturesState {
  byId: { [key: string]: Fixture };
  contestants: { [key: string]: Contestant };
  teamStandings: { [key: string]: TeamStanding };
  players: { [key: string]: MatchPlayer };
  ids: string[];
  h2hStatus: RemoteStatus;
  pbStatus: RemoteStatus;
}
interface MatchOfficial {
  uId: string;
  officialData: {
    officialRef: {
      type: string;
    };
  };
  officialName: {
    first: string;
    last: string;
  };
}
export interface Venue {
  id: string;
  neutral: "yes" | "no";
  longName: string;
  shortName: string;
}
export type BucketType =
  | "PM"
  | "15"
  | "30"
  | "HT"
  | "60"
  | "75"
  | "FT"
  | "LGOAL"
  | "LRED";

type Buckets = { [key in BucketType]: Bucket };

interface Bucket {
  statId: string;
  ratingId: string;
  type: BucketType;
  matchMin: number;
}
type Fixture = {
  id: string;
  matchInfo: MatchInfo;
  contestants: [string, string];
  periods: PeriodMap;
  matchMin?: number;
  matchOfficial?: MatchOfficial;
  buckets: Buckets;
  varData?: VarData;
  type?: "Latest" | "Result";
};

export enum PlayerPosition {
  GOALKEEPER = "Goalkeeper",
  DEFENDER = "Defender",
  MIDFIELDER = "Midfielder",
  STRIKER = "Striker",
  SUBSTITUTE = "Substitute",
  FORWARD = "Forward",
}

export interface MatchPlayer {
  captain?: "1";
  personName: { first: string; last: string; known?: string };
  playerRef: string;
  position: PlayerPosition;
  shirtNumber?: number;
  jerseyNum?: string;
  status: "Start" | "Sub";
  subPosition?: PlayerPosition;
  stats: { [key: string]: StatDto };
  seasonAvg: SeasonAvg;
  bucketStats: { [key in BucketType]: StatsMap };
  indexScore: number;
  name: string;
  firstName: string;
  lastName: string;
  knownName?: string;
  formation_field: null | string;
  formation_position: number | null;
}
interface Kit {
  id: string;
  colour1: string;
  colour2: string;
  type: "home" | "away";
}

interface PositionTopPlayer {
  players: Array<{ playerId: string; value: number }>;
  position: PlayerPosition;
}
export type StatsMap = {
  [key in StatType]: StatDto;
};
type TeamKit = {
  [key in "home" | "away" | "third"]: Kit;
};

export interface Contestant {
  goal?: Goal[];
  booking?: Booking[];
  substitution?: Substitution[];
  id: string;
  opId: string;
  score: number;
  side: "Home" | "Away";
  teamRef: string;
  position: "home" | "away";
  name: string;
  shortName: string;
  officialName: string;
  code: string;
  lineup?: string[];
  startPlayers?: string[];
  subPlayers?: string[];
  stats: { [key: string]: StatDto };
  seasonalStats: { [key: string]: StatDto };
  fullTimeSeasonalStats: { [key: string]: StatDto };
  allSideSeasonalStats: { [key: string]: StatDto };
  topPlayers: Array<{ id: string; stats: Record<string, number> }>;
  bucketStats?: { [key in BucketType]: StatsMap };
  kit?: Kit;
  teamKits: TeamKit;
  matchMin: number;
  seasonAvg: SeasonAvg;
  formation: string;
  standing: TeamStanding;
}

export interface SeasonAvg {
  [key: string]: { [statType: string]: string };
}
interface ContestantStat {
  teamRef: string;
  stat: StatDto[];
  playerLineUp: { matchPlayer: MatchPlayer[] };
}

interface StatsSnapshot {
  contestants: ContestantStat[];
  contestantRatings: any[];
  type: string;
  playerRatings: PlayerRating[];
}

export type PeriodStatsDto = StatsSnapshot[];

interface MatchEvent {
  eventId: string;
  eventNumber: string;
  min: number;
  period: Period | PeriodId;
  playerRef: string;
  sec: number;
  time: number;
  timeStamp: Date;
  uId: string;
}

interface Assist {
  playerRef: string;
}

export interface Goal extends MatchEvent {
  type: "Goal" | "Own" | "Penalty";
  soloRun?: "1";
  varReviewed?: "yes";
  originalDecision?: "GoalAwarded" | "GoalAwarded";
  assist?: Assist;
  "2ndAssist"?: Assist;
}

export interface Booking extends MatchEvent {
  card: "Yellow" | "Red";
  cardType: "StraightRed" | "Yellow" | "SecondYellow";
  managerRef?: string;
  reason: string;
}

export interface Substitution extends MatchEvent {
  subOff: string;
  subOn: string;
  substitutePosition: string;
}

export type EventVariant<T> = T extends Goal
  ? "goal"
  : T extends Booking
  ? "booking"
  : T extends Substitution
  ? "substitution"
  : T extends VarEventType
  ? "varEvent"
  : undefined;

export type PeriodEvent<T> = T & {
  variant: EventVariant<T>;
  side: "Home" | "Away";
};

export type VarPeriodEvent = PeriodEvent<VarEventType> & { time: number };

export interface VarEventType extends MatchEvent {
  decision: string;
  outcome: string;
  reason: string;
  teamRef: string;
}

export interface VarData {
  varEvent: VarEventType[];
}
