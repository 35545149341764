import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { TrnTeamName } from "./TrnTeamName";
interface Props {
  id: string;
  value: number;
}

export const TrnTeamStat: FC<Props> = ({ id, value }) => (
  <Box height="20%" px={2} display="flex" alignItems="center">
    <Box
      display="flex"
      flexGrow={1}
      px={2}
      border="1px solid"
      bgcolor="white"
      borderRadius={12}
    >
      <Box flexGrow={1} textAlign="left">
        <TrnTeamName id={id} />
      </Box>
      <Box width="25%">
        <Typography variant="h6" align="center">
          {value}
        </Typography>
      </Box>
    </Box>
  </Box>
);
