import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";

export const selectContestant = (id: string) => (state: RootState) =>
  state.fixtures.contestants[id];

export const selectContShortName = (id: string) =>
  createSelector(selectContestant(id), (cont) => cont?.shortName);

export const selectContName = (id: string) =>
  createSelector(selectContestant(id), (cont) => cont?.name);

export const selectContCode = (id: string) =>
  createSelector(selectContestant(id), (cont) => cont?.code);

export const selectTeamStanding = (id: string) => (state: RootState) =>
  state.fixtures.contestants[id]?.standing;
