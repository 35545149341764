import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NewPassForm } from "./NewPassForm";

export const NewPass: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box py={2}>
        <Typography variant="h6" align="center">
          {t("accounts.enter_new_pass")}
        </Typography>
      </Box>
      <Box pb={1}>
        <NewPassForm />
      </Box>
    </>
  );
};
