import { styled, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Period } from "features/fixture/dto/Period";
import {
  selectFixturePeriod,
  selectFixtureResult,
  selectFixtureTime,
} from "features/fixture/selectors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TimerFeedback } from "../core/TimerFeedback";
import { FixtureMinutes } from "./FixtureMinutes";
interface Props {
  fixtureId: string;
  variant?: "default" | "minimal";
}

const ColoredTypography = styled(Typography)({
  fontWeight: 900,
  display: "flex",
  color: green[600],
  alignItems: "center",
  justifyContent: "center",
});
const PostponedLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.dark,
}));

export const PeriodStatus: FC<Props> = ({ fixtureId, variant = "default" }) => {
  const [t] = useTranslation();
  const time = useSelector(selectFixtureTime(fixtureId));
  const period = useSelector(selectFixturePeriod(fixtureId));
  const result = useSelector(selectFixtureResult(fixtureId));
  return (
    <>
      {result && result.type === "Postponed" && (
        <PostponedLabel variant="button">
          {t("glossary.postponed")}
        </PostponedLabel>
      )}
      {!result && (!period || period === Period.PRE_MATCH) && (
        <Typography variant="button">
          {time || t("glossary.to_be_announced")}
        </Typography>
      )}
      {period === Period.FULL_TIME && (
        <Typography variant="button">
          {variant === "minimal"
            ? t("glossary.fulltime_short")
            : t("glossary.fulltime")}
        </Typography>
      )}
      {(period === Period.FIRST_HALF || period === Period.SECOND_HALF) && (
        <FixtureMinutes fixtureId={fixtureId} />
      )}
      {period === Period.HALF_TIME && (
        <ColoredTypography variant="button">
          <TimerFeedback />
          {variant === "minimal"
            ? t("glossary.half_time_short")
            : t("glossary.half_time")}
        </ColoredTypography>
      )}
    </>
  );
};
