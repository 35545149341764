import { Box, styled, Typography } from "@material-ui/core";
import StatistigoalIcon from "app/icons/StatistigoalIcon";
import React from "react";
import { Link } from "react-router-dom";
const StyledTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Logo = () => (
  <Box flexGrow={1}>
    <Link to="/">
      <StyledTypography variant="h3">
        <StatistigoalIcon />
      </StyledTypography>
    </Link>
  </Box>
);
