import { Box } from "@material-ui/core";
import { PeriodEvent } from "features/fixture/interfaces";
import { selectPlayerName } from "features/fixture/selectors";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Substitution } from "../../../../features/fixture/interfaces";
import { UpArrowIcon } from "../EventIcons";
import { PlayerName, PlayerNameHelper, TimeMin } from "./styled";

interface Props {
  event: PeriodEvent<Substitution>;
}
export const SubstituteEvent: FC<Props> = ({ event }) => {
  const subOnName = useSelector(selectPlayerName(event.subOn));
  const subOffName = useSelector(selectPlayerName(event.subOff));
  return (
    <Box
      display="flex"
      flexDirection={event.side === "Home" ? "row" : "row-reverse"}
      alignItems="center"
    >
      <TimeMin time={event.time} />
      <UpArrowIcon />
      <PlayerName name={subOnName} />
      <PlayerNameHelper name={subOffName} />
    </Box>
  );
};
