import { Typography } from "@material-ui/core";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import { Grid } from "../core/Grid";
import { StatValue } from "./Styled";
import { TextSkeleton } from "./TextSkeleton";

interface Props {
  home: string;
  away: string;
  status: RemoteStatus;
  title: string;
}

export const MobileAvgStatView: FC<Props> = ({ home, away, status, title }) => (
  <Grid container>
    <Grid item xs={4} textAlign="center">
      <StatValue variant="subtitle2" isMin={home < away}>
        <TextSkeleton status={status} text={home || "0"} />
      </StatValue>
    </Grid>
    <Grid item xs={4} textAlign="center">
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
    </Grid>
    <Grid item xs={4} textAlign="center">
      <StatValue variant="subtitle2" isMin={away < home}>
        <TextSkeleton status={status} text={away || "0"} />
      </StatValue>
    </Grid>
  </Grid>
);
