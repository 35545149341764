import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const Title: FC = ({ children }) => (
  <Box flexGrow={1} px={1}>
    <Typography align="center" variant="subtitle2">
      {children}
    </Typography>
  </Box>
);

interface Props {
  reverse?: boolean;
}

export const TrnPositionTitles: FC<Props> = ({ reverse }) => {
  const [t] = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection={reverse ? "row-reverse" : "row"}
      width="100%"
    >
      <Title>{t("glossary.goalkeeper_short")}</Title>
      <Title>{t("glossary.defender_short")}</Title>
      <Title>{t("glossary.midfielder_short")}</Title>
      <Title>{t("glossary.striker_short")}</Title>
    </Box>
  );
};
