import { Box, Card } from "@material-ui/core";
import React from "react";
import { Grid } from "../core/Grid";
import { ContestantInfo } from "./ContestantInfo";
import { ScoreCont } from "./ScoreCont";

interface Props {
  homeId: string;
  awayId: string;
  fixtureId: string;
}
export const MinimalFixtureOverview = React.forwardRef<
  HTMLElement | undefined,
  Props
>(({ homeId, awayId, fixtureId }, ref) => {
  return (
    /*@ts-expect-error*/
    <Grid container ref={ref} bgcolor="background.default">
      <Grid item md={3} />
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <Box py={1} px={{ xs: 1, md: 4 }}>
            <Grid container alignItems="center" textAlign="center">
              <Grid item xs={4}>
                <ContestantInfo
                  minimal={true}
                  contestantId={homeId}
                  position="home"
                />
              </Grid>
              <Grid item xs={4}>
                {homeId && awayId && (
                  <ScoreCont fixtureId={fixtureId} variant="minimal" />
                )}
              </Grid>
              <Grid item xs={4}>
                <ContestantInfo
                  minimal={true}
                  contestantId={awayId}
                  position="away"
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item md={3} />
    </Grid>
  );
});
