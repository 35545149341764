import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { MatchStatViewProps } from "./MatchStatView";
import { StatViewContainer } from "./StatViewContainer";

type Props = Pick<MatchStatViewProps, "subStats" | "status" | "side">;

export const SubStatsView: FC<Props> = ({ subStats = [], status, side }) => (
  <Box px={4} pt={1} pb={2}>
    {subStats.map((props) => (
      <StatViewContainer status={status} side={side} {...props} />
    ))}
  </Box>
);
