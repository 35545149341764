import Appbar from "app/components/appbar/Appbar";
import { Fixture } from "app/components/Fixture/Fixture";
import { Home } from "app/components/home/Home";
import { CheckoutPage } from "app/components/subscription/CheckoutPage";
import { authorize } from "features/user/userSlice";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { UpdateUser } from "../components/update-user/UpdateUser";
import { Paths } from "./Paths";
import { SocketStatus } from "./SocketStatus";
export const AppRoutes: FC = () => {
  const status = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authorize());
  }, [dispatch]);
  return (
    <>
      <Appbar />
      <Switch>
        <Route
          exact
          path={Paths.APP}
          render={({ location }) => {
            if (status === "ACTIVE" || status === "CREATED") return <Home />;
            return <Redirect to={Paths.LOGIN} />;
          }}
        />

        <Route
          path={`${Paths.FIXTURES}/:id`}
          render={({ location }) => {
            if (status === "ACTIVE" || status === "CREATED") return <Fixture />;
            return <Redirect to={Paths.LOGIN} />;
          }}
        />
        <Route path={Paths.ACCOUNTS}>
          <UpdateUser />
        </Route>
        <Route path={Paths.CHECKOUT}>
          <CheckoutPage />
        </Route>
      </Switch>
      <SocketStatus />
    </>
  );
};
