import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchTrnStatsParams } from "features/calendar/trn-stats/FetchTrnStatsParams";
import {
  AddFixtureIds,
  Calendar,
  CalendarDto,
  Calendars,
  SetSchedule,
} from "./interfaces";
import { FetchStandingsParams } from "./standings/FetchStandingsParams";
import { Ranking } from "./standings/Ranking";

interface FetchStandings {
  calId: string;
  params?: FetchStandingsParams;
}
interface SetStandings {
  calId: string;
  rankings: Ranking[];
}

interface FetchTrnStats {
  id: string;
  params: FetchTrnStatsParams;
}

interface SetTrnStats {
  id: string;
  // contestants: Record<string, TeamStatRankings>;
  contestants: any;
  // players: Record<string, PlayerRankings>;
  players: any;
  trnAvgStats: any;
}

export interface GetCountMatchesByDate {
  trnIds: string[];
  date: number;
  timezoneOffset: number;
}

export interface SetCountMatchesByDate {
  trnId: string;
  count: number;
  live: number;
}

export interface GetPaginatedCalendars {
  page: number;
  per_page: number;
}

const createInitialTrn = ({
  index,
  id,
  name,
  calendar,
}: {
  index: number;
  id: string;
  name: string;
  calendar: CalendarDto;
}): Calendar => ({
  id,
  name,
  index,
  fixtureIds: [],
  standings: {
    rankings: [],
    status: "unknown",
  },
  stats: {
    status: "unknown",
    contestants: {},
    players: {},
    trnAvgStats: {},
  },
  contestants: {},
  players: {},
  details: calendar,
});

const initialState: Calendars = {
  schedule: Date.now(),
  countMatches: {},
  byId: {},
  ids: [],
  live: false,
};

const calendarSlice = createSlice({
  name: "calendars",
  initialState,
  reducers: {
    calendarsFetched(state, action: PayloadAction<CalendarDto[]>) {
      const calendars = action.payload;
      state.byId = Object.fromEntries(
        calendars.map((calendar, index) => [
          calendar._id,
          createInitialTrn({
            index,
            id: calendar._id,
            name: calendar.competition.name,
            calendar,
          }),
        ])
      );
      state.ids = calendars.map((cal) => cal._id);
    },
    getCalendars(state, action: PayloadAction<GetPaginatedCalendars>) {},
    calendarFetched(state, action: PayloadAction<CalendarDto>) {
      const { _id } = action.payload;
      state.byId[_id] = {
        ...state.byId[_id],
        details: action.payload,
      };
    },
    addFixtureIds(state, action: PayloadAction<AddFixtureIds>) {
      const { fixturesIds, calendarId } = action.payload;
      state.byId[calendarId].fixtureIds = fixturesIds;
    },

    setCalendarSchedule(state, action: PayloadAction<SetSchedule>) {
      const { schedule } = action.payload;
      state.schedule = schedule;
    },

    fetchStandings(state, action: PayloadAction<FetchStandings>) {
      const { calId } = action.payload;

      // @ts-expect-error
      state.byId[calId] = {
        id: calId,
      };
      state.byId[calId].standings = { status: "loading", rankings: [] };
    },

    setStandings(state, action: PayloadAction<SetStandings>) {
      const { calId, rankings } = action.payload;
      console.log("setStandings", calId, rankings);
      console.log("state.byId[calId]", state.byId[calId]);
      state.byId[calId].standings = {
        rankings,
        status: "success",
      };
    },
    fetchTrnStats(state, action: PayloadAction<FetchTrnStats>) {
      const { id } = action.payload;
      // @ts-expect-error
      state.byId[id] = {
        id,
        stats: {
          status: "loading",
          contestants: {},
          players: {},
          trnAvgStats: {},
        },
      };
    },
    setTrnStats(state, action: PayloadAction<SetTrnStats>) {
      const { id, ...stats } = action.payload;
      state.byId[id].stats = { status: "success", ...stats };
    },

    getCountMatchesByDate(
      state,
      action: PayloadAction<GetCountMatchesByDate>
    ) {},
    setCountMatchesByDate(
      state,
      action: PayloadAction<SetCountMatchesByDate[]>
    ) {
      const entries = action.payload.map(({ trnId, count, live }) => [
        trnId,
        { all: count, live },
      ]);
      state.countMatches = Object.fromEntries(entries);
    },
    toggleLiveFilter(state, action: PayloadAction<{ live: boolean }>) {
      state.live = action.payload.live;
    },
  },
});

export const {
  addFixtureIds,
  calendarFetched,
  setCalendarSchedule,
  fetchStandings,
  setStandings,
  fetchTrnStats,
  setTrnStats,
  setCountMatchesByDate,
  getCountMatchesByDate,
  calendarsFetched,
  getCalendars,
  toggleLiveFilter,
} = calendarSlice.actions;

export default calendarSlice.reducer;
