import { Typography } from "@material-ui/core";
import { Grid } from "../core/Grid";

export const TermsFR = () => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      <Typography variant="h3" align="center">
        Conditions Générales d'Utilisation
      </Typography>
    </Grid>
    <Grid item md={3} />
    <Grid item xs={12} md={6} bgcolor='white'>
      <Typography variant="h6">
        1. Conditions générales d'utilisation du site et des services proposés
      </Typography>
      <Typography variant="body1">
        L'utilisation du site www.statistigoal.com implique l'acceptation pleine
        et entière des conditions générales d'utilisation ci-après décrites. Ces
        conditions d'utilisation sont susceptibles d'être modifiées ou
        complétées à tout moment, les utilisateurs du site www.statistigoal.com
        sont donc invités à les consulter de manière régulière. Ce site est
        normalement accessible à tout moment aux utilisateurs. Une interruption
        pour raison de maintenance technique peut être toutefois décidée par les
        administrateurs du site, qui s'efforceront alors de communiquer
        préalablement aux utilisateurs les dates et heures de l'intervention. De
        la même façon, les mentions légales peuvent être modifiées à tout moment
        : elles s'imposent néanmoins à l'utilisateur qui est invité à s'y
        référer le plus souvent possible afin d'en prendre connaissance.
      </Typography>
      <br />
      <br />
      <Typography variant="h6">2. Description des services fournis</Typography>
      <Typography variant="body1">
        Le site www.statistigoal.com a pour objet de fournir des résultats et
        des statistiques de football en direct. Il s'efforce de fournir des
        informations aussi précises que possible. Toutefois, il ne pourra être
        tenue responsable des omissions, des inexactitudes et des carences dans
        la mise à jour, qu'elles soient de son fait ou du fait des tiers
        partenaires qui lui fournissent ces informations. Tous les informations
        indiquées sur le site www.statistigoal.com sont données à titre
        indicatif, et sont susceptibles d'évoluer. Par ailleurs, les
        renseignements figurant sur le site www.statistigoal.com ne sont pas
        exhaustifs. Ils sont donnés sous réserve de modifications ayant été
        apportées depuis leur mise en ligne.
      </Typography>

      <br />
      <br />
      <Typography variant="h6">
        3. Limitations contractuelles sur les données techniques
      </Typography>
      <Typography variant="body1">
        Le site utilise la technologie JavaScript. Le site Internet ne pourra
        être tenu responsable de dommages matériels liés à l'utilisation du
        site. De plus, l'utilisateur du site s'engage à accéder au site en
        utilisant un matériel récent, ne contenant pas de virus et avec un
        navigateur de dernière génération mis-à-jour.
      </Typography>

      <br />
      <br />
      <Typography variant="h6">
        4. Propriété intellectuelle et contrefaçons
      </Typography>
      <Typography variant="body1">
        www.statistigoal.com est propriétaire des droits de propriété
        intellectuelle ou détient les droits d'usage sur tous les éléments
        accessibles sur le site, notamment les textes, images, graphismes, logo,
        icônes, sons, logiciels. Toute reproduction, représentation,
        modification, publication, adaptation de tout ou partie des éléments du
        site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf
        autorisation écrite préalable de : www.statistigoal.com. Toute
        exploitation non autorisée du site ou de l'un quelconque des éléments
        qu'il contient sera considérée comme constitutive d'une contrefaçon et
        poursuivie conformément aux dispositions des articles L.335-2 et
        suivants du Code de Propriété Intellectuelle.
      </Typography>

      <br />
      <br />
      <Typography variant="h6">5. Limitations de responsabilité</Typography>
      <Typography variant="body1">
        www.statistigoal.com ne pourra être tenue responsable des dommages
        directs et indirects causés au matériel de l'utilisateur, lors de
        l'accès au site www.statistigoal.com, et résultant soit de l'utilisation
        d'un matériel ne répondant pas aux spécifications indiquées au point 4,
        soit de l'apparition d'un bug ou d'une incompatibilité.
        www.statistigoal.com ne pourra également être tenue responsable des
        dommages indirects (tels par exemple qu'une perte de marché ou perte
        d'une chance) consécutifs à l'utilisation du site. Des espaces
        interactifs (possibilité de poser des questions dans l'espace contact)
        sont à la disposition des utilisateurs. www.statistigoal.com se réserve
        le droit de supprimer, sans mise en demeure préalable, tout contenu
        déposé dans cet espace qui contreviendrait à la législation applicable
        en France, en particulier aux dispositions relatives à la protection des
        données. Le cas échéant, www.statistigoal.com se réserve également la
        possibilité de mettre en cause la responsabilité civile et/ou pénale de
        l'utilisateur, notamment en cas de message à caractère raciste,
        injurieux, diffamant, ou pornographique, quel que soit le support
        utilisé (texte, photographie…).
      </Typography>

      <br />
      <br />
      <Typography variant="h6">6. Gestion des données personnelles</Typography>
      <Typography variant="body1">
        En France, les données personnelles sont notamment protégées par la loi
        n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article
        L. 226-13 du Code pénal et la Directive Européenne du 24 octobre
        1995.Les bases de données sont protégées par les dispositions de la loi
        du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
        relative à la protection juridique des bases de données. Pour plus
        d'informations, vous pouvez lire la charte des données personnelles du
        site.
      </Typography>

      <br />
      <br />
      <Typography variant="h6">7. Liens hypertextes et cookies</Typography>
      <Typography variant="body1">
        Le site www.statistigoal.com contient un certain nombre de liens
        hypertextes vers d'autres sites, mis en place avec l'autorisation de ses
        administrateurs. Cependant, www.statistigoal.com n'a pas la possibilité
        de vérifier le contenu des sites ainsi visités, et n'assumera en
        conséquence aucune responsabilité de ce fait. La navigation sur le site
        www.statistigoal.com est susceptible de provoquer l'installation de
        cookie(s) sur l'ordinateur de l'utilisateur. Un cookie est un fichier de
        petite taille, qui ne permet pas l'identification de l'utilisateur, mais
        qui enregistre des informations relatives à la navigation d'un
        ordinateur sur un site. Les données ainsi obtenues visent à faciliter la
        navigation ultérieure sur le site, et ont également vocation à permettre
        diverses mesures de fréquentation. Le refus d'installation d'un cookie
        peut entraîner l'impossibilité d'accéder à certains services.
        L'utilisateur peut toutefois configurer son ordinateur de la manière
        suivante, pour refuser l'installation des cookies : Sous Internet
        Explorer : onglet outil (pictogramme en forme de rouage en haut a
        droite) / options internet. Cliquez sur Confidentialité et choisissez
        Bloquer tous les cookies. Validez sur Ok. Sous Firefox : en haut de la
        fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller dans
        l'onglet Options. Cliquer sur l'onglet Vie privée. Paramétrez les Règles
        de conservation sur : utiliser les paramètres personnalisés pour
        l'historique. Enfin décochez-la pour désactiver les cookies. Sous Safari
        : Cliquez en haut à droite du navigateur sur le pictogramme de menu
        (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher
        les paramètres avancés. Dans la section "Confidentialité", cliquez sur
        Paramètres de contenu. Dans la section "Cookies", vous pouvez bloquer
        les cookies. Sous Chrome : Cliquez en haut à droite du navigateur sur le
        pictogramme de menu (symbolisé par trois lignes horizontales).
        Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés.
        Dans la section "Confidentialité", cliquez sur préférences. Dans
        l'onglet "Confidentialité", vous pouvez bloquer les cookies.
      </Typography>

      <br />
      <br />
      <Typography variant="h6">
        10. Droit applicable et attribution de juridiction
      </Typography>
      <Typography variant="body1">
        Tout litige en relation avec l'utilisation du site www.statistigoal.com
        est soumis au droit français. Il est fait attribution exclusive de
        juridiction aux tribunaux compétents de Paris.
      </Typography>

      <br />
      <br />
      <Typography variant="h6">11. Les principales lois concernées</Typography>
      <Typography variant="body1">
        Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n°
        2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux
        libertés. Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
        l'économie numérique.
      </Typography>
    </Grid>
    <Grid item md={3} />
  </Grid>
);
