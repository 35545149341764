import { fetchStandingsReq } from "app/API";
import { httpErrorHandler } from "features/fixture/httpErrorHandler";
import { Epic } from "redux-observable";
import { catchError, map, mergeMap } from "rxjs/operators";
import ofActionCreator from "utils/ofActionCreator";
import { fetchStandings, setStandings } from "./calendarSlice";

export const fetchStandingsEpic: Epic = (action$) =>
  action$.pipe(
    ofActionCreator(fetchStandings),
    mergeMap(({ payload }) =>
      fetchStandingsReq(payload.calId, payload.params).pipe(
        map(({ data }) => setStandings(data)),
        catchError((e) => httpErrorHandler(e, fetchStandings))
      )
    )
  );
