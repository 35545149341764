import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import { RemoteStatus } from "features/fixture/interfaces";
import React, { FC } from "react";
import {
  PlayerAvg,
  RedCard,
  ScoredIcon,
  SecYellowCard,
  SubOffIcon,
  SubOnIcon,
  YellowCard,
} from "./PlayerIcons";
import { JerseyNum, PlayerName, StatContainer } from "./PlayerStyles";

interface PlayerProps {
  status: RemoteStatus;
  kitColour: string;
  name: string;
  jerseyNum: string;
  stat: number;
  subOff?: boolean;
  subOn?: boolean;
  scored?: boolean;
  yCard?: boolean;
  dirRCard?: boolean;
  secYCard?: boolean;
  ownGoal?: boolean;
  ftAvg?: string;
  periodAvg?: string;
  periodName?: string;
}
export const PlayerStat: FC<PlayerProps> = ({
  status,
  kitColour,
  stat,
  name,
  subOff,
  subOn,
  scored,
  yCard,
  dirRCard,
  secYCard,
  jerseyNum,
  ownGoal,
  ftAvg,
  periodAvg,
  periodName,
}) => {
  return (
    <>
      {status === "loading" && (
        <Skeleton variant="circle" height="25px" width="25px" />
      )}
      {status === "success" && (
        <>
          <StatContainer bgColour={kitColour}>
            <PlayerAvg ft={ftAvg} period={periodAvg} periodName={periodName} />
            <SubOffIcon show={subOff} />
            <SubOnIcon show={subOn} />
            <ScoredIcon show={scored || ownGoal} isOwnGoal={ownGoal} />
            <YellowCard show={yCard} />
            <RedCard show={dirRCard} />
            <SecYellowCard show={secYCard} />
            <Typography variant="subtitle1" component="span" color="inherit">
              {(stat !== 0 && stat) || ""}
            </Typography>
          </StatContainer>
          <PlayerName variant="caption" align="center">
            <JerseyNum variant="inherit">{`#${jerseyNum}`}</JerseyNum>
            <br />
            {name}
          </PlayerName>
        </>
      )}
    </>
  );
};
