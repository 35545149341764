import React, { FC } from "react";
import { useSelector } from "react-redux";
import { LoadingFeedback } from "../core/LoadingFeedback";
import { RefereeStatsView } from "./RefereeStatsView";

interface Props {
  id: string;
}

export const RefereeStats: FC<Props> = ({ id }) => {
  const { status, ...rest } = useSelector((state) => state.referee.byId[id]);
  return (
    <>
      {status === "loading" && <LoadingFeedback />}
      {status === "success" && <RefereeStatsView {...rest} />}
    </>
  );
};
