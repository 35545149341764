import { Box, Typography } from "@material-ui/core";
import RightIcon from "@material-ui/icons/ChevronRight";
import { Paths } from "app/routes/Paths";
import { Period } from "features/fixture/dto/Period";
import { selectHasMatchStats } from "features/fixture/selectors";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PeriodId } from "shared/enums";
import { Grid } from "../core/Grid";
import { PeriodHighlights } from "./PeriodHighlights";

interface Props {
  fixtureId: string;
}

export const FixtureDetails: FC<Props> = ({ fixtureId }) => {
  const hasStats = useSelector(selectHasMatchStats(fixtureId));
  const [t] = useTranslation();
  return (
    <Grid container py={1}>
      <Grid item xs={12}>
        <Link
          to={`${Paths.FIXTURES}/${fixtureId}/${
            hasStats ? "team_stats" : "match_stats"
          }`}
        >
          <Box>
            <Typography variant="button" color="primary">
              <Box display="flex" justifyContent="center" py={1}>
                {t("glossary.match_stats")}
                <RightIcon />
              </Box>
            </Typography>
            <PeriodHighlights
              fixtureId={fixtureId}
              period={Period.FIRST_HALF}
              periodId={PeriodId.FIRST_HALF}
            />
            <PeriodHighlights
              fixtureId={fixtureId}
              period={Period.SECOND_HALF}
              periodId={PeriodId.SECOND_HALF}
            />
          </Box>
        </Link>
      </Grid>
    </Grid>
  );
};
