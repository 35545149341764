import { Action } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { accessTokenExpired } from "features/user/userSlice";
import { EMPTY, iif, of } from "rxjs";
export const httpErrorHandler = <E extends AxiosError>(
  e: E,
  retryAction: Action
) =>
  iif(
    () => e.response?.data?.error?.name === "TokenExpiredError",
    of(accessTokenExpired()),
    EMPTY
  );
