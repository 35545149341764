import { useTimeBucketTitle } from "app/components/Stats/useTimeBucketTitle";
import { RemoteStatus } from "features/fixture/interfaces";
import {
  selectFixPeriodBucket,
  selectPlayerEvents,
  selectPlayerName,
  selectPlayerPlace,
  selectPlayerShirtNum,
} from "features/fixture/selectors";
import { usePlayerAvgStat } from "features/fixture/usePlayerAvgStat";
import { usePlayerStatType } from "features/fixture/usePlayerStatType";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StatType } from "shared/enums";
import { usePlayerStat } from "../../../../features/fixture/usePlayerStat";
import { useIsUserSubscribed } from "../useIsUserSubscribed";
import { PlayerStat } from "./PlayerStat";
import { PlayerCont } from "./PlayerStyles";

interface Props {
  id: string;
  kitColour?: string;
  reverse?: boolean;
  status: RemoteStatus;
}
export const Player: FC<Props> = ({ id, reverse, kitColour, status }) => {
  const { id: fixtureId } = useParams<{ id: string }>();
  const [qLow = "PM", qUp = "FT"] = useSelector(
    selectFixPeriodBucket(fixtureId)
  );
  const { period } = useTimeBucketTitle(fixtureId);
  const type = usePlayerStatType() ?? StatType.matches_played;
  const playerStat = usePlayerStat(id, type);
  const ftAvg = usePlayerAvgStat(id, "PM-FT", type);
  const periodAvg = usePlayerAvgStat(id, `${qLow}-${qUp}`, type);
  const name = useSelector(selectPlayerName(id));
  const shirtNum = useSelector(selectPlayerShirtNum(id));
  const events = useSelector(selectPlayerEvents(id));
  const place = useSelector(selectPlayerPlace(id));
  const user = useSelector((s) => s.user.status);
  const isSubscribed = useIsUserSubscribed();
  const stat = user === "ACTIVE" && isSubscribed ? playerStat : 0;
  return (
    <PlayerCont
      post={place}
      reverse={reverse}
      hasStat={type === StatType.matches_played || stat || type === null}
    >
      <PlayerStat
        status={status}
        stat={stat}
        kitColour={kitColour || ""}
        jerseyNum={shirtNum}
        name={name}
        ftAvg={ftAvg}
        periodAvg={periodAvg}
        periodName={period}
        {...events}
      />
    </PlayerCont>
  );
};
