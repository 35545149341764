import { styled, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React, { FC } from "react";
import { useFixtureClock } from "../../../utils/useFixtureClock";
import { TimerFeedback } from "../core/TimerFeedback";

interface Props {
  fixtureId: string;
}

const ColoredTypography = styled(Typography)({
  fontWeight: 900,
  display: "flex",
  color: green[600],
  alignItems: "center",
  justifyContent: "center",
});
export const FixtureMinutes: FC<Props> = ({ fixtureId }) => {
  const [mins, sec] = useFixtureClock(fixtureId);
  return (
    <ColoredTypography variant="button" color="inherit">
      <TimerFeedback />
      {`${mins}:${sec}`}
    </ColoredTypography>
  );
};
