import {
  Box,
  CircularProgress,
  createStyles,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Alert = withStyles((t) =>
  createStyles({
    root: {
      borderRadius: 16,
      boxShadow: t.shadows[8],
      paddingRight: t.spacing(4),
      paddingLeft: t.spacing(4),
    },
  })
)(MuiAlert);
export const SocketStatus: FC = () => {
  const [reconnect, setReconnect] = React.useState(false);
  const [t] = useTranslation();
  const status = useSelector((s) => s.socketStatus.serverStatus);
  React.useEffect(() => {
    if (status === "reconnecting") setReconnect(true);
  }, [status]);
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={status === "reconnecting"}
      >
        <Box>
          <Alert
            severity="warning"
            icon={
              <CircularProgress
                size={"1.5rem"}
                color="inherit"
                variant="indeterminate"
              />
            }
          >
            {t("network.reconnecting")}
          </Alert>
        </Box>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={status === "connected" && reconnect}
        onClose={() => setReconnect(false)}
        autoHideDuration={1000}
      >
        <Alert>{t("network.connected")}</Alert>
      </Snackbar>
    </>
  );
};
