import { combineReducers } from "@reduxjs/toolkit";
import calendar from "features/calendar/calendarSlice";
import fixtures from "features/fixture/fixtureSlice";
import metadata from "features/metadata/metadataSlice";
import referee from "features/referees/refereesSlice";
import socketStatus from "features/socket/socketSlice";
import user from "features/user/userSlice";

const rootReducer = combineReducers({
  fixtures,
  socketStatus,
  calendar,
  user,
  referee,
  metadata,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
