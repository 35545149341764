import { IconButton, Menu, MenuItem } from "@material-ui/core";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import { ToggleButtonGroup } from "@material-ui/lab";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../core/FilterButton";

interface Props {
  options: string[];
  gameLimit: number | undefined;
  setGameLimit: (g?: number) => void;
}

export const GameLimitTweak: FC<Props> = ({
  options,
  gameLimit,
  setGameLimit,
}) => {
  const [t] = useTranslation();
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [index, setIndex] = React.useState(0);
  const onChange:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void)
    | undefined = (e, value: string) => {
    if (value === null) {
      setGameLimit();
      return;
    }
    setGameLimit(Number(value));
  };
  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      value={gameLimit?.toString()}
      onChange={onChange}
    >
      <FilterButton value={options[index]}>
        {t("filters.game_limit", { limit: options[index] })}
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setAnchor(e.currentTarget);
          }}
        >
          <DownIcon fontSize="inherit" />
        </IconButton>
      </FilterButton>
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((item, i) => (
          <MenuItem
            key={item}
            onClick={() => {
              setIndex(i);
              setGameLimit(Number(item));
              setAnchor(null);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </ToggleButtonGroup>
  );
};
