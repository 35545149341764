import { Box, Hidden } from "@material-ui/core";
import { fetchTrnStats } from "features/calendar/calendarSlice";
import { selectTrnAvgStats } from "features/calendar/selectors/selectTrnAvgStats";
import { selectTrnStatsStatus } from "features/calendar/selectors/selectTrnStatsStatus";
import { StatsViewContext } from "features/fixture/StatsViewContext";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatType } from "shared/enums";
import { useStatsViewIndex } from "utils/useStatsViewIndex";
import { Grid } from "../core/Grid";
import { MobileStatsWrapper } from "../Stats/MobileStatsWrapper";
import { STAT_ITEMS } from "../Stats/STAT_ITEMS";
import { FilterContainer } from "./FilterContainer";
import { MobileTrnStatsList } from "./MobileTrnStatsList";
import { TrnFilterContext, useTrnFilter } from "./TrnFilterContext";
import { TrnPeriodTweak } from "./TrnPeriodTweak";
import { TrnStatsList } from "./TrnStatsList";
import { TrnStatsWrapper } from "./TrnStatsWrapper";
import { TRN_STAT_ITEMS } from "./TRN_STAT_ITEMS";

interface Props {
  id: string;
}
export const TrnStats: FC<Props> = ({ id }) => {
  const { index, setIndex } = useStatsViewIndex(StatType.possession_percentage);
  const [desc, setDesc] = useState(true);
  const filter = useTrnFilter();
  const status = useSelector(selectTrnStatsStatus(id));
  const trnAvgStats = useSelector(selectTrnAvgStats(id));
  const { bounds, gameLimit, side } = filter;
  const [lowerMinute, upperMinute] = bounds;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchTrnStats({
        id,
        params: { lowerMinute, upperMinute, gameLimit, side },
      })
    );
  }, [dispatch, gameLimit, id, lowerMinute, side, upperMinute]);
  return (
    <StatsViewContext.Provider value={{ index, setIndex }}>
      <TrnFilterContext.Provider value={filter}>
        <FilterContainer>
          <TrnPeriodTweak />
        </FilterContainer>

        <Hidden mdUp>
          <Box>
            <MobileStatsWrapper>
              <MobileTrnStatsList
                index={index}
                setIndex={setIndex}
                status={status}
                items={TRN_STAT_ITEMS}
                stats={trnAvgStats}
              />
            </MobileStatsWrapper>
          </Box>
        </Hidden>

        <Grid container>
          <Grid item md={3}>
            <Hidden mdDown>
              <TrnStatsList
                index={index}
                setIndex={setIndex}
                status={status}
                items={STAT_ITEMS}
                stats={trnAvgStats}
              />
            </Hidden>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box position={{ md: "sticky" }} top={161}>
              <TrnStatsWrapper
                id={id}
                status={status}
                index={index}
                desc={desc}
                setDesc={setDesc}
              />
            </Box>
          </Grid>
        </Grid>
      </TrnFilterContext.Provider>
    </StatsViewContext.Provider>
  );
};
