import { createSelector } from "@reduxjs/toolkit";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { selectContestant } from "./selectContestant";

export const selectSeasonAvgStat = (id: string, type: Nullable<StatType>) =>
  createSelector(selectContestant(id), (cont) => {
    const value = (type && cont?.seasonalStats?.[type]?.value) || 0;
    const total = cont?.seasonalStats?.[StatType.matches_played]?.value || 1;
    return (value / total).toFixed(1);
  });
