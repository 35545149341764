import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BucketType } from "features/fixture/interfaces";
import { TeamStanding } from "features/socket/interfaces";
import { assign, merge } from "lodash";
import { NormalizedSchema } from "normalizr";
import { StatType } from "shared/enums";
import { avgRatings } from "utils/avgRatings";
import { CountMatchesPlayedDto } from "./dto/CountMatchesPlayedDto";
import { FixturesState, NormalFixtures } from "./interfaces";
import { SetSeasonAvg, StatData } from "./types";
const initialState: FixturesState = {
  byId: {},
  contestants: {},
  teamStandings: {},
  players: {},
  ids: [],
  h2hStatus: "unknown",
  pbStatus: "unknown",
};

interface TeamFilter {
  id: string;
  side?: "home" | "away";
}
export interface GetSeasonalStats {
  teams: [TeamFilter, TeamFilter];
  seasonId: string;
  lowerMinute: BucketType;
  upperMinute: BucketType;
  gameLimit?: number;
}
export type SeasonalStatsEntity = { contestants: { [key: string]: any } };
type SetSeasonalStats = NormalizedSchema<SeasonalStatsEntity, string[]>;

export type PeriodBucketsEntity = {
  contestants: { [key: string]: any };
  players: { [key: string]: any };
};
type SuccessPeriodBuckets = NormalizedSchema<
  PeriodBucketsEntity,
  {
    type: BucketType;
    contestants: [string, string];
  }
>;

interface Bound {
  type: BucketType;
  ratingId: string;
  statId: string;
}
export type GetPeriodBucket = [Bound, Bound] | [Bound];
type SetTeamStandings = NormalizedSchema<
  { teamStandings: Record<number, TeamStanding> },
  number[]
>;

interface SetCountedPlayed {
  home: CountMatchesPlayedDto;
  away: CountMatchesPlayedDto;
}
const toStatsObject = (stats: StatData[]) =>
  Object.fromEntries(stats.map((s) => [s.type, s.value.toString()]));
const fixtureSlice = createSlice({
  name: "fixture",
  initialState,
  reducers: {
    getPlayersPlayed(
      state,
      action: PayloadAction<{
        seasonId: string;
        homeId: string;
        awayId: string;
      }>
    ) {
      // state.h2hStatus = "loading";
    },
    setPlayersPlayed(state, action: PayloadAction<SetCountedPlayed>) {
      const { home, away } = action.payload;
      [home.players, away.players]
        .flatMap((p) => p)
        .forEach(({ playerRef, value }) => {
          if (state.players[playerRef]?.stats)
            state.players[playerRef].stats[StatType.matches_played] = {
              type: StatType.matches_played,
              value,
            };
        });
      [home.team, away.team].forEach(
        ({ teamRef, value }) =>
          (state.contestants[teamRef].stats[StatType.matches_played] = {
            type: StatType.matches_played,
            value,
          })
      );
    },
    getSeasonAvg(state, action: PayloadAction<GetSeasonalStats>) {
      const {
        lowerMinute,
        upperMinute,
        teams: [home, away],
      } = action.payload;
      const key = `${lowerMinute}-${upperMinute}`;
      state.contestants[home.id].seasonAvg = {
        ...state.contestants[home.id].seasonAvg,
        [key]: {},
      };
      state.contestants[away.id].seasonAvg = {
        ...state.contestants[away.id].seasonAvg,
        [key]: {},
      };
      Object.keys(state.players).forEach(
        (id) =>
          (state.players[id].seasonAvg = {
            ...state.players[id].seasonAvg,
            [key]: {},
          })
      );
    },
    setSeasonAvg(state, action: PayloadAction<SetSeasonAvg>) {
      const {
        key,
        data: [home, away],
      } = action.payload;

      state.contestants[home.id].seasonAvg[key] = toStatsObject(home.stats);
      state.contestants[away.id].seasonAvg[key] = toStatsObject(away.stats);
      [...home.players, ...away.players]
        .filter((p) => !!state.players[p.id])
        .forEach((p) => {
          state.players[p.id].seasonAvg[key] = toStatsObject(p.stats);
        });
    },
    getSeasonalStats(state, action: PayloadAction<GetSeasonalStats>) {
      state.h2hStatus = "loading";
    },
    failedSeasonalStats(state) {
      state.h2hStatus = "failed";
    },
    setSeasonalStats(state, action: PayloadAction<SetSeasonalStats>) {
      const [homeId, awayId] = action.payload.result;
      const { contestants } = action.payload.entities;
      state.h2hStatus = "success";
      state.contestants[homeId].seasonalStats = contestants[homeId].stats;
      state.contestants[homeId].fullTimeSeasonalStats =
        contestants[homeId].full_time_stats;
      state.contestants[homeId].allSideSeasonalStats =
        contestants[homeId].all_side_stats;
      state.contestants[homeId].topPlayers = contestants[homeId].players;
      state.contestants[awayId].seasonalStats = contestants[awayId].stats;
      state.contestants[awayId].topPlayers = contestants[awayId].players;
      state.contestants[awayId].fullTimeSeasonalStats =
        contestants[awayId].full_time_stats;
      state.contestants[awayId].allSideSeasonalStats =
        contestants[awayId].all_side_stats;
    },
    updateContestantRatings(
      state,
      action: PayloadAction<{ fixtureId: string; matchMin?: number }>
    ) {
      const { fixtureId, matchMin } = action.payload;
      const [home, away] = state.byId[fixtureId].matchInfo.contestant;
      const { lineup: homeLineup = [] } = state.contestants[home];
      const { lineup: awayLineup = [] } = state.contestants[away];
      const homeRatings = avgRatings(homeLineup, state.players, matchMin);
      const awayRatings = avgRatings(awayLineup, state.players, matchMin);
      merge(state.contestants[home].stats, {
        [StatType.avg_ratings]: homeRatings,
      });
      merge(state.contestants[away].stats, {
        [StatType.avg_ratings]: awayRatings,
      });
    },

    updatePlayersRatings(state, action: PayloadAction<NormalFixtures>) {
      const { players } = action.payload.entities;
      merge(state.players, players);
    },

    fixtureInitSucceed(state, action: PayloadAction<NormalFixtures>) {
      const { payload } = action;
      const { contestants, fixtures, players } = action.payload.entities;
      if (players) {
        assign(state.players, players);
      }
      assign(state.contestants, contestants);
      assign(state.byId, fixtures);
      state.ids.push(...payload.result);
    },

    updateEvents(state, action: PayloadAction<NormalFixtures>) {
      const { contestants, fixtures } = action.payload.entities;
      const [opId] = action.payload.result;
      const { contestants: contIds } = fixtures[opId];
      const [homeId, awayId] = contIds;
      merge(state.byId[opId], fixtures[opId]);
      state.contestants[homeId].booking = contestants[homeId].booking;
      state.contestants[homeId].goal = contestants[homeId].goal;
      state.contestants[homeId].substitution = contestants[homeId].substitution;

      state.contestants[awayId].booking = contestants[awayId].booking;
      state.contestants[awayId].goal = contestants[awayId].goal;
      state.contestants[awayId].substitution = contestants[awayId].substitution;
    },
    updateStat(state, action: PayloadAction<NormalFixtures>) {
      const { contestants, fixtures, players } = action.payload.entities;
      const [opId] = action.payload.result;
      const { contestants: contIds } = fixtures[opId];
      const [homeId, awayId] = contIds;
      merge(state.players, players);
      merge(state.byId[opId], fixtures[opId]);
      merge(state.contestants[homeId].stats, contestants[homeId].stats);
      merge(state.contestants[awayId].stats, contestants[awayId].stats);
      state.contestants[homeId].score = contestants[homeId].score;
      state.contestants[awayId].score = contestants[awayId].score;

      state.contestants[homeId].matchMin = contestants[homeId].matchMin;
      state.contestants[awayId].matchMin = contestants[awayId].matchMin;
    },

    getPeriodBucket(state, action: PayloadAction<GetPeriodBucket>) {
      state.pbStatus = "loading";
    },
    successPeriodBucket(state, action: PayloadAction<SuccessPeriodBuckets>) {
      const { contestants, players } = action.payload.entities;
      const { contestants: ids, type } = action.payload.result;
      const [homeId, awayId] = ids;
      merge(state.contestants[homeId], {
        bucketStats: {
          [type]: contestants[homeId].stats,
        },
      });
      merge(state.contestants[awayId], {
        bucketStats: {
          [type]: contestants[awayId].stats,
        },
      });

      const playerIds = [
        ...contestants[homeId].lineup,
        ...contestants[awayId].lineup,
      ];
      const playersEntries = playerIds.map((id) => [
        id,
        { bucketStats: { [type]: players[id].stats } },
      ]);
      const playersObj = Object.fromEntries(playersEntries);
      merge(state.players, playersObj);
      state.pbStatus = "success";
    },
    setTeamStandings(state, action: PayloadAction<SetTeamStandings>) {
      action.payload.result.forEach((id) => {
        state.contestants[id].standing =
          action.payload.entities.teamStandings[id];
      });
    },
  },
});

export const {
  updateContestantRatings,
  updatePlayersRatings,
  fixtureInitSucceed,
  updateStat,
  updateEvents,
  successPeriodBucket,
  getSeasonalStats,
  setSeasonalStats,
  getPeriodBucket,
  setTeamStandings,
  getPlayersPlayed,
  setPlayersPlayed,
  getSeasonAvg,
  setSeasonAvg,
} = fixtureSlice.actions;
export default fixtureSlice.reducer;
