import { Country } from "features/calendar/interfaces";
import { useSelector } from "react-redux";

export const useCountries = () => {
  const countries = useSelector((state) =>
    state.calendar.ids
      .filter((id) =>
        state.calendar.live
          ? state.calendar.countMatches[id]?.live
          : state.calendar.countMatches[id]?.all
      )
      .reduce(
        (acc, curr) =>
          acc.set(
            state.calendar.byId[curr].details.competition.country.countryId,
            state.calendar.byId[curr].details.competition.country
          ),
        new Map<string, Country>()
      )
  );

  return [...countries.values()].sort((a, b) => a.name.localeCompare(b.name));
};
