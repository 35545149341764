import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Grid } from "../core/Grid";

export const FilterContainer: FC = ({ children }) => (
  <Grid
    container
    position={{ md: "sticky" }}
    top={108}
    py={1}
    bgcolor="background.default"
    zIndex="appBar"
  >
    <Grid item xs={12}>
      <Box
        display="flex"
        overflow={{ xs: "scroll auto", md: "auto auto" }}
        justifyContent={{ xs: "flex-start", md: "center" }}
      >
        {React.Children.only(children)}
      </Box>
    </Grid>
  </Grid>
);
