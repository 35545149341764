export enum Paths {
  APP = "/app",
  FIXTURES = "/app/fixtures",
  AUTH = "/auth",
  LOGIN = "/auth/login",
  SIGN_UP = "/auth/signup",
  FORGET_PASS = "/auth/forget_pass",
  NEW_PASS = "/auth/new_password",
  TERMS = "/terms",
  INTRO = "/",
  ACCOUNTS = "/app/accounts",
  CHECKOUT = "/app/checkout",
}
