import { Box, Button } from "@material-ui/core";
import { LockRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Paths } from "app/routes/Paths";
import React from "react";
import { useTranslation } from "react-i18next";
import { BlurEffect } from "../core/BlurEffect";

export const AskToSubscribeAlert = () => {
  const [t] = useTranslation();
  return (
    <BlurEffect
      position="absolute"
      top={0}
      bottom={0}
      right={0}
      left={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box zIndex={1000}>
        <Alert
          severity="warning"
          icon={<LockRounded />}
          variant="standard"
          action={
            <Button href={Paths.CHECKOUT} color="secondary">
              subscribe
            </Button>
          }
        >
          {t("headers.ask_to_verify")}
        </Alert>
      </Box>
    </BlurEffect>
  );
};
