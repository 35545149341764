import { Box, Button, Link as MuiLink, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { submitSignUp } from "app/API";
import { Paths } from "app/routes/Paths";
import { CreateUserDto } from "features/user/dto/create-user.dto";
import { successSignUp } from "features/user/userSlice";
import { Field, Form, Formik } from "formik";
import React, { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { PasswordTextField } from "../core/inputs/PasswordTextField";
import { TextField } from "../core/inputs/TextField";
const FailedMessage: FC<{ error: string }> = ({ error }) => (
  <>
    {error && (
      <Box mb={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )}
  </>
);

const TermsLink: FC = ({ children }) => (
  <Link to={Paths.TERMS}>
    <MuiLink>{children}</MuiLink>
  </Link>
);
type LocationState = { email?: string };
export const SignUpForm: FC = () => {
  const { state } = useLocation<LocationState>();
  const initialValues: CreateUserDto = {
    email: state?.email || "",
    firstName: "",
    lastName: "",
    password: "",
  };
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().max(70).required(),
        lastName: Yup.string().max(70).required(),
        email: Yup.string().email().required(),
        password: Yup.string().min(8).required(),
      }),
    []
  );
  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values, helper) => {
        const { setStatus, setSubmitting } = helper;
        try {
          const { data } = await submitSignUp(values);
          if (data.id) {
            dispatch(successSignUp(data));
            setSubmitting(false);
            setStatus({ success: true });
          }
        } catch (err) {
          /* @ts-expect-error */
          const error = err.response.data.message;
          setStatus({ error });
          setSubmitting(false);
        }
      }}
      initialStatus={{
        isLoading: false,
        success: false,
        error: "",
      }}
    >
      {({ isSubmitting, status, values }) => (
        <>
          {status.success && (
            <Box py={2}>
              <Box p={2}>
                <Typography variant="h5" align="center">
                  {t("create_account.thank_you")}
                </Typography>
              </Box>
              <Box px={4} py={2}>
                <Typography variant="body1" align="center">
                  {(t("create_account.success"), { email: values.email })}
                </Typography>
              </Box>
            </Box>
          )}
          {!status.success && (
            <>
              <Form>
                <Box display="flex" flexDirection="column">
                  <Field
                    type="text"
                    name="firstName"
                    label={t("accounts.first_name_field")}
                    component={TextField}
                  />
                  <Field
                    type="text"
                    name="lastName"
                    label={t("accounts.last_name_field")}
                    component={TextField}
                  />
                  <Field
                    type="email"
                    name="email"
                    label={t("accounts.email_field")}
                    component={TextField}
                  />
                  <Field
                    name="password"
                    label={t("accounts.password_field")}
                    helperText={t("accounts.password_help")}
                    component={PasswordTextField}
                  />
                  <Box my={1}>
                    <Typography variant="caption">
                      <Trans i18nKey={"create_account.agree_to_terms"}>
                        {"By creating account I agree with Statistigoal "}
                        <TermsLink>{"Terms of Condition"}</TermsLink>
                      </Trans>
                    </Typography>
                  </Box>
                  <FailedMessage error={status.error} />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                  >
                    {t("button.submit")}
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </>
      )}
    </Formik>
  );
};
