import MomentUtils from "@date-io/moment";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { RoutesWrapper } from "./routes/RoutesWrapper";
import { theme } from "./theme";
const App = () => {
  const [, i18n] = useTranslation();

  React.useEffect(() => {
    import(`moment/locale/${i18n.language}`);
  }, [i18n.language]);
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
          <Router>
            <Switch>
              <RoutesWrapper />
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
export default App;
