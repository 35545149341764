import { ToggleButtonGroup } from "@material-ui/lab";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../core/FilterButton";
import { TrnFilterContext } from "./TrnFilterContext";

export const TrnSideTweak = () => {
  const [t] = useTranslation();
  const { side, setSide } = useContext(TrnFilterContext);
  const onChange:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void)
    | undefined = (e, value: "home" | "away") => {
    if (value == null) {
      setSide();
      return;
    }
    setSide(value);
  };
  return (
    <ToggleButtonGroup size="small" exclusive value={side} onChange={onChange}>
      <FilterButton name={t("glossary.home")!} value="home" />
      <FilterButton name={t("glossary.away")!} value="away" />
    </ToggleButtonGroup>
  );
};
