import { subscribeFixtures } from "features/socket/socketSlice";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FixtureLayout } from "./FixtureLayout";
export const Fixture: FC = () => {
  let { id } = useParams<{ id: string }>();

  const connection = useSelector((state) => state.socketStatus.serverStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    if (connection === "connected") {
      dispatch(
        subscribeFixtures({
          namespace: "fixtures",
          initialMessage: { fixtureId: id },
        })
      );
    }
  }, [dispatch, id, connection]);

  return <FixtureLayout fixtureId={id} />;
};
