import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { RemoteStatus, StatDto } from "../../../features/fixture/interfaces";
import { StatItem } from "../../interface/StatItem";
import { StatView } from "../Stats/StatView";
import { List } from "../Stats/Styled";

interface Props {
  index: Nullable<StatType>;
  setIndex: (i: Nullable<StatType>) => void;
  stats?: { [key: string]: StatDto };
  status: RemoteStatus;
  items: StatItem[];
}
export const TrnStatsList: FC<Props> = ({
  index,
  setIndex,
  stats = {},
  status,
  items,
}) => {
  const [tName] = useTranslation("stats");
  const [tDesc] = useTranslation("statsDesc");
  const view = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    view.current?.scrollIntoView({ block: "center" });
  }, []);
  return (
    <Box p={2}>
      <List>
        {items.map(({ statType, subStats }) => (
          <StatView
            key={statType}
            ref={index === statType ? view : undefined}
            statType={statType}
            statName={tName(statType)}
            statDesc={tDesc(statType)}
            stat={stats[statType]?.value}
            subStats={subStats?.map(({ statType: subType }) => ({
              statName: tName(subType),
              statDesc: tDesc(subType),
              stat: stats[subType]?.value,
              statType: subType,
              isSelected: index === subType,
              clickHandler: () =>
                index === subType ? setIndex(null) : setIndex(subType),
            }))}
            clickHandler={() =>
              index === statType ? setIndex(null) : setIndex(statType)
            }
            isSelected={index === statType}
            reverse={false}
            status={status}
          />
        ))}
      </List>
    </Box>
  );
};
