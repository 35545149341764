import { createSelector } from "@reduxjs/toolkit";
import { selectCalendar } from "./selectCalendar";

export const selectTrnAvgStats = (id: string) =>
  createSelector(selectCalendar(id), (cal) =>
    Object.entries(cal?.stats.trnAvgStats || []).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: { value: value?.toFixed(1) || '-' },
      }),
      {}
    )
  );
