import { DateType } from "@date-io/type";
import { Box, TextField, TextFieldProps } from "@material-ui/core";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
  date: DateType | null;
  changeHandler: (date: DateType | null) => void;
}

const CustomTextField = styled(TextField)`
  width: 80px;

  /* border: 1px solid;
  border-radius: 4px; */
  input {
    text-align: center;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
    font-weight: ${({ theme }) => theme.typography.subtitle2.fontWeight};
  }

  & .MuiInput-underline:after {
    display: none;
  }
  & .MuiInput-underline:before {
    display: none;
  }
`;

export const DatePicker: FC<Props> = ({ date, changeHandler }) => {
  const [t] = useTranslation();
  return (
    <Box display="flex" alignItems="center">
      <MuiDatePicker
        autoOk
        disableToolbar
        TextFieldComponent={(props: TextFieldProps) => (
          <CustomTextField {...props} />
        )}
        variant="inline"
        value={date}
        labelFunc={(date, invalidLabel) =>
          date?.calendar(null, {
            sameDay: `[${t("calendar.today")}]`,
            nextDay: `[${t("calendar.tomorrow")}]`,
            nextWeek: "ddd, DD MMM",
            lastDay: `[${t("calendar.yesterday")}]`,
            lastWeek: "ddd, DD MMM",
            sameElse: "DD/MM/YYYY",
          }) || invalidLabel
        }
        onChange={changeHandler}
      />
    </Box>
  );
};
