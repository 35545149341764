import { Collapse } from "@material-ui/core";
import { RemoteStatus } from "features/fixture/interfaces";
import React from "react";
import { StatType } from "shared/enums";
import { Nullable } from "shared/Nullable";
import { StatViewContainer } from "./StatViewContainer";
import { SubStatsView } from "./SubStatsView";

type SubStat = Omit<
  MatchStatViewProps,
  "subStats" | "status" | "details" | "reverse"
>;

export interface MatchStatViewProps {
  side?: "Home" | "Away";
  status: RemoteStatus;
  statType: Nullable<StatType>;
  statName: string;
  statDesc: string;
  stat: number;
  matchAvg: number;
  periodAvg: number;
  quarterAvg: number;
  isMin?: boolean;
  subStats?: SubStat[];
  isSelected: boolean;
  clickHandler: () => void;
  period: string;
  quarter: string;
}

export const MatchStatView = React.forwardRef<
  HTMLDivElement,
  MatchStatViewProps
>(({ status, subStats, side, isSelected, ...rest }, ref) => {
  const isSubSelected = React.useMemo(
    () => Boolean(subStats?.filter(({ isSelected }) => isSelected).length),
    [subStats]
  );
  return (
    <>
      <StatViewContainer
        isSelected={isSelected || isSubSelected}
        status={status}
        side={side}
        {...rest}
      />
      {subStats && (
        <Collapse in={isSelected || isSubSelected}>
          <SubStatsView subStats={subStats} status={status} side={side} />
        </Collapse>
      )}
    </>
  );
});
